<template>
	<div class="hezuo-wrap">
		<el-row>
			<img class="banner-unify" src="../assets/内容页切图/顶部图-援藏干部风采.png" alt="">
		</el-row>

		<div class="wrap-Row">
			<FixedContent v-if="!showPage" @ControlsBack="ControlsBack" :FixedContentList="['详情']"></FixedContent>

			<div class="content">
				<el-row>
					<el-col :span="5" class="hezuo-left">
						<div class="hezuo-left-list" :class="cityFage == item.subsetName ? 'click' : ''"
							v-for="(item, index) in cityList" :key="index + 'tree'" @click="cityClick(item)">
							<div class="hezuo-left-title">{{ item.subsetName }}</div>
							<div class="hezuo-left-img">
								<img src="../assets/内容页切图/城市导航-箭头-白.png" v-if="cityFage == item.subsetName" />
								<img src="../assets/内容页切图/城市导航-箭头-灰.png" v-else />
							</div>
						</div>
					</el-col>
					<el-col :span="19" class="hezuo-right">
						<div class="right-btns">
							<div class="hezuo-left-list-right" :class="batchFage == item.subsetName ? 'click' : ''"
								v-for="(item, index) in secondList" :key="index + 'tree'"
								@click="cityClickThree(item.subsetName)">
								<div class="hezuo-left-title">{{ item.subsetName }}</div>

							</div>
						</div>
						<el-row>
							<div class="seePic">
								<div v-if="cityType == '2'" class="video-wall-main" v-for="(item, index) in list">

									<div class="time">
										<div class="line"></div>
										<div>
											{{ item.title }}
										</div>
									</div>
									<div class="video-wall">
										<div v-for="(i, ind) in item.list" class="videosss">
											<div class="wall-detail1">
												<videoPlayer class="video-player vjs-custom-skin videoSize"
													ref="videoPlayer" :playsinline="true" :options="getOptions(i)">
												</videoPlayer>
											</div>
										</div>
									</div>
								</div>
								<div v-if="cityType == '1'" class="pic-wall" v-for="(item, index) in list">

									<div class="time">
										<div class="line"></div>
										<div>
											{{ item.title }}
										</div>
									</div>
									<div>
										<el-image lazy class="wall-detail" v-for="(i, ind) in item.list"
											:src="i.picturePhoto"
											:preview-src-list="item.list.map(b => { return b.picturePhoto })">
											<div slot="placeholder" class="image-slot">
												<img src="@/assets/首页切图/缺省.png" alt="">
											</div>
										</el-image>
									</div>
								</div>

							</div>
						</el-row>

					</el-col>
				</el-row>
			</div>

		</div>

	</div>
</template>

<script>
import { videoPlayer } from 'vue-video-player'
import keyDetail from './components/keyDetail'
export default {
	components: { keyDetail, videoPlayer },
	data() {
		return {
			playerOptionsList: [

			],
			cityList: [],
			cityFage: "",
			showPage: true,
			list: [],
			cityType: '',
			secondList: [],
			batchFage: '',
		}
	},
	mounted() {
		this.getLeftList();
	},
	methods: {
		getOptions(i) {

			let arr = {
				id: i.id,
				playbackRates: [], // 可选的播放速度
				autoplay: false, // 如果为true,浏览器准备好时开始回放。
				muted: false, // 默认情况下将会消除任何音频。
				loop: false, // 是否视频一结束就重新开始。
				preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
				language: 'zh-CN',
				aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
				fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
				sources: [{
					type: "video/mp4", // 类型
					src: i.videoPhoto
				}],
				// poster: require('@/assets/首页切图/10投资合作-大连-宽.png'), // 封面地址
				notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
				controlBar: {
					timeDivider: false, // 当前时间和持续时间的分隔符
					durationDisplay: false, // 显示持续时间
					remainingTimeDisplay: false, // 是否显示剩余时间功能
					fullscreenToggle: true // 是否显示全屏按钮
				}

			}
			return arr
		},
		cityClickThree(e) {
			this.batchFage = e;
			this.getDetails()
		},
		loadMore() { },
		getDetails() {
			if (this.cityType == '2') { // 视频
				this.$baseRequest('/maintain/video/findMap', { videoCategory: '干部风采', videoGrouping: this.cityFage, videoType: this.batchFage }, 'post').then(res => {
					this.list = []
					this.list = res.data;
				})
			} else {
				this.$baseRequest('/maintain/picture/findMap', { pictureCategory: '干部风采', pictureGrouping: this.cityFage, pictureType: this.batchFage }, 'post').then(res => {
					this.list = res.data;
				})
			}
		},
		// 获取右侧具体列表
		getList() {
			this.$baseRequestGet('/sys/dictionaries/subset/list?info=' + this.cityFage, {}, 'get').then(res => {
				this.secondList = res.data;
				this.batchFage = res.data.length ? res.data[0].subsetName : '';
				this.getDetails()
				// this.cityList = res.data;
				// this.cityFage = res.data.length ? res.data[0].subsetName : '';
				// this.cityType = this.cityFage ? res.data[0].subsetType : '';
				// this.cityFage ? this.getList() : this.list = [];

			})


		},
		clickChoose(index) {
			this.chooseListIndex = index;
			this.getLeftList();
		},
		// 获取左侧选择列表
		getLeftList() {
			this.$baseRequestGet('/sys/dictionaries/subset/list?info=' + '干部风采', {}, 'get').then(res => {
				this.cityList = res.data;
				this.cityFage = res.data.length ? res.data[0].subsetName : '';
				this.cityType = this.cityFage ? res.data[0].subsetType : '';
				this.cityFage ? this.getList() : this.list = [];

			})
		},

		ControlsBack() {
			this.showPage = true;
		},

		cityClick(e) {
			this.cityType = e.subsetType;
			this.cityFage = e.subsetName;
			if (!this.showPage) {
				this.showPage = true;
			}
			this.getList();
		},

	}
}
</script>

<style src="../style/element-ui-class.css"></style>

<style src="@/style/viedo.css"></style>
<style scoped lang="scss">
.chooseItem {
	height: 60px;
	padding: 0px 310px;
	background-color: #fff;
	border-bottom: 1px solid #EBEBEB;
	display: flex;
	align-items: center;

	.chooseItem-item {
		width: 100px;
		font-size: 20px;
		font-weight: 600;
		position: relative;
		cursor: pointer;

		.line {
			position: absolute;
			bottom: -17px;
			left: -2px;
			width: 40px;
			border-radius: 2px;
			height: 4px;
			background-color: #F86C14;
		}

	}

}

.hezuo-wrap {
	

	.wrap-Row {
		padding: 30px 310px;
	}

	.content {
		margin-top: 20px;

		.hezuo-left {
			background-color: #fff;
			padding: 20px;

			.hezuo-left-list {
				height: 60px;
				border-radius: 8px;
				padding: 0 25px;
				display: flex;
				cursor: pointer;

				.hezuo-left-title {
					line-height: 60px;
					font-size: 18px;
					display: inline-block;
				}

				.hezuo-left-img {
					float: right;
					margin: auto 0px auto auto;

					img {
						width: 10px;
					}
				}
			}

			.click {
				color: #fff;
				background-image: url(../assets/首页切图/展会简介背景.png);
				background-repeat: no-repeat;
				background-size: cover;
			}
		}

		.hezuo-right {
			padding-left: 35px;

			.hezuo-right-vessel {
				margin-top: 30px
			}

			.hezuo-right-placeholder {
				height: 10px;
			}

			.hezuo-right-project {
				background-color: #fff;
				height: 90px;
				display: flex;
				align-items: center;
				padding: 0 30px;
				margin-top: 20px;

				.project-title {
					font-size: 23px;
					margin-left: 20px;
				}

				.project-right {
					margin-left: auto;
				}
			}
		}
	}
}




.introduct-list {
	display: flex;
	margin-top: 40px;
	padding-bottom: 40px;
	border-bottom: 1px solid #f0f1f7;
	cursor: pointer;

	img {
		width: 280px;
		height: 180px;

	}

	.list-right {
		display: flex;
		flex-direction: column;
		padding-left: 25px;

		.list-title {
			font-size: 23px;
			font-weight: 600;
			line-height: 36px;

		}

		.list-text {
			margin-top: 10px;
			font-size: 15px;
			color: #8b8b8b;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		.list-time {
			margin-top: 10px;
			font-size: 16px;
			color: #8b8b8b;
		}
	}
}

.btn-fabu {
	padding: 7px 15px;
	text-align: left;
	background-color: #F86C14;
	border-radius: 4px;
	color: #fff;
	font-size: 18px;
	margin-top: 5px;
	cursor: pointer;
}

.video-wall-main {

	.video-wall {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;

		.videosss {
			width: 470px;
			margin-right: 20px;
			margin-bottom: 20px;

		}

		.wall-detail1 {
			width: 100%;

		}
	}

	.wall-name {
		width: 430px;
		text-align: left;
		padding: 15px 20px;
		font-size: 16px;
		color: #000;
		background-color: #fff;
		border-radius: 0px 0px 5px 5px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.time {
		font-size: 28px;
		display: flex;
		align-items: center;
		margin-bottom: 20px;
	}

	.line {
		width: 7px;
		height: 7px;
		margin-right: 10px;
		background-color: #F86C14;
		border-radius: 50%;

	}


	.image-slot {
		img {
			width: 272px;
			height: 180px;
		}
	}
}


.pic-wall {


	.time {
		font-size: 28px;
		display: flex;
		align-items: center;
		margin-bottom: 20px;
	}

	.line {
		width: 7px;
		height: 7px;
		margin-right: 10px;
		background-color: #F86C14;
		border-radius: 50%;

	}

	.wall-detail {
		width: 272px;
		height: 180px;
		margin-right: 20px;
		margin-bottom: 20px;
	}

	.image-slot {
		img {
			width: 272px;
			height: 180px;
		}
	}

}

.seePic {
	height: 550px;
	overflow: auto;

}

.right-btns {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	gap: 10px;
	margin-bottom: 20px;

	.hezuo-left-list-right {

		border-radius: 8px;
		padding: 10px 20px;
		background-color: #fff;

		cursor: pointer;

		.hezuo-left-title {


			font-size: 18px;
			display: inline-block;
		}

	}

	.click {
		color: #fff;
		background-color: #F86C14;
		background-repeat: no-repeat;
		background-size: cover;
	}

}
</style>