import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    detail: '',
    detailStore: '',
    hallItem: ''
  },
  mutations: {
    SET_DETAIL: (state, detail) => {
      state.detail = detail
    },
    SET_DETAILSTORE: (state, detailStore) => {
      state.detailStore = detailStore
    },
    SET_HALLITEM: (state, hallItem) => {
      state.hallItem = hallItem
    },
  },
  getters: {
    detail: state => {
      return state.detail
    },
    detailStore: state => {
      return state.detailStore
    },
    hallItem: state => {
      return state.hallItem
    },
  },
  actions: {
  },

})
