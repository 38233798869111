<!--
 * @Author: jiangjinchi 1468922694@qq.com
 * @Date: 2024-09-15 18:22:57
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2024-09-17 18:21:54
 * @FilePath: \PC最新版本\src\view\openLiaoning\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="body">
        <el-row class="row">
            <el-col :span="8" v-for="(item, index) in pilotFreeTradeZone" :key="index" class="item">
                <img style="cursor: pointer;" @click="goTo(item.url, item.name)" :src="item.img" />
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            pilotFreeTradeZone: [{
                img: require("@/assets/其他/开放平台/开放平台-重点开发区.png"),
                name: "重点开发区",
                url: 'emphasisDevelopmentZone',
            }, {
                img: require("@/assets/其他/开放平台/开放平台-自贸试验区.png"),
                name: "自贸试验区",
                url: 'pilotFreeTradeZone',
            }, {
                img: require("@/assets/其他/开放平台/开放平台-跨境电商.png"),
                name: "跨境电商",
                url: 'ebay',
            }]
        }
    },
    mounted() {
     
    },
    methods: {
        //跳转 
        goTo(url, item) {
            this.$emit('jumpUrl', url, item)
        },
    }

}
</script>

<style lang="scss" scoped>
.body {
    padding: 20px 280px;
    

    .row {
        .item {
            text-align: center;

            >img {
                width: 421px;
                height: auto;
                // height: 480px;
            }
        }
    }
}
</style>