/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-06-05 09:38:28
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2023-09-06 10:00:21
 * @FilePath: \vue-admin-template\src\api\base.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/util/request'
export function baseRequest(url,data,method) {
    return request({
        url: url,
        method,
        data
    })
}
export function baseRequestGet(url,data,method) {
    return request({
        url: url,
        method,
    })
}

export function baseRequestGetParams(url,data,method) {
    return request({
        url: url,
        params:data,
        method,
    })
}