<template>
    <div>

        <component v-bind:is="currentBizComponent" @jumpUrl="jumpUrl" :query="query" @showDialogNeed="showDialogNeed">

        </component>


        <el-dialog title="证件查询" :visible.sync="showDialog" width="40%" :close-on-click-modal="false"
            :close-on-press-escape="false" center>
            <div class="dialogMian">
                <div class="inputBox">
                    <div>
                        <el-input style="width: 100%;" v-model="form.info" placeholder="请输入手机号"> </el-input>
                    </div>
                    <div>
                        <el-input style="width: 100%;" v-model="form.code" placeholder="请输入验证码">
                            <template slot="append">
                                <span class="code" @click="send" v-if="isSend">发送验证码</span>
                                <span class="code" v-if="!isSend">{{ sendmsg }}</span>

                            </template>
                        </el-input>

                    </div>
                    <div>
                        <el-input style="width: 100%;" v-model="form.number" placeholder="请输入身份证号">

                        </el-input>
                    </div>
                    <el-button type="primary" @click="clickSearch">查询</el-button>

                </div>
                <div class="zhengjianxinxi" v-if="searchInfo">
                    <div>
                        <img class="zhengjainzhao" :src="searchInfo.headPortrait" alt="">
                    </div>
                    <div class="infoflex">
                        <div class="titless">{{ searchInfo.name }}</div>
                        <div class="contss">
                            <div>团组:<span>{{ searchInfo.theGroup }}</span></div>

                            <div>身份证号码: <span>{{ searchInfo.numberId }}</span></div>

                            <div>手机号码:<span>{{ searchInfo.cellPhoneNumber }}</span></div>

                            <div>证件类型:<span>{{ searchInfo.certificateType }}</span></div>

                            <div>审核状态:<span>{{ searchInfo.approvalResult }}</span></div>
                        </div>

                    </div>
                </div>
            </div>

        </el-dialog>
    </div>
</template>
<script>

export default {

    props: {
        componentsName: {
            type: String
        }
    },
    data() {
        return {
            sendmsg: '发送验证码',
            isSend: true,
            query: '',
            showDialog: false,
            name: '',
            form: {
                info: '',
                code: '',
                number: '',

            },
            searchInfo: ''
        }
    },
    computed: {
        currentBizComponent: function () {
            return this.componentsName
        }
    },


    onload() {

    },
    methods: {
        clickSearch() {
            if (!this.form.info) {
                this.$message.warning('请输入手机号')
                return;
            };
            if (!this.form.code) {
                this.$message.warning('请输入验证码')
                return;
            };
            if (!this.form.number) {
                this.$message.warning('请输入身份证号')
                return;
            };

            this.$baseRequestGetParams('/short/message/search', this.form, 'get').then(res => {
                this.searchInfo = res.data;

            });
        },
        send() {
            if (!this.form.info) {

                this.$message.warning('请填写手机号后获取验证码！')
            } else if (/^1(3|4|5|6|7|8)\d{9}$/.test(this.form.info) == false) {
                this.$message.warning("请填写正确的手机号后获取验证码！");
            } else {
                this.$baseRequest("/short/message/verification?info=" + this.form.info, {}, "get").then(res => {
                    this.isSend = false;
                    let timer = 60;
                    this.sendmsg = timer + "s";
                    this.timeFun = setInterval(() => {
                        --timer;
                        this.sendmsg = timer + "s";
                        if (timer == 0) {
                            this.isSend = true;
                            this.sendmsg = "重新发送";
                            clearInterval(this.timeFun);
                        }
                    }, 1000);
                })

            }
        },
        showDialogNeed() {
            this.searchInfo = '';
            this.form.info = '';
            this.form.code = '';
            this.form.number = '';

            this.showDialog = true
        },
        jumpUrl(url, query) {

            this.$emit('runMore', url)
            this.query = query
        },

    }
}

</script>
<style scoped lang="scss">
.zhengjainzhao {
    width: 220px;
    height: 280px;
    border-radius: 10px;
}

.zhengjianxinxi {
    margin-top: 40px;
    display: flex;
    // justify-content: center;

}

.infoflex {
    margin-left: 30px;

    .titless {
        font-size: 30px;
        font-weight: 600;
        letter-spacing: 2px;
        color: #333333;
        margin-bottom: 20px;
    }

    .contss {
        font-size: 19px;
        color: #666666;

        div {
            margin-bottom: 20px;
        }

        span {
            margin-left: 10px;
        }

    }
}

.FixedContentStyle {
    padding: 20px 280px;
}

.inputBox {

    div {
        margin-bottom: 10px;
    }
}
</style>