<template>
	<div class="consumption-wrap">

		<div class="content">
			<div class="FixedContentCon">
				<FixedContent :titleJumpList="titleJumpList" @jumpUrl="jumpUrl" @back="back"></FixedContent>
			</div>
			<el-row>
				<el-col :span="5" class="consumption-left">
					<div class="type-list">
						<div class="type-list-item" v-for="(item, index) in type" :key="index"
							:class="index == typeIndex ? 'type-list-item-click' : ''" @click="typeClick(index)">
							<div>{{item}}</div>
							<img src="../../assets/导航箭头-白.png" v-if="index == typeIndex" />
							<img src="../../assets/导航箭头-灰.png" v-else />
						</div>
					</div>
				</el-col>
				<el-col :span="19" class="consumption-right">
					<el-row :gutter="30" v-if="!detailsFlag">
						<el-col :span="6" v-for="item in list" :key="item.id">
							<div class="consumption-product" @click="goTo(item.id)">
								<el-image class="consumption-product-img" :src="item.photo">
									<div slot="placeholder" class="image-slot">
										<img src="@/assets/首页切图/缺省.png" alt="">
									</div>
									<div slot="error" class="image-slot">
										<img src="@/assets/首页切图/缺省.png" alt="">
									</div>
								</el-image>
								<div class="product-boxs">
									<div class="consumption-product-title">{{ item.title }}</div>
									<div class="consumption-product-content">{{ item.company }}</div>
								</div>
							</div>
						</el-col>
					</el-row>

					<consumptionDetails ref="consumptionDetails" v-else :id="id"></consumptionDetails>
	
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	import consumptionDetails from './consumptionDetails'
	export default {
		components: {
			consumptionDetails
		},
		props: ["query"],
		data() {
			return {
				cityFage: '全部',
				detailsFlag: false,
				list: [],
				id: "",
				type: ["全部", "食品", "饰品"],
				typeIndex: 0,
				titleJumpList: [{
					url: "consumptionLiaoning",
					name: "消费辽宁",
				}, {
					url: "consumptionDetail",
					name: '',
				}, ]
			}
		},
		mounted() {
			this.getType();
			this.findList();

			if (typeof this.query === 'string') {
				this.titleJumpList[1].name = '辽宁' + this.query
			} else {
				this.titleJumpList[1].name = '辽宁' + this.query.type;

				this.goTo(this.query.id)
			}

		},
		watch: {
			detailsFlag(val) {
				if (val) {
					if (typeof this.query === 'string') {
						this.titleJumpList = [

							{
								url: "consumptionLiaoning",
								name: "消费辽宁"

							}, {
								url: "",
								name: '辽宁' + this.query

							}, {
								url: "consumptionDetail",
								name: '详情'

							},
						]
					} else {
						this.titleJumpList = [

							{
								url: "consumptionLiaoning",
								name: "消费辽宁"

							}, {
								url: "",
								name: '辽宁' + this.query.type

							}, {
								url: "consumptionDetail",
								name: '详情'

							},
						]
					}


				} else {
					if (typeof this.query === 'string') {
						this.titleJumpList = [{
							url: "consumptionLiaoning",
							name: "消费辽宁"

						}, {
							url: "consumptionDetail",
							name: '辽宁' + this.query

						}]
					} else {
						this.titleJumpList = [{
							url: "consumptionLiaoning",
							name: "消费辽宁"

						}, {
							url: "consumptionDetail",
							name: '辽宁' + this.query.type

						}]
					}

				}
			}
		},
		methods: {
			getType() {
				let arr = typeof this.query === 'string' ? this.query : this.query.type
				this.$baseRequest('/not/goods/boutique/classification?info=' + arr, {}, 'get').then(res => {
					this.type = ["全部"];
					res.data.forEach(item => {
						this.type.push(item)
					});
				})
			},
			typeClick(index) {
				this.typeIndex = index;
				this.findList();
			},
			back(e) {
				this.detailsFlag = false;
			},
			jumpUrl(e) {
				this.$emit('jumpUrl', e)
			},
			findList() {
				let arr = typeof this.query === 'string' ? this.query : this.query.type

				this.$baseRequest('/not/goods/boutique/findList', {
					province: '',
					category: arr,
					classification: this.type[this.typeIndex] != '全部' ? this.type[this.typeIndex] : ''
				}, 'POST').then(res => {
					this.detailsFlag = false;
					this.list = res.data;
				})
			},
			// cityClick(e) {
			// 	this.cityFage = e;
			// 	this.detailsFlag = false;
			// 	this.findList();
			// },
			goTo(e) {
				this.id = e;
				this.detailsFlag = !this.detailsFlag;
			}
		}
	}
</script>

<style src="../../style/element-ui-class.css"></style>

<style scoped lang="scss">
	.dingbu {
		width: 100%;
		height: auto;
	}

	.consumption-wrap {
		// background-color: #FAFAFA;

		.content {
			padding: 60px 310px;

			.breadcrumb {
				height: 15px;
				display: flex;
				align-items: center;
				line-height: 15px;
				color: #8389a6;
				margin-bottom: 20px;

				div {
					display: flex;
					align-items: center;

					img {
						width: 15px;
						height: 15px;
						margin-right: 10px;
					}
				}

				i {
					margin: 0 5px;
				}
			}

			.consumption-left {
				background-color: #fff;
				padding: 20px;

				.type-list {

					&-item {
						cursor: pointer;
						display: flex;
						height: 60px;
						align-items: center;
						padding: 0px 25px;
						font-size: 16px;
						justify-content: space-between;

						>img {
							width: 8px;
							height: 15px;
						}
					}

					&-item-click {
						color: #fff;
						background-color: #ea0e1e;
						border-radius: 10px;
					}
				}
			}

			.consumption-right {
				padding-left: 35px;

				.consumption-product {
					// background-color: pink;
					cursor: pointer;
					border-radius: 5px;
					// background-color: #fff;
					padding-bottom: 20px;

					.consumption-product-img {
						width: 100%;
						height: 200px;
						border-radius: 5px 5px 0 0;
						vertical-align: top;
					}

					.image-slot {
						img {
							width: 100%;
							height: 280px;
							border-radius: 5px 5px 0 0;

						}

					}

					.product-boxs {
						padding: 20px;
						background-color: #fff;
						box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
						border-radius: 0px 0px 5px 5px;
					}

					.consumption-product-title {

						// margin-top: 10px;
						font-size: 17px;
						font-weight: bold;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;

					}

					.consumption-product-content {
						margin-top: 5px;
						height: 40px;
						font-size: 14px;
						color: #b4b4b4;
						display: -webkit-box;
						overflow: hidden;
						-webkit-box-orient: vertical;
						line-clamp: 2;
						-webkit-line-clamp: 2;
					}
				}
			}
		}
	}

	.FixedContentCon {
		margin-bottom: 20px;
		margin-left: 20px;
	}
</style>