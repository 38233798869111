<!--
 * @Author: jiangjinchi 1468922694@qq.com
 * @Date: 2024-09-13 09:05:28
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2024-09-18 13:19:02
 * @FilePath: \pc\src\view\detailPage\detailpageOne.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="body">
        <FixedContent :titleJumpList="titleJumpList" @jumpUrl="jumpUrl"></FixedContent>
        <div class="content">
            <div class="introduct-left ">
                <div class="title">
                    {{ info.name }}
                </div>
                <div class="date">
                    <img src="../../assets/辽洽会PC端切图/展商服务/酒店推荐详情-距离位置.png" alt=""> <span>距博览馆  {{ info.distance }}公里</span>
                    <img src="../../assets/辽洽会PC端切图/展商服务/酒店推荐详情-电话.png" alt=""> {{ info.contactNumber }}
                </div>
                <div class="imgss">
                    <img :src="info.picture" alt="">
                </div>
                <div class="conts">
                    {{ info.spare1 }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['query'],
    data() {
        return {
            titleJumpList: [
                {
                    url: "ExhibitionService",
                    name: "展会服务"

                }, {
                    url: "exhibitionGuide",
                    name: "酒店推荐"

                }, {
                    url: "detailpageThree",
                    name: "详情"

                },
            ],
            info: ''
        }
    },
    mounted() {
 
        this.getDetail()

    },
    methods: {
        getDetail() {
            this.$baseRequestGet('/not/hotel/details?info=' + this.query.id, {}, 'get').then(res => {
                this.info = res.data;
            })
        },
        //跳转 
        jumpUrl(e) {
            this.$emit('jumpUrl', e)
        },
    }

}
</script>

<style lang="scss" scoped>
.body {
    padding: 20px 280px;
    

    .content {
        margin-top: 30px;
        padding: 50px 130px;
        background-color: #fff;
        border-radius: 5px;
    }

    .title {
        text-align: center;
        font-family: 'SourceHanSansSC-Regular';
        font-size: 26px;
        color: #000;
        margin-bottom: 20px;

        img {
            width: 20px;
            height: auto;

        }

        span {
            margin: 0px 10px;
        }
    }
}

.tupian {
    width: 100%;

    img {
        width: 100%;
        height: auto;
    }
}

.conts {
    margin-top: 30px;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 140%;
    text-indent: 2em;

}

.introduct-left {

    background: #fff;
    // padding: 20px;
    padding-bottom: 60px;

    .title {
        font-size: 28px;
        text-align: left;
    }

    .date {
        font-size: 18px;
        color: #878787;
        margin-top: 10px;
        margin-bottom: 10px;
        img {
            width: 15px;
            height: auto;
            vertical-align: middle;
            margin-right: 5px;

        }

        span {
            margin-right: 20px;
        }
    }

    .content {
        font-size: 16px;
    }



}

.imgss {
    width: 100%;

    img {
        width: 100%;
        height: auto;
        border-radius: 5px;
    }


}
</style>