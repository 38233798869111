<template>
	<div class="body">
		<el-row>
			<div class="meeting">
				<div v-for="(item, index) in meeting" :key="index" class="meeting-item" @click="meetingClick(index)"
					:class="meetingIndex == index ? 'meeting-item-click' : ''">
					{{ item.name }}
				</div>
			</div>
		</el-row>
		<el-row>
			<div class="statistics">
				<div class="statistics-item" v-if="statistics.enterprise">
					<div class="data">
						{{ statistics.enterprise }}
					</div>
					<div class="title">
						参展企业（家）
					</div>
				</div>
				<div class="statistics-item" v-if="statistics.signedProject">
					<div class="data">
						{{ statistics.signedProject }}
					</div>
					<div class="title">
						签约项目（个）
					</div>

				</div>
				<div class="statistics-item" v-if="statistics.totalAmount">
					<div class="data">
						{{ statistics.totalAmount }}
					</div>
					<div class="title">
						签约总额（亿元）
					</div>

				</div>
				<div class="statistics-item" v-if="statistics.majorProject">
					<div class="data">
						{{ statistics.majorProject }}
					</div>
					<div class="title">
						重点项目（个）
					</div>

				</div>
			</div>
		</el-row>
		<el-row class="introduce">
			<el-col :span="12" class="content">
				<div class="content-title">
					{{ introduce.title }}
				</div>
				<div class="content-content" v-html="introduce.content">
				</div>
				<div class="content-img">
					<img style="cursor: pointer;" @click="goTo('previousAchievementsDET')"
						src="../../assets/历届成就/按钮-查看详情.png" />
				</div>
			</el-col>
			<el-col :span="12">

				<el-image class="myImg" :src="introduce.img" :preview-src-list="[introduce.img]">
				</el-image>
			</el-col>
		</el-row>

	</div>
</template>

<script>
export default {
	data() {
		return {
			params: {
				cityName: "",
			},
			meeting: [{
				id: 1,
				name: "第一届辽洽会"
			}, {
				id: 2,
				name: "第二届辽洽会"
			}, {
				id: 3,
				name: "第三届辽洽会"
			}, {
				id: 4,
				name: "第四届辽洽会"
			},], //辽洽会
			meetingIndex: 0, //辽恰会选择下标
			statistics: {
				enterprise: "", //参展企业
				signedProject: "", //签约项目
				totalAmount: "", //签约总额
				majorProject: "", //重点项目
			}, //统计
			introduce: {
				id: "",
				img: '',
				title: "",
				content: ""
			}
		}
	},
	mounted() {
		this.getIntroduce();
	},
	methods: {
		meetingClick(index) {
			this.meetingIndex = index;
			this.getIntroduce();
		},
		getIntroduce() {
			this.$baseRequest("/not/previous/achievements/journalism/findList", {
				journalismCategory: this.meeting[this.meetingIndex].name
			}, "post").then(res => {
				var data = res.data[0];
				this.introduce.id = data.id;
				this.introduce.img = data.journalismPhoto;
				this.introduce.title = data.journalismTitle;
				this.introduce.content = data.spare1;
				this.statistics.enterprise = data.spare4;
				this.statistics.signedProject = data.spare5;
				this.statistics.totalAmount = data.spare6;
				this.statistics.majorProject = data.spare7;
			})
		},
		goTo(url, item) {

			this.$emit('jumpUrl', url, this.introduce.id);
		},
	}


}
</script>

<style lang="scss" scoped>
.content-content {
	text-indent:2em;
}
.body {
	padding: 20px 280px;
	
	.meeting {
		margin: 0px 47px;
		display: flex;
		justify-content: space-around;
		background-color: #ffdfe1;
		padding: 7px 0px;
		border-radius: 25px;

		&-item {
			cursor: pointer;
			padding: 7px 45px;
			font-family: "SourceHanSansSC-Regular";
			font-size: 19px;
			color: #ec2937;
		}

		&-item-click {
			border-radius: 20px;
			background-color: #ec2937;
			color: #fff;
		}
	}

	.statistics {
		margin-top: 60px;
		display: flex;
		justify-content: space-around;

		&-item {
			text-align: center;

			.data {
				font-size: 38px;
				font-family: "adihaus-bold.woff";
			}

			.title {
				font-size: 18px;
				font-family: "SourceHanSansSC-Regular";
			}
		}
	}

	.introduce {
		.content {
			padding: 25px 55px 0px 0px;

			&-title {
				margin-top: 13px;
				font-size: 35px;
				font-family: "思源黑体CN-Bold";
			}

			&-content {
				margin-top: 24px;
				font-size: 16px;
				height: 280px;
			}

			&-img {
				margin-top: 8px;

				img {
					width: 120px;
					height: 40px;
				}
			}
		}

		.myImg {
			width: 640px;
			height: 470px;
		}
	}



	.title {
		height: 40px;

		img {
			width: 25px;
			height: 25px;
		}

		>span {
			line-height: 40px;
			margin-left: 9px;
			font-size: 32px;
			font-family: 'DingTalk_JinBuTi_Regular';
		}

		.input {
			float: right;
			width: 400px;

			>input {
				height: 40px !important;
			}

			.el-input__icon {
				line-height: 0px;
			}

			.el-input__icon::before {
				font-size: 20px;
			}
		}
	}

	.title.margin {
		margin: 58px 0px 20px 0px;
	}

	>.content {
		margin-top: 10px;

		img {
			width: 100%;
			height: 250px;
		}

		.text {
			background-color: #fff;
			padding: 25px 20px 50px 20px;
			border: 1px solid #ececec;
			margin-bottom: 30px;

			&-title {
				font-size: 21px;
				text-align: center;
				font-family: "思源黑体CN-Bold";
			}

			&-content {
				height: 40px;
				line-height: 20px;
				margin-top: 11px;
				font-size: 15px;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
}
</style>