<template>
	<div class="body">
		<el-row class="title">
			<img src="../../assets/辽洽快讯/标题装饰.png" />
			<span>热点新闻</span>
		</el-row>
		<el-row class="content" :gutter="25">
			<el-col :span="8" v-for="(item, index) in hotNews" :key="index">
				<div @click="goTo('newsIndex', item, '热点新闻')" style="cursor: pointer;">
					<img :src="item.img" />
					<div class="text">
						<div class="text-title">{{ item.title }}</div>
						<div class="text-content">{{ item.content }}</div>
						<div class="text-date">{{ item.date }}</div>
					</div>
				</div>
			</el-col>
		</el-row>
		<el-row class="title margin">
			<img src="../../assets/辽洽快讯/标题装饰.png" />
			<span>展会资讯</span>
		</el-row>
		<el-row class="content-lengthways" v-if="fairInfo.length">
			<div v-for="(item, index) in fairInfo" :key="index" @click="goTo('newsIndex', item, '展会资讯')"
				style="cursor: pointer;">

				<img :src="item.img" />
				<div class="text">
					<div class="text-title">{{ item.title }}</div>
					<div class="text-content">{{ item.content }}</div>
					<div class="text-date">{{ item.date }}</div>
				</div>
			</div>
		</el-row>
	</div>
</template>

<script>
export default {
	data() {
		return {
			hotNews: [], //展会资讯
			fairInfo:[]
		}
	},
	mounted() {
		this.getHotNews();
		this.getFairInfo();
	},
	methods: {
		//热点新闻
		getHotNews() {
			this.$baseRequest("/maintain/journalism/findList", {
				journalismCategory: "新闻资讯",
				journalismSecondaryClassification: "热点新闻",
				beginNum: 1,
				endNum: 4
			}, "post").then(res => {
				this.hotNews = [];
				res.data.forEach(item => {
					this.hotNews.push({
						id: item.id,
						img: item.journalismPhoto,
						title: item.journalismTitle,
						content: item.spare1,
						date: item.journalismDate
					})
				})
			})
		},
		//热点新闻
		getFairInfo() {
			this.$baseRequest("/maintain/journalism/findList", {
				journalismCategory: "新闻资讯",
				journalismSecondaryClassification: "展会资讯"
			}, "post").then(res => {
				this.fairInfo = [];
				res.data.forEach(item => {
					this.fairInfo.push({
						id: item.id,
						img: item.journalismPhoto,
						title: item.journalismTitle,
						content: item.spare1,
						date: item.journalismDate
					})
				})
			})
		},
		goTo(url, item, type) {
			this.$emit('jumpUrl', url, {
				id: item.id,
				journalismCategory: "新闻资讯",
				type: type
			})
		},
	}

}
</script>

<style lang="scss" scoped>
.body {
	padding: 20px 280px;
	// background-color: #f6f7f9;

	.title {
		img {
			width: 25px;
			height: 25px;
		}

		span {
			line-height: 25px;
			margin-left: 9px;
			font-size: 29px;
			font-family: 'DingTalk_JinBuTi_Regular';
		}
	}

	.title.margin {
		margin: 42px 0px 25px 0px;
	}

	.content {
		margin-top: 27px;

		img {
			width: 100%;
			height: 280px;
		}

		.text {
			margin-top: -4px;
			background-color: #fff;
			padding: 28px 20px 50px 20px;

			&-title {
				height: 58px;
				font-size: 22px;
				letter-spacing: 1px;
			}

			&-content {
				height: 60px;
				margin-top: 15px;
				line-height: 30px;
				font-size: 16px;
			}

			&-date {
				margin-top: 35px;
				font-size: 14px;
			}
		}
	}

	.text {
		&-title {
			font-size: 22px;
			font-family: "思源黑体CN-Bold";
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&-content {
			font-size: 16px;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&-date {
			font-size: 14px;
			color: #a3a3a3;
		}
	}

	.content-lengthways {
		background-color: #fff;
		padding: 10px 43px;

		>div {
			padding: 30px 0px;
			display: flex;
			border-bottom: 1px solid #eff1f7;

			img {
				width: 220px;
				height: 140px;
			}

			.text {
				margin-left: 30px;
				flex-direction: column;
				align-self: flex-start;
				padding: 18px 0px;

				&-title {
					font-size: 18px;
					-webkit-line-clamp: 1;
					padding: 0px;
				}

				&-content {

					margin-top: 10px;
					padding: 0px;
				}

				&-date {
					margin-top: 10px;
					padding: 0px;
					color: #a3a3a3;
				}
			}
		}
	}
}
</style>