<template>
    <div class="open-wrap">
        <div>
            <el-row>
                <img class="banner-unify" src="@/assets/内容页切图/顶部图-文化旅游.png" alt="">
            </el-row>
            <div class="content" v-if="!showMoreList && !showMoreListDetail">

                <el-row :gutter="20">
                    <div style="display: flex;justify-content: space-between;align-items: center;">
                        <div class="title-only">民族活动</div>
                        <div class="title-more" @click="seeMore('民族活动')">查看更多<img class="img2"
                                src="../assets/内容页切图/查看更多箭头.png" alt=""></div>
                    </div>
                    <div class="store">
                        <div class="storeItem" v-for="(item, index) in CultureList" :key="index + 'n'"
                            @click="goTo(item.id)">
                            <div class="stoPics">
                                <el-image lazy class="wall-detail" :src="item.journalismPhoto">
                                    <div slot="placeholder" class="image-slot">
                                        <img src="@/assets/首页切图/缺省.png" alt="">
                                    </div>
                                </el-image>
                            </div>
                            <div class="content">
                                <div class="contentName">
                                    <div><img src="../assets/首页切图/藏族活动.png" alt=""></div>
                                    {{ item.journalismTitle }}
                                </div>

                            </div>
                        </div>
                    </div>
                    <div style="display: flex;justify-content: space-between;align-items: center;">
                        <div class="title-only">民间艺术</div>
                        <div class="title-more" @click="seeMore('民间艺术')">查看更多<img class="img2"
                                src="../assets/内容页切图/查看更多箭头.png" alt=""></div>
                    </div>
                    <div class="store">
                        <div class="storeItem" v-for="(item, index) in artList" :key="index + 'n'"
                            @click="goTo(item.id)">
                            <div class="stoPics">
                                <el-image lazy class="wall-detail" :src="item.journalismPhoto">
                                    <div slot="placeholder" class="image-slot">
                                        <img src="@/assets/首页切图/缺省.png" alt="">
                                    </div>
                                </el-image>
                            </div>
                            <div class="content">
                                <div class="contentName">
                                    {{ item.journalismTitle }}
                                </div>
                                <div class="adress">
                                    {{ item.spare1 }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div style="display: flex;justify-content: space-between;align-items: center;">
                        <div class="title-only">先进典型</div>
                        <div class="title-more" @click="seeMore('先进典型')">查看更多<img class="img2"
                                src="../assets/内容页切图/查看更多箭头.png" alt=""></div>
                    </div>

                    <div class="introduct-list" v-for="(item, index) in modelList" :key="index + '1'">
                        <div>
                            <img v-if="item.journalismPhoto" :src="item.journalismPhoto" alt="">
                            <img v-else src="../assets/首页切图/占位.png" alt="">
                        </div>
                        <div class="list-right">
                            <div class="list-title">{{ item.journalismTitle }}</div>
                            <div class="list-text">
                                {{ item.spare1 }}
                            </div>
                            <div class="list-time">
                                <div style="display: flex;justify-content: flex-start;">
                                    <div class="btn-fabu" @click="goTo(item.id)">查看详情</div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </el-row>
            </div>
        </div>
        <moreList v-if="showMoreList" :typeName="typeName" @goBack="goBack"></moreList>
        <openDetails v-if="showMoreListDetail" @goBack="goBack" :id="id" ref="openDetails"></openDetails>
        <!-- <moreListDetail v-if="showMoreListDetail"  @goBack="goBack" :typeName="typeName" :only="true"></moreListDetail> -->
    </div>
</template>

<script>
import moreList from './components/moreList.vue';

import openDetails from './components/openDetails.vue';

export default {
    components: {
        moreList, openDetails
    },
    data() {
        return {
            showMoreList: false,
            detailsFlag: false,
            showMoreListDetail: false,
            cityName: "",
            cityList: [],
            id: "",
            typeName: '',
            CultureList: [],
            artList: [],
            modelList: []
        }
    },
    mounted() {
        this.findList();
    },
    methods: {

        goTo(e) {
            this.id = e;
            this.showMoreListDetail = true;
            this.$nextTick(() => {
                document.documentElement.scrollTop = 200;
                this.$refs["openDetails"].details();
            })
        },
        seeMore(e) {
            this.typeName = e;
            this.showMoreList = true;
            this.$nextTick(() => {
                document.documentElement.scrollTop = 200;

            })
        },
        findList() {
            this.$baseRequest('/maintain/journalism/findList', { journalismCategory: '文化旅游', beginNum: 1, endNum: 5, journalismSecondaryClassification: '民族活动' }, 'post').then(res => {
                this.CultureList = res.data;
            });
            this.$baseRequest('/maintain/journalism/findList', { journalismCategory: '文化旅游', beginNum: 1, endNum: 5, journalismSecondaryClassification: '民间艺术' }, 'post').then(res => {
                this.artList = res.data;
            });
            this.$baseRequest('/maintain/journalism/findList', { journalismCategory: '文化旅游', beginNum: 1, endNum: 4, journalismSecondaryClassification: '先进典型' }, 'post').then(res => {
                this.modelList = res.data;
            });
          
            if (this.$route.query.id) {
           
                this.goTo(this.$route.query.id);
            };
        },

        goBack() {
            this.showMoreList = false;
            this.showMoreListDetail = false;

        }
    }
}
</script>

<style src="../style/element-ui-class.css"></style>
<style></style>
<style scoped lang="scss">
.title-only {
    font-size: 28px;
    margin: 20px 0px;
}

.title-more {
    font-size: 16px;
    color: #A3A7BC;
    cursor: pointer;

    img {
        width: 11px;
        vertical-align: inherit;
    }
}

.indexBox {
    display: flex;
    justify-content: space-between;

}

.itemIndex {
    width: 32%;
    position: relative;

    img {
        width: 100%;
        border-radius: 10px;
        height: 350px;
    }

    .itemIndexImg {
        position: absolute;
        bottom: 20px;
        left: 0;
        font-size: 20px;
        padding: 10px;
        color: #fff;
    }
}

.dingbu {
    width: 100%;
    height: auto;
}

.introduct-list {
    display: flex;
    margin-top: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid #f0f1f7;
    cursor: pointer;

    // align-items: center;
    img {
        width: 280px;
        height: 180px;

    }

    .list-right {
        display: flex;
        flex-direction: column;
        padding-left: 25px;

        .list-title {
            font-size: 23px;
            font-weight: 600;
            line-height: 36px;

        }

        .list-text {
            margin-top: 10px;
            font-size: 15px;
            color: #8b8b8b;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .list-time {
            margin-top: 10px;
            font-size: 16px;
            color: #8b8b8b;
        }
    }
}

.open-wrap {
    

    .content {
        padding: 30px 310px;

        .el-input {
            width: 21%;
        }

        .el-button {
            margin-left: 10px;
            width: 7%;
            background-color: #1e72ec;
        }

        .list {
            padding: 20px;
            background-color: #fff;
            margin-top: 20px;

            .list-left {
                img {
                    border-radius: 5px;
                    width: 100%;
                    height: 150px;
                }
            }

            .list-right {
                padding: 0 15px;
                display: flex;
                flex-direction: column;
                height: 150px;

                .list-title {
                    font-size: 20px;
                    font-weight: bold;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .list-content {
                    margin-top: 10px;
                    font-size: 13px;
                    color: #666666;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }

                .list-el-button {
                    margin-top: auto;
                    margin-left: auto;
                    width: 110px;
                }
            }
        }
    }
}

.btn-fabu {
    padding: 7px 15px;
    text-align: left;
    background-color: #F86C14;
    border-radius: 4px;
    color: #fff;
    font-size: 18px;
    margin-top: 15px;
    cursor: pointer;
}

.store {
    margin-top: 40px;
    display: flex;
    justify-content: flex-start;

    .storeItem {
        width: 24%;
        background-color: #fff;
        border-radius: 10px;
        margin-right: 20px;
        cursor: pointer;

        .stoPics {
            width: 100%;
            height: 280px;
            overflow: hidden;
            margin-bottom: 0px;
            border-radius: 10px 10px 0px 0px;

            .wall-detail {
                width: 100%;
                height: 280px;
            }

            .image-slot {
                img {
                    width: 100%;
                    height: 280px;
                }

            }
        }

        img {
            display: block;
            width: 100%;
        }

        .content {
            background-color: #fff;
            padding: 30px 20px;
            border-radius: 0px 0px 10px 10px;
        }

        .contentName {
            font-size: 20px;
            font-weight: 600;
            color: #000;
            margin-bottom: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            img {
                width: 80px;
            }
        }

        .adress {
            color: #6C6C6C;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 18px;
        }
    }
}

.store :last-child {
    margin-right: 0px;
}
</style>