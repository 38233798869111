import { render, staticRenderFns } from "./keyDetail.vue?vue&type=template&id=0c437856&scoped=true&"
import script from "./keyDetail.vue?vue&type=script&lang=js&"
export * from "./keyDetail.vue?vue&type=script&lang=js&"
import style0 from "@/style/element-ui-class.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./keyDetail.vue?vue&type=style&index=1&id=0c437856&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c437856",
  null
  
)

export default component.exports