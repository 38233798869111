<template>
    <div class="hezuo-wrap">
        <el-row>
            <img class="banner-unify" src="../assets/内容页切图/顶部图-那曲消费.png" alt="">
        </el-row>
        <div class="wrap-Row" v-if="!showDetail && !showMoreList">
            <div class="content">
                <el-row>
                    <el-col :span="24">
                        <div class="hezuo-left">
                            <div>
                                <div class="title">
                                    <em>西藏精选</em>
                                </div>
                                <div class="title-intro">
                                    精选旅程，探索非凡之路
                                </div>
                            </div>
                            <div class="pic-top">
                                <div>
                                    <img @click="clickItem('精选旅游路线')" src="../assets/首页切图/西藏精选-精选旅游路线.png" alt="">
                                </div>
                                <div>
                                    <img @click="clickItem('非遗文创精粹')" src="../assets/首页切图/西藏精选-非遗文创精粹.png" alt="">
                                </div>
                                <div>
                                    <img @click="clickItem('热门景点推荐')" src="../assets/首页切图/西藏精选-热门景点推荐.png" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="hezuo-left">
                            <div class="hezuo-left-box">
                                <div class="title">
                                    <em>西藏攻路</em>
                                </div>
                                <div class="title-intro">
                                    逐梦天路，解锁高原秘境的深度游指南
                                </div>
                            </div>
                            <div class="pic-bottom">
                                <div class="left">
                                    <img @click="clickItem('西藏网红餐厅')" src="../assets/首页切图/西藏攻略-西藏网红餐厅.png" alt="">
                                </div>
                                <div class="right">
                                    <div>
                                        <img @click="clickItem('西藏特产美物美食')" src="../assets/首页切图/西藏攻略-西藏特产美物美食.png"
                                            alt="">
                                    </div>
                                    <div>
                                        <img @click="clickItem('舒适酒店推荐')" src="../assets/首页切图/西藏攻略-舒适酒店推荐.png" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>

                    </el-col>
                </el-row>
            </div>

        </div>
        <moreList v-if="showMoreList" :typeName="typeName" @goBack="goBack"></moreList>
        <detailPage v-if="showDetail" :typeName="typeName" @goBack="goBack"></detailPage>
    </div>
</template>

<script>

import moreList from './TourIntroduction/moreList.vue';
import detailPage from './strategyCom/index.vue'
export default {
    components: { detailPage, moreList },
    data() {
        return {
            showMoreList: false,
            typeName: '',
            showDetail: false,
            cityList: ['展会简介', '展会分布'],
            cityFage: "展会简介",
            showPage: true,

            index: '',
            content: '1',
            nameList: ['沈阳世茂希尔顿酒店', '沈阳威斯汀酒店', '沈阳皇朝万鑫万豪酒店', '沈阳金廊智选假日酒店', '沈阳芊丽酒店'],
        }
    },
    mounted() {

    },
    methods: {
        goBack() {

            this.showMoreList = false;
            this.showDetail = false;

        },
        clickItem(item) {
            this.typeName = item;
            switch (item) {
                case '精选旅游路线':
                    this.showMoreList = true;
                    break;
                case '热门景点推荐':
                    this.showMoreList = true;
                    break;
                case '西藏网红餐厅':
                    this.showMoreList = true;
                    break;
                case '非遗文创精粹':
                    this.showDetail = true;
                    break;
                case '西藏特产美物美食':
                    this.showDetail = true;
                case '舒适酒店推荐':
                    this.showDetail = true;

                    break;
            }

        },
        cityClick(e) {
            this.showPage = true;
            this.$nextTick(res => {
                document.documentElement.scrollTop = 200;
            })
            this.cityFage = e;
        },
        clickDetail(name) {
            this.$baseRequest('/not/hotel/findList', {}, 'post').then(res => {
                this.detail = res.data.find(item => item.name == name);
                this.$nextTick(res => {
                    document.documentElement.scrollTop = 200;
                })
                this.showPage = false
            })

        }
    }
}
</script>

<style src="../style/element-ui-class.css"></style>

<style scoped lang="scss">
.pic-top {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    div {
        width: 32%;

        img {
            width: 100%;
            height: auto;
            cursor: pointer;
        }
    }
}

.pic-bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .left {
        width: 49%;

        img {
            width: 100%;
            height: auto;
            cursor: pointer;
        }
    }

    .right {
        width: 49%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        img {
            width: 100%;
            height: auto;
            cursor: pointer;

        }
    }
}

.hezuo-wrap {
    

    .wrap-Row {
        padding: 60px 310px;
    }

    .content {


        .hezuo-left {
            background-color: #FFF6F0;
            padding: 20px;
            border-radius: 5px;
            margin-bottom: 30px;

            .title {
                margin-bottom: 5px;

                em {
                    font-size: 30px;
                    color: #F86C14;
                    font-weight: 600;
                    font-style: italic;
                    font-style: oblique;
                    letter-spacing: 1px;
                }
            }

            .title-intro {
                font-size: 20px;
            }



        }


    }



}
</style>