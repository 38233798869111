<template>
    <div class="hezuo-wrap">
        <el-row>
            <img class="banner-unify" src="../assets/内容页切图/顶部图-项目援藏.png" alt="">
        </el-row>

        <div class="wrap-Row">
            <FixedContent v-if="!showPage" @ControlsBack="ControlsBack" :FixedContentList="['详情']"></FixedContent>

            <div class="content" v-if="cityList.length">
                <el-row>
                    <el-col :span="5" class="hezuo-left">
                        <div class="hezuo-left-list" :class="cityFage == item.subsetName ? 'click' : ''"
                            v-for="(item, index) in cityList" :key="index + 'tree'" @click="cityClick(item.subsetName)">
                            <div class="hezuo-left-title">{{ item.subsetName }}</div>
                            <div class="hezuo-left-img">
                                <img src="../assets/内容页切图/城市导航-箭头-白.png" v-if="cityFage == item.subsetName" />
                                <img src="../assets/内容页切图/城市导航-箭头-灰.png" v-else />
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="19" class="hezuo-right">
                        <el-row v-if="showPage">
                            <div class="right-btns">
                                <div class="hezuo-left-list-right" :class="batchFage == item.subsetName ? 'click' : ''"
                                    v-for="(item, index) in threeTree" :key="index + 'tree'"
                                    @click="cityClickThree(item.subsetName)">
                                    <div class="hezuo-left-title">{{ item.subsetName }}</div>

                                </div>
                            </div>
                            <div class="introduct-list" v-for="(item, index) in list" :key="index + '1'"
                                @click="clickDetail(item.id)">
                                <div>
                                    <img v-if="item.journalismPhoto" :src="item.journalismPhoto" alt="">
                                    <img v-else src="../assets/首页切图/占位.png" alt="">
                                </div>
                                <div class="list-right">
                                    <div class="list-title">{{ item.journalismTitle }}</div>
                                    <div class="list-text">
                                        {{ item.spare1 }}
                                    </div>
                                    <div class="list-time">
                                        <div style="display: flex;justify-content: flex-start;">
                                            <div class="btn-fabu">查看详情</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-row>
                        <el-row v-else>
                            <keyDetail ref="smallDetail" :id="id" >
                            </keyDetail>
                        </el-row>
                    </el-col>
                </el-row>
            </div>

        </div>

    </div>
</template>

<script>

import keyDetail from './components/keyDetail'
export default {
    components: { keyDetail },
    data() {
        return {
            item: {},
            cityList: [],
            cityFage: "",
            showPage: true,
            detailSmall: '',
            detailStore: '',
            newsPage: false,
            storeList: [],

            list: [],
            batchFage: '',
            threeTree: [],
        }
    },
    mounted() {
        this.getLeftList();
    },
    methods: {
        // 获取右侧具体列表
        getList() {
            this.$baseRequest('/maintain/journalism/charm/grace/findList', { areas: '项目援藏', project: this.cityFage, batch: this.batchFage }, 'post').then(res => {
                this.list = res.data;
            })
        },
        clickChoose(index) {
            this.chooseListIndex = index;
            this.getLeftList();
        },
        // 获取三级列表
        getTree() {
            this.$baseRequestGet('/sysDictionaries/subsetCharm/grace/list?info=' + this.cityFage, {}, 'get').then(res => {
                this.threeTree = res.data;
                this.batchFage = res.data.length ? res.data[0].subsetName : '';
                this.getList()
            })
        },
        // 获取左侧选择列表
        getLeftList() {
            this.$baseRequestGet('/sysDictionaries/subsetCharm/grace/list?info=' + '项目援藏', {}, 'get').then(res => {
                this.cityList = res.data;
                this.cityFage = res.data.length ? res.data[0].subsetName : '';
                if (this.cityFage) {
                    this.getTree();
                }
              
            })
        },
        ControlsBack() {
            this.showPage = true;
        },
        cityClickThree(e) {
            this.batchFage = e;
            this.getList()
        },
        cityClick(e) {
            this.cityFage = e;
            if (!this.showPage) {
                this.showPage = true;
            }
            this.getTree();

        },
        clickDetail(id) {
            this.id = id;
            this.showPage = false;
        },

        detailShang(item) {
            this.detailStore = item;

        }
    }
}
</script>

<style src="../style/element-ui-class.css"></style>

<style scoped lang="scss">
.chooseItem {
    height: 60px;
    padding: 0px 310px;
    background-color: #fff;
    border-bottom: 1px solid #EBEBEB;
    display: flex;
    align-items: center;

    .chooseItem-item {
        width: 100px;
        font-size: 20px;
        font-weight: 600;
        position: relative;
        cursor: pointer;

        .line {
            position: absolute;
            bottom: -17px;
            left: -2px;
            width: 40px;
            border-radius: 2px;
            height: 4px;
            background-color: #F86C14;
        }

    }

}

.hezuo-wrap {
    

    .wrap-Row {
        padding: 30px 310px;
    }

    .content {
        margin-top: 20px;

        .hezuo-left {
            background-color: #fff;
            padding: 20px;

            .hezuo-left-list {
                height: 60px;
                border-radius: 8px;
                padding: 0 25px;
                display: flex;
                cursor: pointer;

                .hezuo-left-title {
                    line-height: 60px;
                    font-size: 18px;
                    display: inline-block;
                }

                .hezuo-left-img {
                    float: right;
                    margin: auto 0px auto auto;

                    img {
                        width: 10px;
                    }
                }
            }

            .click {
                color: #fff;
                background-image: url(../assets/首页切图/展会简介背景.png);
                background-repeat: no-repeat;
                background-size: cover;
            }
        }

        .hezuo-right {
            padding-left: 35px;

            .hezuo-right-vessel {
                margin-top: 30px
            }

            .right-btns {
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                gap: 10px;

                .hezuo-left-list-right {

                    border-radius: 8px;
                    padding: 10px 20px;
                    background-color: #fff;

                    cursor: pointer;

                    .hezuo-left-title {


                        font-size: 18px;
                        display: inline-block;
                    }

                }

                .click {
                    color: #fff;
                    background-color: #F86C14;
                    background-repeat: no-repeat;
                    background-size: cover;
                }

            }

            .hezuo-right-placeholder {
                height: 10px;
            }

            .hezuo-right-project {
                background-color: #fff;
                height: 90px;
                display: flex;
                align-items: center;
                padding: 0 30px;
                margin-top: 20px;

                .project-title {
                    font-size: 23px;
                    margin-left: 20px;
                }

                .project-right {
                    margin-left: auto;
                }
            }
        }
    }
}




.introduct-list {
    display: flex;
    margin-top: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid #f0f1f7;
    cursor: pointer;

    // align-items: center;
    img {
        width: 280px;
        height: 180px;

    }

    .list-right {
        display: flex;
        flex-direction: column;
        padding-left: 25px;

        .list-title {
            font-size: 23px;
            font-weight: 600;
            line-height: 36px;

        }

        .list-text {
            margin-top: 10px;
            font-size: 15px;
            color: #8b8b8b;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .list-time {
            margin-top: 10px;
            font-size: 16px;
            color: #8b8b8b;
        }
    }
}

.btn-fabu {
    padding: 7px 15px;
    text-align: left;
    background-color: #F86C14;
    border-radius: 4px;
    color: #fff;
    font-size: 18px;
    margin-top: 5px;
    cursor: pointer;
}
</style>