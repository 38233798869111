<template>
    <div class="hezuo-wrap">
        <el-row>
            <img class="banner-unify" src="../assets/内容页切图/顶部图-云展厅.png" alt="">
        </el-row>

        <div class="wrap-Row">
            <FixedContent v-if="!showPage" @ControlsBack="ControlsBack" :FixedContentList="['详情']"></FixedContent>
            <div v-if="!id">
                <el-carousel indicator-position="outside" class="ExhibitionLeftHeight">
                    <el-carousel-item v-for="(item, index) in 4" :key="index + 'c'">
                        <div @click="clickNewsDetail(item)" style="cursor: pointer;">
                            <el-image class="carousel" :src="require('../assets/首页切图/云展厅banner.png')"></el-image>
                            <!-- <div class="ExhibitionLeftHeightTitle">
                                <div></div>
                            </div> -->
                        </div>

                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="content" v-if="!id">
                <el-row>
                    <el-col :span="5" class="hezuo-left">
                        <div class="hezuo-left-list" :class="cityFage == item ? 'click' : ''"
                            v-for="(item, index) in cityList" :key="index + 'tree'" @click="cityClick(item)">
                            <div class="hezuo-left-title">{{ item }}</div>
                            <div class="hezuo-left-img">
                                <img src="../assets/内容页切图/城市导航-箭头-白.png" v-if="cityFage == item" />
                                <img src="../assets/内容页切图/城市导航-箭头-灰.png" v-else />
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="18" class="normal-row" v-if="cityFage == '展会介绍'">
                        <div class="introdiction-new">
                            今年是辽宁对口支援西藏的30周年。自中央实施对口支援西藏政策以来，辽宁省积极响应，选派了一批又一批优秀的援藏干部人才，与西藏人民携手共进，共同建设美丽西藏。为全面展示辽宁援藏30年的工作成果，加强两地的那曲文旅与合作，辽宁援藏30周年成果展应运而生。
                            项目成果展示:成果展上，辽宁省重点展示了30年来在西藏实施的各类援藏项目，包括基础设施建设、教育、医疗、文化等多个领域。其中，涉及农牧业、交通、通信、市政等多个方面的基础设施项目，极大地改善了西藏当地的基础设施条件，为经济社会发展奠定了坚实基础。
                        </div>
                    </el-col>
                    <el-col :span="18" class="normal-row" v-if="cityFage == '展厅分布'">
                        <el-image class="zhantingfenbu" :src="require('../assets/内容页切图/展厅分布图.jpg')"
                            :preview-src-list="[require('../assets/内容页切图/展厅分布图.jpg')]"></el-image>

                    </el-col>
                    <el-col :span="19" class="hezuo-right" v-if="cityFage == '展区展品'">
                        <div class="search-box">
                            <el-input clearable class="search" v-model="searchContent" placeholder="请输入搜索条件"></el-input>
                            <el-button @click="clickBtns" class="btn" type="primary">搜索</el-button>
                        </div>
                        <el-row>
                            <div class="store">
                                <div class="storeItem" v-for="(item, index) in storeList" :key="index + 'n'"
                                    @click="clickStore(item.id)">
                                    <div class="stoPics">
                                        <el-image lazy class="wall-detail" :src="item.picture">
                                            <div slot="placeholder" class="image-slot">
                                                <img src="@/assets/首页切图/缺省.png" alt="">
                                            </div>
                                        </el-image>
                                    </div>
                                    <div class="content">
                                        <div class="contentName">
                                            {{ item.productName }}
                                        </div>
                                        <div class="adress">
                                            {{ item.productIntroduction }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </el-row>
                        <div class="el-pageing" v-if="storeList.length">
                            <el-pagination @current-change="handleCurrentChange" background layout="prev, pager, next"
                                :total="total">
                            </el-pagination>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <el-row v-if="cityFage == '展区展品' && id">
                <detailPage ref="smallDetail" :idDetails="id" :typeName="'西藏特产美物美食'">
                </detailPage>
            </el-row>

        </div>

    </div>
</template>

<script>

import detailPage from '../components/detailStyleOne'
export default {
    components: { detailPage },
    data() {
        return {
            id: '',
            cityList: ['展区展品', '展会介绍', '展厅分布'],
            cityFage: "展区展品",
            showPage: true,
            storeList: [],
            list: [],
            beginNum: 1,
            total: 0,
            searchContent: ''
        }
    },
    mounted() {
        // this.getLeftList();
        this.getStore()
    },
    methods: {
        clickBtns() {
            this.getStore(true)
        },
        handleCurrentChange(val) {
            this.beginNum = val;
            this.getStore()
        },
        getStore(isClear) {
            if (isClear) {
                this.beginNum = 1;
            }
            this.$baseRequest('/not/goods/boutique/pageLists', {
                province: '',
                productType: '展厅产品',
                endNum: 6,
                beginNum: this.beginNum,
                productName: this.searchContent
            }, 'POST').then(res => {
                this.storeList = res.data.records;
                this.total = Number(res.data.total)
            })
        },
        // 获取右侧具体列表
        getList() {

            this.$baseRequest('/maintain/journalism/charm/grace/findList', { areas: this.cityFage }, 'post').then(res => {
                this.list = res.data;

            })
        },

        // 获取左侧选择列表
        getLeftList() {
            this.$baseRequestGet('/sys/dictionaries/list?info=' + '批次', {}, 'get').then(res => {
                this.cityList = res.data;
                this.cityFage = res.data.length ? res.data[0].dictionariesName : '';
                this.cityFage ? this.getList() : this.list = [];

            })
        },
        clickNewsDetail() {

        },
        ControlsBack() {
            this.id = '';
            this.showPage = true;
        },

        cityClick(e) {
            this.searchContent = '';
            this.beginNum = 1;
            if (this.cityFage == '展区展品') {
                this.getStore();
            };
            this.cityFage = e;
            if (!this.showPage) {
                this.showPage = true;
            }
            // this.getList();
        },
        clickStore(id) {
            this.id = id;
            this.showPage = false;
        },

    }
}
</script>

<style src="../style/element-ui-class.css"></style>
<style>
.search .el-input__inner {
    font-size: 18px;
}

.el-button--primary {
    font-size: 17px;
}
</style>
<style scoped lang="scss">
.ExhibitionLeftHeight {
    height: 500px !important;
    position: relative;
    margin-bottom: 20px;
    .carousel {
        width: 100%;
        height: 500px;
    }

    .ExhibitionLeftHeightTitle {

        width: 100%;

        overflow: hidden;

        div {
            width: 80%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

        }

        position: absolute;
        color: #fff;
        left: 0px;
        bottom: 0px;
        padding: 20px;
        font-size: 18px;
        background-color: rgba(0, 0, 0, 0.4);

    }
}


.hezuo-wrap {
    

    .wrap-Row {
        padding: 30px 310px;
    }

    .content {


        .hezuo-left {
            background-color: #fff;
            padding: 20px;

            .hezuo-left-list {
                height: 60px;
                border-radius: 8px;
                padding: 0 25px;
                display: flex;
                cursor: pointer;

                .hezuo-left-title {
                    line-height: 60px;
                    font-size: 18px;
                    display: inline-block;
                }

                .hezuo-left-img {
                    float: right;
                    margin: auto 0px auto auto;

                    img {
                        width: 10px;
                    }
                }
            }

            .click {
                color: #fff;
                background-image: url(../assets/首页切图/展会简介背景.png);
                background-repeat: no-repeat;
                background-size: cover;
            }
        }

        .hezuo-right {
            padding-left: 35px;

            .hezuo-right-vessel {
                margin-top: 30px
            }

            .hezuo-right-placeholder {
                height: 10px;
            }

            .hezuo-right-project {
                background-color: #fff;
                height: 90px;
                display: flex;
                align-items: center;
                padding: 0 30px;
                margin-top: 20px;

                .project-title {
                    font-size: 23px;
                    margin-left: 20px;
                }

                .project-right {
                    margin-left: auto;
                }
            }
        }
    }
}

.normal-row {
    background-color: #fff;
    padding: 20px;
    margin: 20px;

}

.store {

    margin-top: 40px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .storeItem {
        width: 31%;

        background-color: #fff;
        border-radius: 10px;
        margin-right: 20px;
        cursor: pointer;

        .stoPics {
            width: 100%;
            height: 280px;
            overflow: hidden;
            margin-bottom: 0px;
            border-radius: 10px 10px 0px 0px;

            .wall-detail {
                width: 100%;
                height: 280px;
            }

            .image-slot {
                img {
                    width: 100%;
                    height: 280px;
                }

            }

        }

        img {
            display: block;
            width: 100%;

        }

        .content {
            background-color: #fff;
            padding: 30px 20px;
            border-radius: 0px 0px 10px 10px;
        }

        .contentName {
            font-size: 20px;
            font-weight: 600;
            color: #000;
            margin-bottom: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .adress {
            color: #6C6C6C;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.store :last-child {
    margin-right: 0px;
}

.zhantingfenbu {
    width: 100%;
    height: auto;
    cursor: pointer;
}

.el-pageing {
    text-align: center;
    margin-top: 20px;
}

.search-box {
    // margin: 30px 0px;
    margin-top: 30px;

    .search {
        width: 90%;
        margin-right: 10px;
    }
}
.introdiction-new {
    padding: 20px;
    font-size: 19px;
    letter-spacing: 1px;
    line-height: 40px;
    text-indent: 2em;
}
</style>