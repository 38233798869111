<!--
 * @Author: jiangjinchi 1468922694@qq.com
 * @Date: 2023-09-07 19:10:06
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2024-09-18 14:21:56
 * @FilePath: \department-of-commerce\PC\src\view\components\openDetails.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<div class="openDetails-wrap">

		<div class="content">
			<FixedContent :FixedContentList="FixedContentList" @ControlsBack="ControlsBack"></FixedContent>
			<el-row>
				<el-col :span="24" class="rich-text">
					<div v-if="$route.name == '文化旅游' && infoData.banner">
						<el-image  class="banner-img" :src="infoData.banner" :preview-src-list="[infoData.banner]"></el-image>
						<!-- <img class="banner-img" :src="infoData.banner" alt=""> -->
					</div>
					<div class="journalismTitle">
						{{ infoData.journalismTitle }}
					</div>
					<div class="journalismDate">
						{{ infoData.journalismDate }}<span> {{ infoData.source }}</span>
					</div>
					<div class="ql-container ql-snow" style="border: none;">
						<div class="ql-editor"  @click="showImage($event)" v-html="infoData.journalismContent"></div>
					</div>
					<div id="chatRef">
						<chat :id="id" :chatType="'article'" :fatherScrollHeigh="fatherScrollHeigh"></chat>
					</div>
				</el-col>
			</el-row>
		</div>
		<elImageViewer v-if="dialogVisible" :on-close="closeImage" :url-list="[url]" />
	</div>
</template>

<script>

export default {

	props: {

		FixedContentList: {
			type: Array,
			default: () => ['详情']
		},
		id: {
			type: [String, Number]
		}
	},
	data() {
		return {
			infoData: {
				journalismContent: ''
			},
			url: '',
			dialogVisible: false,
			playerOptionsList: [],
			fatherScrollHeigh: ''

		}
	},

	mounted() { },
	methods: {
		ControlsBack(e) {
			this.$emit('goBack', e);

		},
		showImage(e) {
			if (e.target.tagName == 'IMG') {

				this.url = e.target.src
				this.dialogVisible = true
			}
		},
		closeImage() {
			this.dialogVisible = false
			this.url = '';
		},
		details() {
			this.$baseRequest('/maintain/journalism/details?info=' + this.id, {}, 'get').then(res => {
				this.infoData = res.data;
				if(this.infoData.journalismContent) {
					this.infoData.journalismContent = this.infoData.journalismContent.replace(/<iframe/g, "<video controls style='width:100%;height:auto;'")
				}
				this.$nextTick(res => {
					this.fatherScrollHeigh = document.getElementById('chatRef').offsetTop;
				})
			})
		},
	}
}
</script>

<style src="@/style/element-ui-class.css"></style>

<style scoped lang="scss">
.openDetails-wrap {
	

	.content {
		padding: 60px 310px;

		.breadcrumb {
			height: 15px;
			display: flex;
			align-items: center;
			line-height: 15px;
			color: #8389a6;
			margin-bottom: 20px;
			font-size: 19px;

			div {
				display: flex;
				align-items: center;

				img {
					width: 18px;
					height: auto;
					margin-right: 10px;
				}
			}

			i {
				margin: 0 5px;
			}
		}


		.rich-text {
			margin-top: 20px;
			background-color: #fff;
			padding: 50px 80px;

			.journalismTitle {
				font-size: 28px;
				color: #000;
				margin-bottom: 5px;
			}

			.journalismDate {
				color: #7B7B7B;
				margin-top: 10px;
				margin-bottom: 30px;
			}
		}
	}
}

.carousel {
	width: 100%;
	height: 457px;
}

.videoBox {
	margin-bottom: 30px;
}

.cityBox {
	font-size: 28px;
	margin-bottom: 30px;
	color: #000;
}

.banner-img {
	width: 100%;
	height: auto;
	margin-bottom: 10px;
}
</style>