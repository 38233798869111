<!--
 * @Author: jiangjinchi 1468922694@qq.com
 * @Date: 2023-09-01 10:55:04
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2024-09-16 15:10:39
 * @FilePath: \sizedemo\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="sind-Page ">
        <div class="login-form">

            <form class="form">
                <div class="form-title">
                    注册
                </div>
                <div class="flex-column">
                    <label>企业名称 </label>
                </div>
                <div class="inputForm">

                    <input type="text" v-model="form.enterpriseName" class="input" placeholder="请输入企业名称">
                </div>

                <div class="flex-column">
                    <label>手机号 </label>
                </div>
                <div class="inputForm">
                    <input type="text" v-model="form.enterpriseMobileNumber" class="input" placeholder="请输入您的手机号">
                </div>
                <div class="flex-column">
                    <label>验证码 </label>
                </div>
                <div class="inputForm ">
                    <input type="text" v-model="form.enterpriseVerificationCode" style="width: 75%;" class="input"
                        placeholder="请输入验证码">

                    <span class="code" @click="send" v-if="isSend">发送验证码</span>
                    <span class="code" v-if="!isSend">{{ sendmsg }}</span>

                    <!-- <el-button size='mini' >发送验证码</el-button>
                    <el-button size='mini' v-if="!isSend">{{ sendmsg }}</el-button> -->

                </div>
                <div class="flex-column">
                    <label>设置密码 </label>
                </div>
                <div class="inputForm">
                    <input type="text" v-model="form.spare1" class="input" placeholder="请输入您的密码">
                </div>

                <div class="btn">
                    <img @click="clickSumbit" src="@/assets/其他/供采对接/注册.png" alt="">
                </div>
                <div class="signin">
                    <span>已有账号？</span><span @click="clickLogin" class="goSign">立即登录</span>
                </div>
            </form>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            sendmsg: '发送验证码',
            isSend: true,
            options: [],
            form: {
                enterpriseName: '',
                enterpriseMobileNumber: '',
                enterpriseVerificationCode: '',
                spare1: '',

            }
        }
    },
    mounted() {

    },

    methods: {
        send() {
            if (!this.form.enterpriseMobileNumber) {

                this.$message.warning('请填写手机号后获取验证码！')
            } else if (/^1(3|4|5|6|7|8)\d{9}$/.test(this.form.enterpriseMobileNumber) == false) {
                this.$message.warning("请填写正确的手机号后获取验证码！");
            } else {
                this.$baseRequest("/not/onlineEnterprise/verification?info=" + this.form.enterpriseMobileNumber, {}, "get").then(res => {
                    this.isSend = false;
                    let timer = 60;
                    this.sendmsg = timer + "s";
                    this.timeFun = setInterval(() => {
                        --timer;
                        this.sendmsg = timer + "s";
                        if (timer == 0) {
                            this.isSend = true;
                            this.sendmsg = "重新发送";
                            clearInterval(this.timeFun);
                        }
                    }, 1000);
                })

            }
        },


        clickSumbit() {
            if (!this.form.enterpriseName) {
                this.$message.warning('请输入企业名')
                return;
            };
            if (!this.form.enterpriseMobileNumber) {
                this.$message.warning('请输入手机号')
                return;
            };
            if (!this.form.enterpriseVerificationCode) {
                this.$message.warning('请输入验证码')
                return;
            };
            if (!this.form.spare1) {
                this.$message.warning('请输入密码')
                return;
            };
            this.$baseRequest('/not/onlineEnterprise/add', this.form, 'post').then(res => {
                this.$message.success('注册成功')
                setTimeout(() => {
                    this.clickLogin();
                }, 1500);
             
            });
        },
        clickLogin() {
            this.$router.replace({ path: '/loginPage' })
        }
    }

}
</script>
<style src="@/style/elementSingle.css"></style>
<style>
.sind-Page .el-input {
    width: 440px;
    background-color: #F5F6F8;
}

.sind-Page .el-input__inner {
    border: none;
    background-color: #F5F6F8;
    padding-left: 10px;
    color: black;

}
</style>
<style scoped lang="scss">
.sind-Page {
    width: 100vw;
    height: 1300px;
    background-image: url(@/assets/内容页切图/线上洽谈-注册bg.png);
    background-size: 100% 100%;
    position: relative;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    // background-color: pink;
    padding: 30px;
    width: 450px;

    // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    .form-title {
        text-align: center;
        font-size: 28px;
        margin-bottom: 40px;
    }
}

.login-form {
    position: absolute;
    right: 35%;
    top: 150px;
}

::placeholder {
    // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.form button {
    align-self: flex-end;
}

.flex-column>label {
    color: #151717;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1px;
}

.inputForm {
    border: 1.5px solid #ecedec;
    border-radius: 6px;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    transition: 0.2s ease-in-out;
    background-color: #F5F6F8;
    margin-bottom: 20px;

    .code {
        color: #2C7BED;
        cursor: pointer;
    }
}

.input {
    margin-left: 10px;
    // border-radius: 10px;
    border: none;
    width: 85%;
    height: 100%;
    background-color: #F5F6F8;
}

.single {
    margin-left: 10px;
    // border-radius: 10px;
    border: none;
    width: 72%;
    height: 100%;
    background-color: #F5F6F8;
}

.input:focus {
    outline: none;
}

.inputForm:focus-within {
    // border: 1.5px solid #2d79f3;
}

.signin {
    text-align: center;
    margin-top: 10px;
    font-size: 17px;

    .goSign {
        color: #EA0E1E;
        cursor: pointer;
    }
}

.btn {
    text-align: center;
    margin-top: 30px;

    img {
        width: 310px;
        height: 50px;
        cursor: pointer;
    }
}
</style>