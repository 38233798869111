<!--
 * @Author: jiangjinchi 1468922694@qq.com
 * @Date: 2024-09-12 18:44:50
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2024-09-17 18:44:02
 * @FilePath: \pc\src\view\HighlightMoment\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="body">
        <div class="main">

            <div class="jingcaishunjian">

                <div style="display: flex;justify-content: space-between;align-items: center;">
                    <div class="title"> <img src="../../assets/辽洽快讯/标题装饰.png" />
                        <span>精彩瞬间</span>
                    </div>
                    <div class="lookMoreNoraml">
                        <span @click="goTo('photoBroadcast')" style="cursor: pointer;"> 查看更多</span>
                        <img @click="goTo('photoBroadcast')" style="cursor: pointer;" src="../../assets/查看更多箭头.png"
                            alt="">
                    </div>

                </div>
                <div class="zhuneirong">
                    <div class="zhuneirongzuo">
                        <div v-for="(item, index) in list" @click="clickPic(item)"
                            :class="check == item.dictionariesName ? 'checkYes' : 'checkNo'">
                            {{ item.dictionariesName }}
                        </div>
                    </div>
                    <div class="zhuneirongyou">
                        <!-- <img v-if="picLists" :src="picLists" alt=""> -->
                        <el-image class="imgEl" v-if="picLists"  :src="picLists" :preview-src-list="[picLists]">
                        </el-image>
                    </div>
                </div>
            </div>
            <div>
                <div style="display: flex;justify-content: space-between;align-items: center;">
                    <div class="title"> <img src="../../assets/辽洽快讯/标题装饰.png" />
                        <span>高光时刻</span>
                    </div>
                    <div class="lookMoreNoraml">
                        <span @click="goTo('liveVideo')" style="cursor: pointer;"> 查看更多</span>
                        <img @click="goTo('liveVideo')" style="cursor: pointer;" src="../../assets/查看更多箭头.png" alt="">
                    </div>

                </div>
                <div style="display: flex;justify-content: space-between;" >
                    <div class="pic-wall" v-for="(item, index) in videoFirst">
                        <div class="wall-detail">
                            <videoPlayer class="video-player vjs-custom-skin videoSize" ref="videoPlayer"
                                :playsinline="true" :options="playerOptionsList[index]">
                            </videoPlayer>
                        </div>
                        <div class="wall-name">
                            {{ item.spare2 }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { videoPlayer } from 'vue-video-player'
export default {
    components: {
        videoPlayer,

    },
    data() {
        return {
            list: [],
            check: '',
            count: 20,
            playerOptionsList: [

            ],
            videoFirst: [],
            beginNum: 1,
            picLists: '',
            showVideo:false
        }
    },
    mounted() {
        this.getVideoFirst();
        this.getType()
    },
    methods: {
        clickPic(item) {

            this.check = item.dictionariesName;
            this.getPic();
        },
        getType() {
            this.$baseRequest("/sys/dictionaries/firstStage?info=图片", {}, "get").then(res => {
                this.list = res.data;
                this.check = this.list.length ? this.list[0].dictionariesName : '';
                this.getPic();
            })
        },
        getPic() {
            this.picLists = ''
            this.$baseRequest('/maintain/picture/findList', { pictureCategory: this.check, beginNum: 1, endNum: 2 }, 'post').then(res => {
                this.picLists = res.data.length ? res.data[0].picturePhoto : '';

            })
        },
        //跳转 
        goTo(url) {
            this.$emit('jumpUrl', url, this.check)
        },
        // 资讯视频
        getVideoFirst() {
            this.$baseRequest('/maintain/video/findList', { videoCategory: '', beginNum: 1, endNum: 4 }, 'post').then(res => {
                this.videoFirst = res.data;
                this.videoFirst.forEach(item => {
                    this.playerOptionsList.push(
                        {
                            id: '1',
                            playbackRates: [], // 可选的播放速度
                            autoplay: false, // 如果为true,浏览器准备好时开始回放。
                            muted: false, // 默认情况下将会消除任何音频。
                            loop: false, // 是否视频一结束就重新开始。
                            preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                            language: 'zh-CN',
                            aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                            fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                            sources: [{
                                type: "video/mp4", // 类型
                                src: item.videoPhoto
                            }],
                            // poster: require('@/assets/首页切图/10投资合作-大连-宽.png'), // 封面地址
                            notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                            controlBar: {
                                timeDivider: false, // 当前时间和持续时间的分隔符
                                durationDisplay: false, // 显示持续时间
                                remainingTimeDisplay: false, // 是否显示剩余时间功能
                                fullscreenToggle: true // 是否显示全屏按钮
                            }
                        }
                    )
                })
                this.showVideo = true;

            })


        }
    }
}


</script>

<style lang="scss" scoped>
.body {
    padding: 20px 280px;



}

.checkYes {
    padding: 10px 40px;

    font-size: 18px;
    color: #fff;
    margin-bottom: 20px;
    background-image: url(../../assets/高光时刻/选中bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
}

.checkNo {

    padding: 10px 40px;
    background-color: #F3F7FC;
    font-size: 18px;
    color: #000;
    margin-bottom: 20px;
    cursor: pointer;
}

.title {
    img {
        width: 25px;
        height: 25px;
    }

    span {
        line-height: 25px;
        margin-left: 9px;
        font-size: 29px;
        font-family: 'DingTalk_JinBuTi_Regular';
    }
}

.lookMoreNoraml {
    font-size: 16px;
    text-align: center;
    // margin-top: 40px;
    color: #373737;


    img {
        width: 8px;
        vertical-align: inherit;
        margin-left: 5px;
    }

}

.jingcaishunjian {
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.main {
    // padding: 20px 280px;
    // padding-top: 200px;
}

.zhuneirong {
    margin-top: 20px;
    width: 100%;
    border-radius: 10px;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

    .zhuneirongzuo {
        width: 160px;
        padding: 30px;

    }

    .zhuneirongyou {
        width: calc(100% - 160px);

        .imgEl {
            width: 100%;
            height: 500px;
            border-radius: 0px 10px 10px 0px;
            vertical-align: bottom;
        }
    }
}

.pic-wall {
    margin-top: 30px;
    width: 420px;
    margin-right: 20px;
    margin-bottom: 20px;

    .wall-detail {
        width: 100%;
        // height: 175px;

    }

    .wall-name {
        width: calc(100% - 40px);
        text-align: center;
        padding: 15px 20px;
        font-size: 16px;
        color: #000;
        border-radius: 0px 0px 5px 5px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }



}
</style>