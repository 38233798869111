/*
 * @Author: jiangjinchi 1468922694@qq.com
 * @Date: 2023-09-01 10:55:04
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2024-09-14 14:19:42
 * @FilePath: \sizedemo\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './util/rem'
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false
Vue.use(ElementUI);

import _ from 'lodash'
Vue.prototype._ = _;

import VueScrollTo from 'vue-scrollto';

Vue.use(VueScrollTo);

// 挂载组件包
import mountComponents from '@/util/mount-components';
Vue.use(mountComponents);
import { baseRequest,baseRequestGet,baseRequestGetParams } from '@/api/base';
Vue.prototype.$baseRequest = baseRequest;
Vue.prototype.$baseRequestGet = baseRequestGet;
Vue.prototype.$baseRequestGetParams = baseRequestGetParams;
// 引入瀑布流
// import {VueMasonryPlugin} from 'vue-masonry';
// Vue.use(VueMasonryPlugin)

//引入vue懒加载
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
