<!--
 * @Author: jiangjinchi 1468922694@qq.com
 * @Date: 2023-09-07 19:10:06
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2024-07-22 12:00:07
 * @FilePath: \department-of-commerce\PC\src\view\components\openDetails.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<div class="openDetails-wrap">

		<div class="content" v-if="!detailShow">

			<el-row class="rich-text htmlContent">

				<div class="listDay" v-for="(item, index) in infoData.dayList" :key="index + 'jige'">
					<div class="titile-big">{{item.daysName}}</div>
					<div>
						<div class="timeBox" v-for="(i, indx) in item.spotList" :key="indx + 'juti'">
							<div class="dot"></div>
							<div class="timeBox-item" @click="clickDetail(i.id)">
								<div class="timeBox-img">
									<img :src="i.coverImage" alt="">
								</div>
								<div class="timeBox-main">
									<div class="name">{{ i.coverName }}</div>
									<div class="timeBox-main-content">
										{{ i.introductionScenicSpots }}</div>
									<div class="time-details">{{ i.suggestPlaying }}</div>
								</div>
							</div>
							<div class="distance">相聚{{i.distance}}km</div>
						</div>

					</div>
				</div>
			</el-row>
		</div>
		<detail v-if="detailShow" ref="detailRef" :id="smallId" :typeName="typeName" v-on="$listeners"></detail>
	</div>
</template>

<script>

import detail from '../TourIntroduction/detail.vue'

export default {
	props: {
		id: {},
		typeName: {},
		only: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			detailShow: false,
			infoData: {

			},
			smallId: '',
			playerOptionsList: []
		}
	},
	components: {
		detail
	},
	mounted() {
		this.getDetail()
	},
	methods: {
		getDetail() {

			this.$baseRequest('/scenic/line/details?info=' + this.id, {}, 'get').then(res => {
				this.infoData = res.data
			})
		},
		clickDetail(id) {
			this.smallId = id;
			this.$emit("modifyName", '景点详情');
			this.detailShow = true;
		},

	}
}
</script>

<style src="@/style/element-ui-class.css"></style>

<style scoped lang="scss">
.listDay {
	margin-bottom: 40px;
}

.titile-big {
	font-size: 30px;
	color: #000;
	margin-bottom: 10px;
	letter-spacing: 1px;
	margin-bottom: 40px;
}

.timeBox {

	padding-left: 40px;
	padding-bottom: 20px;
	border-left: 2px solid #DEE1E0;

	position: relative;

	.distance {
		font-size: 17px;
		margin-top: 15px;
	}

	.dot {
		position: absolute;
		top: -6px;
		left: -4px;
		width: 10px;
		height: 10px;
		background-color: #DEE1E0;
		border-radius: 50%;
	}

	.timeBox-item {
		display: flex;
		background-color: #F6FAFF;
		padding: 20px;
		justify-content: flex-start;
		border-radius: 10px;
		cursor: pointer;

		.timeBox-main {
			width: calc(100% - 140px);
			margin-left: 20px;

			.timeBox-main-content {
				width: 100%;
				font-size: 17px;
				margin-bottom: 10px;
				color: #6D6D6D;
				overflow: hidden;
				word-wrap: break-word;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;

			}

			.time-details {
				font-size: 17px;
				color: #F8711D;
			}
		}

		.timeBox-img {
			width: 140px;
			height: 140px;

			img {
				width: 140px;
				height: 140px;
				border-radius: 10px;
				margin-right: 20px;
			}
		}

		.name {
			font-size: 30px;
			color: #000;
			margin-bottom: 10px;
			letter-spacing: 1px;
		}

	}
}

.openDetails-wrap {
	

	.content {
		padding: 0px 100px;

		.breadcrumb {
			height: 15px;
			display: flex;
			align-items: center;
			line-height: 15px;
			color: #8389a6;
			margin-bottom: 20px;
			font-size: 19px;

			div {
				display: flex;
				align-items: center;

				img {
					width: 18px;
					height: auto;
					margin-right: 10px;
				}
			}

			i {
				margin: 0 5px;
			}
		}

		.rich-text {
			margin-top: 20px;
			// background-color: #fff;
			padding: 50px 80px;
		}
	}
}

.carousel {
	width: 100%;
	height: 457px;
}

.videoBox {
	margin-bottom: 30px;
}

.cityBox {
	font-size: 28px;
	margin-bottom: 30px;
	color: #000;

}
</style>