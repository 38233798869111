<template>
    <div class="open-wrap">
        <div>
            <div class="content" v-if="!showMoreListDetail">
                <FixedContent :FixedContentList="[typeName]" @ControlsBack="ControlsBack"></FixedContent>
                <el-row :gutter="20">

                    <div v-if="(typeName == '先进典型' && $route.path == '/CulturalExchange') || typeName == '援藏干部事迹'"
                        class="introduct-list" v-for="(item, index) in cityList" :key="index + '1'" >
                        <div>
                            <img v-if="item.journalismPhoto" :src="item.journalismPhoto" alt="">
                            <img v-else src="../../assets/首页切图/占位.png" alt="">
                        </div>
                        <div class="list-right">
                            <div class="list-title">{{ item.journalismTitle }}</div>
                            <div class="list-text">
                                {{ item.spare1 }}
                            </div>
                            <div class="list-time">
                                <div style="display: flex;justify-content: flex-start;">
                                    <div class="btn-fabu" @click="goTo(item.id)">查看详情</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="typeName == '民族活动'">
                        <div class="store">
                            <div class="storeItem" v-for="(item, index) in cityList" :key="index + 'n'"
                            @click="goTo(item.id)">
                                <div class="stoPics">
                                    <el-image lazy class="wall-detail" :src="item.journalismPhoto">
                                        <div slot="placeholder" class="image-slot">
                                            <img src="@/assets/首页切图/缺省.png" alt="">
                                        </div>
                                    </el-image>
                                </div>
                                <div class="content">
                                    <div class="contentName">
                                        <div><img src="../../assets/首页切图/藏族活动.png" alt=""></div>
                                        {{item.journalismTitle}}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="typeName == '民间艺术'">
                        <div class="store">
                            <div class="storeItem" v-for="(item, index) in cityList" :key="index + 'n'"
                            @click="goTo(item.id)">
                                <div class="stoPics">
                                    <el-image lazy class="wall-detail" :src="item.journalismPhoto">
                                        <div slot="placeholder" class="image-slot">
                                            <img src="@/assets/首页切图/缺省.png" alt="">
                                        </div>
                                    </el-image>
                                </div>
                                <div class="content">
                                    <div class="contentName">
                                        {{item.journalismTitle}}
                                    </div>
                                    <div class="adress">
                                       {{item.spare1}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="typeName == '先进典型' && $route.path == '/CadresAppearance'">
                        <div class="indexBox">
                            <div v-for="(item, index) in cityList" :key="index" class="itemIndex" @click="goTo(item.id)">
                                <img :src="item.journalismPhoto" alt="">
                                <div class="itemIndexImg">
                                    <div class="names">
                                        {{ item.journalismTitle }}
                                    </div>
                                    <div class="contents">{{ item.positionName }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-row>
            </div>
            <openDetails v-else @goBack="ControlsBack" :FixedContentList="[typeName, '详情']" :id="id" ref="openDetails"></openDetails>
        </div>

    </div>
</template>

<script>
import openDetails from './openDetails.vue'
export default {
    components: {
        openDetails
    },
    props: {
        typeName: {}
    },
    data() {
        return {
            FixedContentList: [],
            showMoreListDetail: false,
            cityName: "",
            cityList: [],
            id: ""
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        getList() {
            this.$baseRequest('/maintain/journalism/findList', {
				journalismCategory:  this.$route.name,
				journalismSecondaryClassification: this.typeName,
			}, 'post').then(res => {
				this.cityList = res.data;

			});
        },
        ControlsBack(e) {
            switch (e) {
                case -1:
                    this.$emit('goBack');
                    break;
                case 0:
                    this.showMoreListDetail = false;
                    break;
                default:
                    break;
            }
        },
        goTo(e) {
            this.id = e;
            this.showMoreListDetail = !this.detailsFlag;
            this.$nextTick(() => {
                document.documentElement.scrollTop = 200;
                this.$refs["openDetails"].details();
            })
        },

    }
}
</script>


<style scoped lang="scss">
.indexBox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 40px;


}

.itemIndex {
    width: 320px;
    position: relative;
    margin-bottom: 20px;
    cursor:pointer;

    img {
        width: 100%;
        border-radius: 4px;
        height: 350px;
    }

    .itemIndexImg {
        position: absolute;
        bottom: 0px;
        left: 0;
        font-size: 20px;
        padding: 10px;
        width: 300px;
        color: #fff;

        background: rgba(29, 57, 173, 0.9);

        .contents {
            font-size: 18px;
            margin-top: 5px;
        }
    }
}



.open-wrap {
    

    .content {
        padding: 60px 310px;
    }
}

.btn-fabu {
    padding: 7px 15px;
    text-align: left;
    background-color: #F86C14;
    border-radius: 4px;
    color: #fff;
    font-size: 18px;
    margin-top: 15px;
    cursor: pointer;
}

.store {
    margin-top: 40px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .storeItem {
        flex-shrink: 0;
        width: 23%;
        background-color: #fff;
        border-radius: 10px;
        margin-right: 20px;
        cursor: pointer;

        .stoPics {
            width: 100%;
            height: 280px;
            overflow: hidden;
            margin-bottom: 0px;
            border-radius: 10px 10px 0px 0px;

            .wall-detail {
                width: 100%;
                height: 280px;
            }

            .image-slot {
                img {
                    width: 100%;
                    height: 280px;
                }

            }
        }

        img {
            display: block;
            width: 100%;
        }

        .content {
            background-color: #fff;
            padding: 30px 20px;
            border-radius: 0px 0px 10px 10px;
        }

        .contentName {
            font-size: 20px;
            font-weight: 600;
            color: #000;
            margin-bottom: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            img {
                width: 80px;
            }
        }

        .adress {
            color: #6C6C6C;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.store :last-child {
    margin-right: 0px;
}

.introduct-list {
    display: flex;
    margin-top: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid #f0f1f7;
    cursor: pointer;

    img {
        width: 280px;
        height: 180px;

    }

    .list-right {
        display: flex;
        flex-direction: column;
        padding-left: 25px;

        .list-title {
            font-size: 23px;
            font-weight: 600;
            line-height: 36px;

        }

        .list-text {
            margin-top: 10px;
            font-size: 15px;
            color: #8b8b8b;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .list-time {
            margin-top: 10px;
            font-size: 16px;
            color: #8b8b8b;
        }
    }
}
</style>