<!--
 * @Author: jiangjinchi 1468922694@qq.com
 * @Date: 2023-09-01 10:55:04
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2024-09-16 15:20:00
 * @FilePath: \sizedemo\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="login-page">
        <div class="login-form">
            <form class="form">
                <div class="form-title">
                    欢迎登录
                </div>
                <div class="inputForm">
                    <img src="@/assets/内容页切图/线上洽谈-登录-用户名.png" alt="">
                    <input type="text" v-model="form.enterpriseMobileNumber" class="input" placeholder="请输入手机号">
                </div>

                <div style="display: flex;align-items: center;position: relative;">
                    <div class="inputForm" style="width: 100%;">
                        <img src="@/assets/内容页切图/线上洽谈-登录-密码.png" alt="">
                        <input v-model="form.spare1" type="text" class="input" placeholder="请输入密码">
                    </div>

                </div>

                <div class="btn">
                    <img @click="backPage" src="@/assets/其他/供采对接/登录.png" alt="">
                </div>
                <div class="signin">
                    <span>没有账号?</span><span class="goSign" @click="clickSign">去注册</span>
                </div>
            </form>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            sendInfo: '发送',
            checked: '',
            show: true,
            count: "",
            timer: null,
            form: {
                enterpriseMobileNumber: '',
                spare1: ''
            }
        }
    },
    mounted() {

    },

    methods: {

        clickSign() {
            this.$router.replace({ path: '/sign' })
        },
        backPage() {
            if (!this.form.enterpriseMobileNumber) {
                this.$message.warning('请输入手机号')
                return;
            };
            if (!this.form.spare1) {
                this.$message.warning('请输入密码')
                return;
            };
            this.$baseRequest('/not/onlineEnterprise/enterpriseSign', this.form, 'post').then(res => {
                localStorage.setItem('loginInfo', JSON.stringify(res.data));
                this.$router.replace({ path: '/' })
            });

        },

    }

}
</script>
<style src="@/style/elementSingle.css"></style>
<style scoped lang="scss">
.goSign {
    color: #EA0E1E;
    cursor: pointer;
}

.remember {
    text-align: right;
}

.login-page {
    width: 100vw;
    height: 100vh;
    background-image: url(@/assets/其他/供采对接/登录bg.png);
    background-size: 100% 100%;
    position: relative;
}

.signin {
    text-align: center;
    margin-top: 10px;
    font-size: 17px;


}

.btn {
    text-align: center;
    margin-top: 30px;

    img {
        width: 310px;
        height: 50px;
        cursor: pointer;
    }
}

.login-form {
    position: absolute;
    right: 260px;
    top: 220px;
}

.form {

    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 30px;
    width: 400px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    .form-title {
        text-align: center;
        font-size: 28px;
        margin-bottom: 40px;
    }

}

::placeholder {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.inputForm {
    // border: 1.5px solid #ecedec;
    border-radius: 5px;
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    transition: 0.2s ease-in-out;
    background-color: #F5F6F8;
    margin-bottom: 20px;

    img {
        width: 17px;
        height: 20px;
        margin-left: 10px;
    }

}

.input {
    margin-left: 15px;
    border-radius: 10px;
    border: none;
    width: 85%;
    height: 100%;
    background-color: #F5F6F8;
    font-size: 16px;

    // box-shadow: 12.5px 12.5px 10px rgba(0, 0, 0, 0.015), 100px 100px 80px rgba(0, 0, 0, 0.03);

}

.input:focus {
    outline: none;
}

.sendBtn {
    background-color: #F86C14;
    border-color: #F86C14;
    position: absolute;
    right: 0px;
    top: 10%;
    font-size: 15px
}

.inputForm:focus-within {
    // border: 1.5px solid #2d79f3;
}
</style>