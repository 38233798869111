<template>
	<div class="open-wrap">
		<div >
			<el-row>
				<img class="banner-unify" src="@/assets/内容页切图/顶部图-援藏工作综述.png" alt="">
			</el-row>
			<div class="content" v-if="!detailsFlag">

				<el-row :gutter="20">

					<div class="introduct-list" v-for="(item, index) in cityList" :key="index + '1'" @click="goTo(item.id)">
						<div>
							<img v-if="item.journalismPhoto" :src="item.journalismPhoto" alt="">
							<img v-else src="../assets/首页切图/占位.png" alt="">
						</div>
						<div class="list-right">
							<div class="list-title">{{ item.journalismTitle }}</div>
							<div class="list-text">
								{{ item.spare1 }}
							</div>
							<div class="list-time">
								<div style="display: flex;justify-content: flex-start;">
									<div class="btn-fabu">查看详情</div>
								</div>
							</div>
						</div>
					</div>
				</el-row>
			</div>
			<openDetails ref="openDetails" @goBack="goBack" v-else :id="id" ></openDetails>
		</div>
		
	</div>
</template>

<script>
import openDetails from './components/openDetails.vue'
export default {
	components: {
		openDetails
	},
	data() {
		return {
			detailsFlag: false,
			cityName: "",
			cityList: [],
			id: ""
		}
	},
	mounted() {
		this.findList();
	},
	methods: {
		findList() {
			this.$baseRequest('/maintain/journalism/findList', {
				journalismCategory: "工作叙述",
			}, 'post').then(res => {
				this.cityList = res.data
				
			})
		},
		goTo(e) {
			this.id = e;
			this.detailsFlag = true;
			this.$nextTick(() => {
				document.documentElement.scrollTop = 200;
				this.$refs["openDetails"].details();
			})
		},
		goBack() {
			this.detailsFlag = false;
		}
	}
}
</script>

<style src="../style/element-ui-class.css"></style>
<style></style>
<style scoped lang="scss">
.indexBox {
	display: flex;
	justify-content: space-between;

}

.itemIndex {
	width: 32%;
	position: relative;

	img {
		width: 100%;
		border-radius: 10px;
		height: 350px;
	}

	.itemIndexImg {
		position: absolute;
		bottom: 20px;
		left: 0;
		font-size: 20px;
		padding: 10px;
		color: #fff;
	}
}

.dingbu {
	width: 100%;
	height: auto;
}

.introduct-list {
	display: flex;
	margin-top: 40px;
	padding-bottom: 40px;
	border-bottom: 1px solid #f0f1f7;
	cursor: pointer;

	// align-items: center;
	img {
		width: 280px;
		height: 180px;

	}

	.list-right {
		display: flex;
		flex-direction: column;
		padding-left: 25px;

		.list-title {
			font-size: 23px;
			font-weight: 600;
			line-height: 36px;

		}

		.list-text {
			margin-top: 10px;
			font-size: 15px;
			color: #8b8b8b;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		.list-time {
			margin-top: 10px;
			font-size: 16px;
			color: #8b8b8b;
		}
	}
}

.open-wrap {
	

	.content {
		padding: 60px 310px;

		.el-input {
			width: 21%;
		}

		.el-button {
			margin-left: 10px;
			width: 7%;
			background-color: #1e72ec;
		}

		.list {
			padding: 20px;
			background-color: #fff;
			margin-top: 20px;

			.list-left {
				img {
					border-radius: 5px;
					width: 100%;
					height: 150px;
				}
			}

			.list-right {
				padding: 0 15px;
				display: flex;
				flex-direction: column;
				height: 150px;

				.list-title {
					font-size: 20px;
					font-weight: bold;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.list-content {
					margin-top: 10px;
					font-size: 13px;
					color: #666666;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}

				.list-el-button {
					margin-top: auto;
					margin-left: auto;
					width: 110px;
				}
			}
		}
	}
}

.btn-fabu {
	padding: 7px 15px;
	text-align: left;
	background-color: #F86C14;
	border-radius: 4px;
	color: #fff;
	font-size: 18px;
	margin-top: 15px;
	cursor: pointer;
}
</style>