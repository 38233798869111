<!--
 * @Author: jiangjinchi 1468922694@qq.com
 * @Date: 2024-09-13 17:03:54
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2024-09-17 18:32:59
 * @FilePath: \pc\src\view\previousAchievements\detail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="body">
        <div class="FixedContentStyle">
            <FixedContent :titleJumpList="titleJumpList" @jumpUrl="jumpUrl"></FixedContent>
        </div>
        <div class="content">
            <div class="title">
                <img src="../../assets/标题装饰.png" alt="">
                <span>{{ details.journalismTitle }}</span>
                <img src="../../assets/标题装饰.png" alt="">
            </div>
        
            <div class="ql-container ql-snow" style="border: none;">
                <div class="ql-editor" @click="showImage($event)" v-html="details.journalismContent"></div>
            </div>
        </div>

        <elImageViewer v-if="dialogVisible" :on-close="closeImage" :url-list="[url]" />
    </div>
</template>

<script>
export default {
    props: ['query'],
    data() {
        return {
            url: '',
            dialogVisible: false,
            titleJumpList: [
                {
                    url: "previousAchievements",
                    name: "历届成就"

                }, {
                    url: "",
                    name: "详情"

                }
            ],
            details: {
                journalismTitle: '',
                journalismContent: ''
            }
        }
    },
    mounted() {

        this.getDetail();
    },
    methods: {
        showImage(e) {
            if (e.target.tagName == 'IMG') {

                this.url = e.target.src
                this.dialogVisible = true
            }
        },
        closeImage() {
            this.dialogVisible = false
            this.url = '';
        },
        goTo(url, query) {
            this.$emit('jumpUrl', url, query);
        },
        jumpUrl(e) {
            this.goTo(e)
        },
        getDetail() {
            this.$baseRequest("/not/previous/achievements/journalism/details?info=" + this.query, {}, "get").then(res => {
                this.details = res.data;
                if (this.details.journalismContent) {
                    this.details.journalismContent = this.details.journalismContent.replace(/<iframe/g, "<video controls style='width:100%;height:auto;'")
                }

            })
        }
    }


}
</script>

<style lang="scss" scoped>
.body {
    padding: 20px 280px;
    // background-color: #f6f7f9;


}


.content {
    margin-top: 20px;
    padding: 50px 150px;
    background-color: #fff;
    border-radius: 5px;
}

.title {
    text-align: center;
    font-family: 'SourceHanSansSC-Regular';
    font-size: 26px;
    color: #000;
    margin-bottom: 30px;

    img {
        width: 20px;
        height: auto;
    }

    span {
        margin: 0px 10px;
    }
}
</style>