<!--
 * @Author: jiangjinchi 1468922694@qq.com
 * @Date: 2024-09-06 09:04:34
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2024-09-15 14:09:54
 * @FilePath: \pc\src\components\CityUtils.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<div>
		<div class="list" :class="cityFage == item ? 'click' : ''" v-for="item in cityList" :key="item"
			@click="cityClick(item)">
			<div class="title">{{ item }}</div>
			<div class="img">
				<img src="@/assets/内容页切图/城市导航-箭头-白.png" v-if="cityFage == item" />
				<img src="@/assets/内容页切图/城市导航-箭头-灰.png" v-else />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			cityList: ['全部', '沈阳市', '大连市', '鞍山市', '抚顺市', '本溪市', '丹东市', '锦州市', '营口市', '阜新市', '辽阳市', '盘锦市', '铁岭市', '朝阳市',
				'葫芦岛市', '沈抚示范区'
			],
			cityFage: "全部",
		}
	},
	mounted() { },
	methods: {
		cityClick(e) {
			this.cityFage = e;
			this.$emit("cityClick", e);
		}
	}
}
</script>


<style src="@/style/element-ui-class.css"></style>

<style scoped lang="scss">
.list {
	height: 60px;
	border-radius: 8px;
	padding: 0 25px;
	display: flex;
	cursor: pointer;

	.title {
		line-height: 60px;
		font-size: 18px;
		display: inline-block;
	}

	.img {
		float: right;
		margin: auto 0px auto auto;

		img {
			width: 10px;
		}
	}
}

.click {
	background-color: #EA0E1E;
	color: #fff;
}
</style>