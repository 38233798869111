<template>
	<div class="open-wrap">
		<div>
			<el-row>
				<img class="banner-unify" src="@/assets/内容页切图/顶部图-那曲文旅.png" alt="">
			</el-row>
			<div>
				<div class="content1">
					<FixedContent v-if="showMoreListDetail || showDetail" :FixedContentList="FixedContentList"
						@ControlsBack="ControlsBack"></FixedContent>
				</div>
				<div class="content" v-if="!showMoreList && !showMoreListDetail && !showDetail">

					<el-row :gutter="20">
						<div style="display: flex;justify-content: space-between;align-items: center;">
							<div class="title-only">路线规划</div>
							<div class="title-more" @click="seeMore('路线规划')">查看更多<img class="img2"
									src="../assets/内容页切图/查看更多箭头.png" alt=""></div>
						</div>
						<div class="indexBox">
							<div v-for="(item, index) in LineSpot" :key="index" class="itemIndex"
								@click="clickStore(item.id, '路线规划')">
								<img :src="item.lineImage" alt="">
								<div class="itemIndexImg">
									<img src="../assets/首页切图/旅游介绍-去看看.png" alt="">

								</div>
								<div class="introduction">
									<div class="introduction-bigname">
									 {{item.lineName}}
									</div>
									<div class="introduction-smallname">
										{{item.lineDays}}天·{{item.numberAttractions}}个观光景点
									</div>
								</div>
							</div>
						</div>

						<div style="display: flex;justify-content: space-between;align-items: center;">
							<div class="title-only">旅游介绍</div>
							<div class="title-more" @click="seeMore('旅游介绍')">查看更多<img class="img2"
									src="../assets/内容页切图/查看更多箭头.png" alt=""></div>
						</div>

						<div class="store">
							<div class="storeItem" v-for="(item, index) in ScenicSpot" :key="index + 'n'"
								@click="clickDetail(item.id)">
								<div class="stoPics">
									<el-image lazy class="wall-detail" :src="item.coverImage">
										<div slot="placeholder" class="image-slot">
											<img src="@/assets/首页切图/缺省.png" alt="">
										</div>
									</el-image>
								</div>
								<div class="content">
									<div class="contentName">
										{{item.coverName}}
									</div>
									<div class="adress">
										<div class="adress-sign">
											<img src="../assets/首页切图/旅游景点-标签.png" alt="">
											<div class="ranking">
												旅游推荐NO.{{item.recommendedRanking}}
											</div>
										</div>
										<div class="address-name">
											{{item.introductionScenicSpots}}
										</div>
									</div>
								</div>
							</div>
						</div>
					</el-row>
				</div>
				<moreList v-if="showMoreList" :typeName="typeName" @goBack="goBack"></moreList>
				<div style="padding: 0px 200px;">
					<moreListDetail ref="moreListDetailRef" v-if="showMoreListDetail" :typeName="typeName"
						@needShowDetail="needShowDetail"  :id="id" @modifyName="modifyName">
					</moreListDetail>
					<detail v-if="showDetail" ref="detailRef" :id="id" 	@needShowDetail="needDetail" :typeName="typeName" @goBack="goBackDetail"></detail>
				</div>

			</div>

		</div>

	</div>
</template>

<script>
import moreList from './TourIntroduction/moreList.vue';
import moreListDetail from './TourIntroduction/moreListDetail.vue';
import detail from './TourIntroduction/detail.vue';
export default {
	components: {
		moreList, moreListDetail, detail
	},
	data() {
		return {
			showDetail: false,
			showMoreList: false,
			detailsFlag: false,
			showMoreListDetail: false,
			cityName: "",
			cityList: [],
			cityId: "",
			typeName: '',
			FixedContentList: [],
			ScenicSpot: [],
			LineSpot: [],
			id: ''
		}
	},
	mounted() {
		this.findList();
	},
	methods: {
		ControlsBack(e) {
	
			switch (e) {
				case -1:
					this.showMoreListDetail = false;
					this.showDetail = false;
					break;
				case 0:
					this.$set(this, "FixedContentList", ['详情']);
					if(this.showMoreListDetail) {
						this.$refs.moreListDetailRef.detailShow = false;
					}else {
						this.$refs.detailRef.needShowDetail = false;
					}
					break;
				case 1:
					this.$set(this, "FixedContentList", [this.typeName, '景点详情']);
					this.$refs.moreListDetailRef.detailShow = false;
					break;
				default:
					break;
			}
		},
		modifyName(e) {
			this.$set(this, "FixedContentList", [this.typeName, '景点详情']);
		},
		needShowDetail(e) {
			this.$set(this, "FixedContentList", [this.typeName, '景点详情', '详情']);
		},
		needDetail(e) {
			this.$set(this, "FixedContentList", ['景点详情', '详情']);
		},
		goBackDetail(e) {
			if (e == 'one') {
				this.typeName = '旅游介绍'
				this.showDetail = false;
				this.showMoreList = true;
			} else {
				this.showMoreList = false;
				this.showMoreListDetail = false;
				this.showDetail = false;
			}
		},
		clickDetail(e) {
			this.id = e;
			this.typeName = '旅游介绍'
			this.showDetail = true;
			this.$set(this, "FixedContentList", ['景点详情']);
			this.$nextTick(() => {
				document.documentElement.scrollTop = 200;

			})
		},
		clickStore(e, type) {
			this.typeName = type;
			this.id = e;
			this.showMoreListDetail = true;
			this.$set(this, "FixedContentList", ['详情']);
			this.$nextTick(() => {
				document.documentElement.scrollTop = 200;

			})
		},
		seeMore(e) {
			this.typeName = e;
			this.showMoreList = true;
			this.showMoreListDetail = false;
			this.$nextTick(() => {
				document.documentElement.scrollTop = 200;

			})
		},
		findList() {
			// 景点
			this.$baseRequest('/scenic/spot/findList', {
				beginNum: 1,
				endNum: 7
			}, 'post').then(res => {
				this.ScenicSpot = res.data;
			});
			// 路线
			this.$baseRequest('/scenic/line/findList', {
				beginNum: 1,
				endNum: 5
			}, 'post').then(res => {
				this.LineSpot = res.data;
			});
		},

		goBack() {
			this.showMoreList = false;
			this.showMoreListDetail = false;

		}
	}
}
</script>

<style src="../style/element-ui-class.css"></style>
<style></style>
<style scoped lang="scss">
.content1 {
	padding: 30px 310px;
}

.indexBox {
	display: flex;
	justify-content: flex-start;
	margin-bottom: 20px;


}

.itemIndex {
	width: 420px;
	margin-right:30px;
	position: relative;

	img {
		width: 100%;
		border-radius: 10px;
		height: 420px;
	}

	.itemIndexImg {
		position: absolute;
		bottom: 30px;
		left: 20px;

		img {
			width: 150px;
			height: auto;
			cursor: pointer;
		}
	}

	.introduction {
		position: absolute;
		top: 30px;
		left: 20px;
		color: #fff;

		.introduction-bigname {
			font-size: 25px;
			margin-bottom: 12px;
		}

		.introduction-smallname {
			font-size: 17px;
		}
	}
}

.title-only {
	font-size: 28px;
	margin: 20px 0px;
}

.title-more {
	font-size: 16px;
	color: #A3A7BC;
	cursor: pointer;

	img {
		width: 11px;
		vertical-align: inherit;
	}
}


.dingbu {
	width: 100%;
	height: auto;
}

.introduct-list {
	display: flex;
	margin-top: 20px;
	padding-bottom: 40px;
	border-bottom: 1px solid #f0f1f7;
	cursor: pointer;

	// align-items: center;
	img {
		width: 280px;
		height: 180px;

	}

	.list-right {
		display: flex;
		flex-direction: column;
		padding-left: 25px;

		.list-title {
			font-size: 23px;
			font-weight: 600;
			line-height: 36px;

		}

		.list-text {
			margin-top: 10px;
			font-size: 15px;
			color: #8b8b8b;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		.list-time {
			margin-top: 10px;
			font-size: 16px;
			color: #8b8b8b;
		}
	}
}

.open-wrap {
	

	.content {
		padding: 30px 310px;

		.el-input {
			width: 21%;
		}

		.el-button {
			margin-left: 10px;
			width: 7%;
			background-color: #1e72ec;
		}

		.list {
			padding: 20px;
			background-color: #fff;
			margin-top: 20px;

			.list-left {
				img {
					border-radius: 5px;
					width: 100%;
					height: 150px;
				}
			}

			.list-right {
				padding: 0 15px;
				display: flex;
				flex-direction: column;
				height: 150px;

				.list-title {
					font-size: 20px;
					font-weight: bold;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.list-content {
					margin-top: 10px;
					font-size: 13px;
					color: #666666;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}

				.list-el-button {
					margin-top: auto;
					margin-left: auto;
					width: 110px;
				}
			}
		}
	}
}

.btn-fabu {
	padding: 7px 15px;
	text-align: left;
	background-color: #F86C14;
	border-radius: 4px;
	color: #fff;
	font-size: 18px;
	margin-top: 15px;
	cursor: pointer;
}

.store {
	margin-top: 10px;
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	gap: 20px;

	.storeItem {
		width: 32%;
		background-color: #fff;
		border-radius: 10px;

		cursor: pointer;

		.stoPics {
			width: 100%;
			height: 280px;
			overflow: hidden;
			margin-bottom: 0px;
			border-radius: 10px 10px 0px 0px;

			.wall-detail {
				width: 100%;
				height: 280px;
			}

			.image-slot {
				img {
					width: 100%;
					height: 280px;
				}

			}
		}

		img {
			display: block;
			width: 100%;
		}

		.content {
			background-color: #fff;
			padding: 30px 20px;
			border-radius: 0px 0px 10px 10px;
		}

		.contentName {
			font-size: 26px;
			font-weight: 600;
			color: #000;
			margin-bottom: 10px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			img {
				width: 80px;
			}
		}

		.adress {
			display: flex;
			align-items: center;
			color: #6C6C6C;

			font-size: 17px;

			.adress-sign {
				position: relative;

				img {
					width: 120px;
					height: auto;
				}

				.ranking {
					position: absolute;
					left: 4px;
					top: 1px;
					font-size: 16px;
					color: #8F694D;
				}
			}

			.address-name {
				width: calc(100% - 120px);
				margin-left: 10px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
	}
}

.store :last-child {
	margin-right: 0px;
}
</style>