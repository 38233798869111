<template>
	<div class="open-wrap">
		<div v-if="!detailsFlag">
			<el-row>
				<img class="banner-unify" src="@/assets/内容页切图/顶部图-走进那曲.png" alt="">
			</el-row>
			<div class="content">
				<el-row>
					<el-input v-model="journalismTitle" placeholder="请输入城市名称" class="el-input" clearable></el-input>
					<el-button type="primary" class="el-button" style="background-color: #F86C14;border-color: #F86C14;" @click="findList">查询</el-button>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="12" v-for="item in cityList" :key="item.id">
						<el-row class="list">
							<el-col :span="9" class="list-left">
								<img v-if="item.journalismPhoto" :src="item.journalismPhoto" />
								<img v-else src="@/assets/首页切图/占位.png" />

							</el-col>
							<el-col :span="15" class="list-right">
								<div class="list-title">{{ item.journalismTitle }}</div>
								<div class="list-content">{{ item.spare1 }}</div>
								<el-button type="primary" style="background-color: #F86C14;border-color: #F86C14;" class="list-el-button" @click="goTo(item.id)">查看详情</el-button>
							</el-col>
						</el-row>
					</el-col>
				</el-row>
			</div>
		</div>
		<openDetails ref="openDetails" @goBack="goBack" v-else :id="id" :typeName="'走进那曲'"></openDetails>
	</div>
</template>

<script>
import openDetails from './components/openDetails.vue'
export default {
	components: {
		openDetails
	},
	data() {
		return {
			detailsFlag: false,
			journalismTitle: "",
			cityList: [],
			id: ""
		}
	},
	mounted() {
		this.findList();
	},
	methods: {
		findList() {
			this.$baseRequest('/maintain/journalism/findList', {
				journalismTitle: this.journalismTitle,
				journalismCategory: '走进那曲'
			}, 'post').then(res => {
				this.cityList = res.data;
				
			})
		},
		goTo(e) {
			this.id = e;
			this.detailsFlag = !this.detailsFlag;
			this.$nextTick(() => {
				document.documentElement.scrollTop = 200;
				this.$refs["openDetails"].details();
			})
		},
		goBack() {
			this.detailsFlag = !this.detailsFlag;
		}
	}
}
</script>

<style src="../style/element-ui-class.css"></style>
<style></style>
<style scoped lang="scss">
.dingbu {
	width: 100%;
	height: auto;
}

.open-wrap {
	

	.content {
		padding: 60px 310px;

		.el-input {
			width: 21%;
		}

		.el-button {
			margin-left: 10px;
			width: 7%;
			background-color: #1e72ec;
		}

		.list {
			padding: 20px;
			background-color: #fff;
			margin-top: 20px;

			.list-left {
				img {
					border-radius: 5px;
					width: 100%;
					height: 150px;
				}
			}

			.list-right {
				padding: 0 15px;
				display: flex;
				flex-direction: column;
				height: 150px;

				.list-title {
					font-size: 20px;
					font-weight: bold;
					white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
				}

				.list-content {
					margin-top: 10px;
					font-size: 13px;
					color: #666666;
					overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
				}

				.list-el-button {
					margin-top: auto;
					margin-left: auto;
					width: 110px;
				}
			}
		}
	}
}
</style>