<template>
    <div class="open-wrap">
        <div>
            <div class="content">
                <el-row class="breadcrumb">
                    <div @click="goTo"><img src="@/assets/内容页切图/面包屑-首页.png" /> {{ $route.name }}</div><i
                        class="el-icon-arrow-right"></i><span style="cursor: pointer;" @click="clickBack">{{ typeName
                        }}</span><i class="el-icon-arrow-right" v-if="showMoreListDetail || showDetail"></i><span
                        v-if="showMoreListDetail || showDetail">详情</span>
                </el-row>
                <el-row :gutter="20">
                    <div v-if="!showMoreListDetail && !showDetail">

                        <div class="store" v-if="typeName == '非遗文创精粹'">
                            <div class="storeItem" v-for="(item, index) in list" :key="index + 'n'"
                                @click="clickStore(item.id)">
                                <div class="stoPics">
                                    <el-image lazy class="wall-detail" :src="item.picture">
                                        <div slot="placeholder" class="image-slot">
                                            <img src="@/assets/首页切图/缺省.png" alt="">
                                        </div>
                                    </el-image>
                                </div>
                                <div class="content">
                                    <div class="contentName">
                                        {{ item.productName }}
                                    </div>
                                    <div class="adress">

                                        <div class="address-name">
                                            {{ item.productIntroduction }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="store" v-if="typeName == '西藏特产美物美食'">
                            <div class="storeItem2" v-for="(item, index) in list" :key="index + 'n'"
                                @click="clickStore(item.id)">
                                <div class="stoPics">
                                    <el-image lazy class="wall-detail" :src="item.picture">
                                        <div slot="placeholder" class="image-slot">
                                            <img src="@/assets/首页切图/缺省.png" alt="">
                                        </div>
                                    </el-image>
                                </div>
                                <div class="content">
                                    <div class="contentName">
                                        {{ item.productName }}
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div class="groggery" v-if="typeName == '舒适酒店推荐'">
                            <div class="groggery-item" v-for="(item, index) in list" :key="index"
                                @click="clickhotal(item.id)">
                                <div>
                               
                                    <img :src="item.picture" alt="">
                                </div>
                                <div class="groggery-right">
                                    <div>
                                        <div class="groggery-right-name">{{ item.hotelName }}</div>
                                        <!-- <div class="groggery-right-address"><img src="../../assets//首页切图/酒店腿甲位置.png"
                                                alt="">{{ item.address }}</div> -->
                                        <div class="groggery-right-info">{{ item.position }}</div>
                                    </div>
                                    <div class="groggery-right-price"><span style="color: #F86C14;">￥</span><span
                                            class="price">{{ item.price }}</span><span style="color: #7A7A7A;">起</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <detailStyleOne v-if="showMoreListDetail" :idDetails="id" :typeName="typeName"></detailStyleOne>
                    <detailsPage v-if="showDetail" :id="id"></detailsPage>
                </el-row>
            </div>
        </div>


    </div>
</template>

<script>
import detailStyleOne from '../../components/detailStyleOne.vue';
import detailsPage from './details.vue';

export default {
    components: {
        detailStyleOne, detailsPage
    },
    props: {
        typeName: {}
    },
    data() {
        return {
            showDetail: false,
            showMoreListDetail: false,
            cityName: "",
            list: [],
            id: ""
        }
    },
    mounted() {
        this.findList()
    },
    methods: {
        findList() {
            if (this.typeName == '舒适酒店推荐') {
                this.$baseRequest('/not/hotel/findList', {}, 'post').then(res => {
                    this.list = res.data;
                })
            } else if (this.typeName == '非遗文创精粹') {
                this.$baseRequest('/not/goods/boutique/findList', {
                    province: '',
                    productType: '非遗产品'
                }, 'POST').then(res => {
                    this.list = res.data;
                })
            } else {
                this.$baseRequest('/not/goods/boutique/findList', {
                    province: '',
                    productType: '特产美食'
                }, 'POST').then(res => {
                    this.list = res.data;
                })
            }

        },
        goTo(e) {
            this.$emit("goBack");

        },
        goBack() {
            this.showMoreListDetail = false;
        },
        clickBack(e) {
            this.showMoreListDetail = false;
            this.showDetail = false;
        },
        clickhotal(e) {
            this.id = e;
            this.showDetail = true;
            this.$nextTick(() => {
                document.documentElement.scrollTop = 200;
            })
        },
        clickStore(e) {
            this.id = e;
            this.showMoreListDetail = true;
            this.$nextTick(() => {
                document.documentElement.scrollTop = 200;
            })
        }
    }
}
</script>


<style scoped lang="scss">
.htm-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.htm-item {
    width: 24%;

    img {
        width: 100%;
        height: auto;
        border-radius: 5px;
    }
}

.htm {
    padding: 20px 200px;
}

.htm-box-img {
    img {
        width: 100%;
        height: auto;
    }
}

.htm-box-name {
    font-size: 30px;
    color: #000;
    margin-top: 30px
}

.indexBox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 40px;


}

.itemIndex {
    width: 650px;
    position: relative;
    margin-bottom: 25px;

    img {
        width: 100%;
        border-radius: 10px;
        height: 250px;
    }

    .itemIndexImg {
        position: absolute;
        bottom: 90px;
        right: 20px;

        img {
            width: 150px;
            height: auto;
            cursor: pointer;
        }
    }

    .introduction {
        position: absolute;
        top: 100px;
        left: 40px;
        color: #fff;

        .introduction-bigname {
            font-size: 25px;
            margin-bottom: 12px;
        }

        .introduction-smallname {
            font-size: 17px;
        }
    }
}

.breadcrumb {
    height: 15px;
    display: flex;
    align-items: center;
    line-height: 15px;
    color: #8389a6;
    margin-bottom: 20px;
    span {
        font-size: 19px;
    }
    div {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 19px;
        img {
            width: 18px;
            height: auto;
            margin-right: 10px;
        }
    }

    i {
        margin: 0 5px;
    }
}

.open-wrap {
    

    .content {
        padding: 30px 310px;
    }
}

.groggery {
    margin-top: 40px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;

    .groggery-item {
        width: 29%;
        display: flex;
        padding: 20px;
        background-color: #fff;
    }

    img {
        width: 180px;
        height: 220px;
        border-radius: 5px;
    }

    .groggery-right {
        width: calc(100% - 200px);
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;

        .groggery-right-name {
            font-size: 26px;
            color: #000;
        }

        .groggery-right-address {
            font-size: 16px;
            color: #6C6C6C;
            margin: 10px 0px;

            img {
                width: 10px;
                height: auto;
                vertical-align: middle
            }
        }

        .groggery-right-info {
            font-size: 18px;
            color: #F86C14;
            margin-top: 20px;
        }

        .groggery-right-price {
            font-size: 14px;
            text-align: right;

            .price {
                font-size: 30px;
                color: #F86C14;
            }
        }
    }
}

.store {
    // height: 700px;
    // overflow-y: scroll;
    margin-top: 40px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;

    .storeItem {
        width: 32%;
        background-color: #fff;
        border-radius: 10px;

        cursor: pointer;

        .stoPics {
            width: 100%;
            height: 280px;
            overflow: hidden;
            margin-bottom: 0px;
            border-radius: 10px 10px 0px 0px;

            .wall-detail {
                width: 100%;
                height: 280px;
            }

            .image-slot {
                img {
                    width: 100%;
                    height: 280px;
                }

            }
        }

        img {
            display: block;
            width: 100%;
        }

        .content {
            background-color: #fff;
            padding: 30px 20px;
            border-radius: 0px 0px 10px 10px;
        }

        .contentName {
            font-size: 26px;
            font-weight: 600;
            color: #000;
            margin-bottom: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            img {
                width: 80px;
            }
        }

        .adress {
            display: flex;
            align-items: center;
            color: #6C6C6C;

            font-size: 17px;

            .adress-sign {
                position: relative;

                img {
                    width: 120px;
                    height: auto;
                }

                .ranking {
                    position: absolute;
                    left: 4px;
                    top: 1px;
                    font-size: 16px;
                    color: #8F694D;
                }
            }

            .address-name {
                width: calc(100% - 120px);

                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }

    .storeItem2 {
        width: 18%;
        background-color: #fff;
        border-radius: 10px;

        cursor: pointer;

        .stoPics {
            width: 100%;
            height: 280px;
            overflow: hidden;
            margin-bottom: 0px;
            border-radius: 10px 10px 0px 0px;

            .wall-detail {
                width: 100%;
                height: 280px;
            }

            .image-slot {
                img {
                    width: 100%;
                    height: 280px;
                }

            }
        }

        img {
            display: block;
            width: 100%;
        }

        .content {
            background-color: #fff;
            padding: 30px 20px;
            border-radius: 0px 0px 10px 10px;
        }

        .contentName {
            font-size: 26px;
            font-weight: 600;
            color: #000;
            margin-bottom: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            img {
                width: 80px;
            }
        }
    }
}

.store :last-child {
    margin-right: 0px;
}
</style>