<!--
 * @Author: jiangjinchi 1468922694@qq.com
 * @Date: 2023-09-07 15:55:13
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2024-09-14 16:12:47
 * @FilePath: \department-of-commerce\PC\src\view\pilotSite.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="importantAcitve-main">
        <div class="wie">
         
            <div class="content-lengthways">
                <div v-for="(item, index) in fairInfo" :key="index" @click="goTo('detailpageTwo',item)" 
                    style="cursor: pointer;">

                    <img :src="item.img" />
                    <div class="text">
                        <div class="text-title">{{ item.title }}</div>
                        <div class="text-content">{{ item.content }}</div>
                        <div class="text-date">{{ item.date }}</div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import { videoPlayer } from 'vue-video-player'
export default {
    components: {
        videoPlayer,

    },
    data() {
        return {
            loading: false,
            fairInfo: [{
				img: require("@/assets/测试图片/2.png"),
				title: "辽宁品牌商品采购对接会:打造国内外经贸交流与名优商品采购平台",
				content: "辽宁，作为共和国的长子，拥有着悠久底蕴和峥记忆。辽宁省商务厅经过海选初审、网络投票、专家阿巴阿巴阿巴阿巴阿巴阿巴专家阿巴阿巴阿巴阿巴阿巴阿巴专家阿巴阿巴阿巴阿巴阿巴阿巴专家阿巴阿巴阿巴阿巴阿巴阿巴专家阿巴阿巴阿巴阿巴阿专家阿巴阿巴阿巴阿巴阿巴阿巴专家阿巴阿巴阿巴阿巴阿巴阿巴专家阿巴阿巴阿巴阿巴阿巴阿巴专家阿巴阿巴阿巴阿巴阿巴阿巴专家阿巴阿巴阿巴阿巴阿巴阿巴巴阿巴",
				date: "2024.09.26"
			}, {
				img: require("@/assets/测试图片/2.png"),
				title: "辽宁品牌商品采购对接会:打造国内外经贸交流与名优商品采购平台",
				content: "辽宁，作为共和国的长子，拥有着悠久底蕴和峥记忆。辽宁省商务厅经过海选初审、网络投票、专家阿巴阿巴阿巴阿巴阿巴阿巴",
				date: "2024.09.26"
			}, {
				img: require("@/assets/测试图片/2.png"),
				title: "辽宁品牌商品采购对接会:打造国内外经贸交流与名优商品采购平台",
				content: "辽宁，作为共和国的长子，拥有着悠久底蕴和峥记忆。辽宁省商务厅经过海选初审、网络投票、专家阿巴阿巴阿巴阿巴阿巴阿巴",
				date: "2024.09.26"
			}], //展会资讯
            beginNum: 1,
            isNoPage: false,
            type: '',
         
        }
    },
    mounted() {
     
    },
    methods: {
        goTo(url, item) {
            this.$emit('jumpUrl', url);
        },
    }
}
</script>

<style src="../../style/viedo.css"></style>
<style scoped lang="scss">
.broadcast {
    margin-top: 20px;
    background-color: #fff;
    border-radius: 3px;
    padding: 50px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    height: 750px;
    overflow: auto;

}

.pic-wall {
    width: 370px;
    margin-right: 20px;
    margin-bottom: 20px;

    .wall-detail {
        width: 100%;
        // height: 175px;

    }

    .wall-name {
        width: calc(100% - 40px);
        text-align: center;
        padding: 15px 20px;
        font-size: 16px;
        color: #000;
        border-radius: 0px 0px 5px 5px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }



}

.importantAcitve-main {
    background-image: url(../../assets/辽洽会PC端切图/历届成就/主体活动.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: 100vw;
    height: 1000px;

    .mainPage {

        .bac {

            z-index: 99;

            img {
                width: 100%;
                height: auto;
            }
        }



        .mainPageTime {
            padding: 20px 280px;

            .broadcast {
                width: calc(100vw - 750px);
                background-color: #fff;
                border-radius: 3px;
                padding: 50px;
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                height: 750px;
                overflow: auto;
                // background-color: pink;
            }
        }
    }



}

.wie {
    padding: 20px 280px;
    padding-top: 120px;
}
.content-lengthways {
		background-color: #fff;
		padding: 10px 43px;
        margin-top: 20px;

		>div {
			padding: 30px 0px;
			display: flex;
			border-bottom: 1px solid #eff1f7;

			img {
				width: 220px;
				height: 140px;
			}

			.text {
				margin-left: 30px;
				flex-direction: column;
				align-self: flex-start;
				padding: 18px 0px;

				&-title {
					font-size: 22px;
                    font-weight: 600;
					-webkit-line-clamp: 1;
					padding: 0px;
				}

				&-content {

					margin-top: 10px;
					padding: 0px;
				}

				&-date {
					margin-top: 10px;
					padding: 0px;
					color: #a3a3a3;
				}
			}
		}
	}
</style>