<template>
	<div class="consumptionDetails-wrap">
		<div class="consumptionDetails-head">
			<div>
				<img :src="objInfo.companyLogo" alt="">
			</div>
			<div class="head-title">
				<div class="title1">{{ objInfo.title }}</div>
				<div class="title2">{{ objInfo.company }}</div>
			</div>

		</div>
		<div class="detailContent">
			<el-row class="title">
				<img src="../../assets/辽洽快讯/标题装饰.png" />
				<span>产品信息</span>
			</el-row>

		</div>
		<div class="info">
			{{ objInfo.content }}
		</div>
		<div style="display: flex;flex-wrap:wrap">
			<div class="tupian" v-for="(item, index) in 4">

				<el-image class="elImg" v-if="objInfo['productImage' + (index + 1)]" :src="objInfo['productImage' + (index + 1)]"
					:preview-src-list="[objInfo['productImage' + (index + 1)]]">
				</el-image>

			</div>
		</div>
		<div class="detailContent">
			<el-row class="title">
				<img src="../../assets/辽洽快讯/标题装饰.png" />
				<span>企业信息</span>
			</el-row>

		</div>
		<div class="info">
			{{ objInfo.enterpriseInformation }}
		</div>
		<div style="display: flex;flex-wrap:wrap">
			<div class="tupian" v-for="(item, index) in 4">
				<el-image class="elImg" v-if="objInfo['enterpriseImage' + (index + 1)]"
					:src="objInfo['enterpriseImage' + (index + 1)]"
					:preview-src-list="[objInfo['enterpriseImage' + (index + 1)]]">
				</el-image>
			</div>
		</div>


	</div>
</template>

<script>

export default {
	props: ["id"],

	data() {
		return {
			dialogVisible: false,
			objInfo: "",
			ruleForm: {
				onlineId: "",
				name: "",
				mobileNumber: "",
				leavingMessage: "",
			},

		}
	},
	mounted() {
		this.getDetail()
	},
	methods: {
		getDetail() {
			this.$baseRequestGet('/not/goods/boutique/details?info=' + this.id, {}, 'get').then(res => {
				this.objInfo = res.data;
			})
		},
	
	
	}
}
</script>

<style src="@/style/element-ui-class.css"></style>

<style>
.consumptionDetails-wrap .el-input__inner {
	background-color: #f5f6f8 !important;
	height: 55px;
	line-height: 55px;
	border: none;
}

.consumptionDetails-wrap .el-textarea__inner {
	background-color: #f5f6f8 !important;
	border: none;
}
</style>

<style scoped lang="scss">
.consumptionDetails-wrap {
	padding: 50px 30px;
	background-color: #fff;

	.consumptionDetails-head {
		border-bottom: 1px solid #e7e9eb;
		padding-bottom: 20px;
		display: flex;
		justify-content: flex-start;

		img {
			width: 150px;
			height: 150px;
			margin-right: 20px;
			border-radius: 10px;
		}

		.head-title {
			.title1 {
				font-size: 27px;
				font-weight: 400;
			}

			.title2 {
				margin-top: 10px;
				font-size: 18px;
				color: #666666;
			}
		}

		.head-btn {
			display: flex;
			align-items: center;
			justify-content: right;

			img {

				width: 107px;
				height: 35px;
				vertical-align: bottom;
				cursor: pointer;
				// object-fit: contain;
			}
		}
	}

	.rich-text {
		margin-top: 20px;
		font-size: 15px;

		.richPic {
			text-align: center;
		}

		.wall-detail {
			width: 50%;

			margin-bottom: 20px;
		}
	}

	.dialog {
		text-align: center;
		padding: 0 45px 30px 45px;

		.dialog-title {
			color: #000;
			font-size: 22px;
			font-weight: bold;
		}

		.dialog-input {
			margin-top: 40px;
		}

		.dialog-btn {
			margin-top: 30px;
		}

		.sumbitBox {
			width: 220px;
			height: 44px;
			// cursor: pointer;
		}
	}
}

.title {
	img {
		width: 25px;
		height: 25px;
	}

	span {
		line-height: 25px;
		margin-left: 9px;
		font-size: 29px;
		font-family: 'DingTalk_JinBuTi_Regular';
	}
}

.detailContent {
	margin-top: 40px;
}

.info {
	font-size: 17px;
	margin-top: 20px;
	letter-spacing: 1px;
	line-height: 140%;
	color: #121212;
	margin-bottom: 20px;
}

.tupian {
	.elImg {
		width: 200px;
		height: 200px;
		border-radius: 5px;
		margin-right: 20px;
	}
}
</style>