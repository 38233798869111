<!--
 * @Author: jiangjinchi 1468922694@qq.com
 * @Date: 2023-09-07 19:10:06
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2024-07-23 09:59:40
 * @FilePath: \department-of-commerce\PC\src\view\components\openDetails.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="keyDetail">
        <div class="ql-container ql-snow" style="border: none;">
            <div class="top-detail">
                <div class="name-detail">
                    {{ infoData.journalismTitle }}
                </div>
                <div class="time-detail">
                    {{ infoData.journalismDate }}
                </div>
            </div>

            <div class="ql-container ql-snow" style="border: none;">
                <div class="ql-editor" @click="showImage($event)" v-html="infoData.journalismContent"></div>
            </div>
        </div>
        <div id="chatRef">
            <chat :id="id" :fatherScrollHeigh="fatherScrollHeigh" :chatType="'charmGrace'"></chat>
        </div>
        <elImageViewer v-if="dialogVisible" :on-close="closeImage" :url-list="[url]" />
    </div>
</template>

<script>

export default {

    props: ['id'],
    data() {
        return {
            infoData: {
            },
            url: '',
            dialogVisible: false,
            playerOptionsList: [],
            fatherScrollHeigh: ''
        }
    },

    mounted() {
        this.getDetail()
    },
    methods: {
        showImage(e) {
            if (e.target.tagName == 'IMG') {

                this.url = e.target.src
                this.dialogVisible = true
            }
        },
        closeImage() {
            this.dialogVisible = false
            this.url = '';
        },
        getDetail() {
            this.$baseRequestGet('/maintain/journalism/charm/grace/details?info=' + this.id, {}, 'get').then(res => {
                this.infoData = res.data;
                if (this.infoData.journalismContent) {
                    this.infoData.journalismContent = this.infoData.journalismContent.replace(/<iframe/g, "<video controls style='width:100%;height:auto;'")
                }
                this.$nextTick(res => {
                    this.fatherScrollHeigh = document.getElementById('chatRef').offsetTop;
                })
            })
        },

        goTo() {
            this.$emit("goBack");
        }
    }
}
</script>

<style src="@/style/element-ui-class.css"></style>

<style scoped lang="scss">
.name-detail {
    font-size: 30px;
    color: #000;
    letter-spacing: 1px;
}

.time-detail {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 20px;
    color: #7E7E7E;
}

.top-detail {
    padding: 0px 15px;
}

.keyDetail {
    background-color: #fff;
    padding: 40px;
}

.openDetails-wrap {
    

    .content {
        padding: 60px 310px;

        .breadcrumb {
            height: 15px;
            display: flex;
            align-items: center;
            line-height: 15px;
            color: #8389a6;
            margin-bottom: 20px;
            font-size: 19px;

            div {
                display: flex;
                align-items: center;

                img {
                    width: 18px;
                    height: auto;
                    margin-right: 10px;
                }
            }

            i {
                margin: 0 5px;
            }
        }

        .rich-text {
            margin-top: 20px;
            background-color: #fff;
            padding: 50px 80px;
        }
    }
}

.carousel {
    width: 100%;
    height: 457px;
}

.videoBox {
    margin-bottom: 30px;
}

.cityBox {
    font-size: 28px;
    margin-bottom: 30px;
    color: #000;
}
</style>