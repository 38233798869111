<template>
    <div>
        <div style="width: 100%; display: flex; position: relative;margin-top:30px">
            <div style="width: 10%; margin-left: 30px">
                <div>
                    <!-- 开头 -->
                    <span data-v-jzl20210826="" class="headerRadio f-1"></span>
                    <!-- 第二行 -->
                    <span class="hingelisHeard f-2" v-if="experienceData.length > Index * 2"></span>
                    <span data-v-jzl20210826="" v-if="experienceData.length > Index &&
                        experienceData.length <= Index * 2
                        " class="hingeorgerHeard f-3"></span>
                    <!-- 第三行 -->
                    <!-- <span class="hingelisHeard" style="margin-top:99.5px"></span> -->
                    <span data-v-jzl20210826="" v-if="experienceData.length > Index * 3" style=""
                        class="hingeorgerHeard f-4"></span>
                </div>
            </div>
            <div style="width: 100%">
                <!-- 第一行数据 -->
                <div style="display: flex">
                    <div class="timeline" v-for="(v, i) in DisplayProcessing(experienceData, 1)" :key="i">
                        <div class="border"></div>
                        <div class="Nodes"></div>
                        <div class="timeNodes" >
                            <el-tooltip class="item" :offset="[1000,1000]" effect="dark"  placement="top">
                               <div class="f-p">

                               </div>
                            </el-tooltip>
                            <p class="nodeTimes">
                                <span>{{ v.date }}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <!-- 第二行数据 -->
                <div style="display: flex">
                    <div class="timeline" v-for="(v, i) in DisplayProcessings(experienceData, 2)" :key="i">
                        <div class="border"></div>
                        <div class="Nodes"></div>
                        <div class="timeNodes">
                            <el-tooltip class="item" effect="dark" :content="v.name" placement="right">
                                <p class="nodeTimelis">
                                    <span>{{ v.name }}</span>
                                </p>
                            </el-tooltip>
                            <p class="nodeTimes">
                                <span>{{ v.date }}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <!-- 第三行数据 -->
                <div style="display: flex">
                    <div class="timeline" v-for="(v, i) in DisplayProcessingss(experienceData, 3)" :key="i">
                        <div class="border f-5" ></div>
                        <div class="Nodes f-5"></div>
                        <div class="timeNodes">
                            <el-tooltip class="item" effect="dark" :content="v.name" placement="bottom">
                                <p class="nodeTimelis">
                                    <span>{{ v.name }}</span>
                                </p>
                            </el-tooltip>
                            <p class="nodeTimes">
                                <span>{{ v.date }}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <!-- 第四行数据 -->
                <div style="display: flex">
                    <div class="timeline" v-for="(v, i) in DisplayProcessingsss(experienceData, 4)" :key="i">
                        <div class="border f-5"></div>
                        <div class="Nodes f-5" ></div>
                        <div class="timeNodes">
                            <el-tooltip class="item" effect="dark" :content="v.name" placement="right">
                                <p class="nodeTimelis">
                                    <span>{{ v.name }}</span>
                                </p>
                            </el-tooltip>
                            <p class="nodeTimes">
                                <span>{{ v.date }}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div >
                <div>
                    <!-- 第一行 -->
                    <span class="hingelis" v-if="experienceData.length > Index"></span>
                    <span data-v-jzl20210826="" v-if="experienceData.length <= Index" class="hingeorger"></span>
                    <!-- 第二行 -->
                    <span class="hingelis f-8" v-if="experienceData.length > Index * 3" style=""></span>
                    <span data-v-jzl20210826="" v-if="experienceData.length > Index * 2 &&
                        experienceData.length <= Index * 3
                        "  class="hingeorger f-8"></span>
                    <!-- 第三行 -->
                    <!-- <span class="hingelis" style="margin-top:100.3px"></span> -->
                    <!-- <span  data-v-jzl20210826="" style="margin-top:100.3px" class="hingeorger"></span> -->
                </div>
            </div>
            <!-- <div style="width: 70px; display: flex; align-items: center">
          <span style="margin-left: 10px">{{ true ? "展开" : "收起" }}</span>
          <img
            style="margin-left: 3px; width: 15px; height: 15px"
            src="../../assets/image/open.png"
            alt
          />
          <img v-if="false" src="../../assets/image/put.png" alt />
        </div> -->
        </div>
    </div>
</template>
<script>
export default {
    props: {
        data: [],
        Index: 0, //第一行展示X条数据
    },
    data() {
        return {
            experienceData: this.data,
        };
    },
    methods: {
        DisplayProcessing(Arg, Num) {
            if (Num == 1) {
                let arr = Arg.slice(0, this.Index * Num);
                return arr;
            }
        },
        DisplayProcessings(Arg, Num) {
            if (Num == 2) {
                let arr = Arg.slice(this.Index, this.Index * Num).reverse();
                return arr;
            }
        },
        DisplayProcessingss(Arg, Num) {
            if (Num == 3) {
                let arr = Arg.slice(this.Index * 2, this.Index * Num);
                return arr;
            }
        },
        DisplayProcessingsss(Arg, Num) {
            if (Num == 4) {
                let arr = Arg.slice(this.Index * 3, this.Index * Num).reverse();
                return arr;
            }
        },
    },
    watch: {
        data: function (newVal, oldVal) {
            this.experienceData = newVal;
        },
    },
};
</script>
<style scoped>
.timeline {
    width: 100%;
    height: 100px;
    position: relative;
}

.border {
    width: 100%;
    border-bottom: 1px solid #cccccc;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.Nodes {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: white;
    border: 5px solid #0075c7;
    position: absolute;
    right: 35px;
    top: 50%;
    transform: translate(0, -50%);
}

.timeNodes {
    position: absolute;
    text-align: center;
    right: -5px;
    width: 105px;
    top: 50%;
    transform: translate(0, -50%);
}

.nodeTimelis {
    width: 70px;
    margin-bottom: 20px;
    margin-left: 20px;
    overflow: hidden;
    word-break: keep-all;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.nodeTimes {
    margin-top: 15px;
}

.hingelis {
    content: "";
    display: block;
    width: 100%;
    height: 98px;
    border: 3px solid #E5E5E5;
    border-radius: 0 50px 50px 0;
    border-left: 0px;
    margin-top: 49px;
}

.hingelisHeard {
    content: "";
    display: block;
    width: 100%;
    height: 98px;
    border: 3px solid #E5E5E5;
    border-radius: 50px 0 0 50px;
    border-right: 0px;
    margin-top: 48px;
}

.hingeorger {
    display: block;
    width: 100%;
    border-bottom: 1px solid #cccccc;
    margin: 49.5px 0;
    position: relative;
}

.hingeorgerHeard {
    display: block;
    width: 100%;
    border-bottom: 3px solid #E5E5E5;
    position: relative;
}

.hingeorgerHeard[data-v-jzl20210826]:after {
    content: "";
    position: absolute;
    top: -4px;
    left: -2px;
    border-top: 5px solid transparent;
    border-right: 12px solid #cccccc;
    border-bottom: 5px solid transparent;
}

.hingeorger[data-v-jzl20210826]:after {
    content: "";
    position: absolute;
    top: -4px;
    right: -2px;
    border-top: 5px solid transparent;
    border-left: 12px solid #cccccc;
    border-bottom: 5px solid transparent;
}

.headerRadio {
    display: block;
    width: 100%;
    border-bottom: 3px solid #E5E5E5;
    position: relative;
}

.headerRadio[data-v-jzl20210826]:after {
    content: "";
    position: absolute;
    top: -4px;
    left: -2px;
    width: 1px;
    height: 1px;
    border-radius: 50%;
    background: #cccccc;
    border: 5px solid #cccccc;
    position: absolute;
    right: 35px;
    top: 50%;
    transform: translate(0, -50%);
}

.f-1 {
    margin-top: 49.5px
}

.f-2 {
    margin-top: 98px
}

.f-3 {
    margin-top: 98.5px
}

.f-4 {
    margin-top: 98px
}
.f-5 {
    top: 51%
}
.f-6 {
    top: 51%
}
.f-7 {
    width: 10%; margin-right: 30px
}
.f-8 {
    margin-top: 99px
}
.f-p {
    /* width: 150px;
    height: 150px;
    background-color: pink;
    position: absolute;
    top: -200px; */

}
.item {
    border: 4px solid #0075c7;
    position: relative;
    bottom: 100px;
    width: 150px;
    height: 150px;
}
</style>