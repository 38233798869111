<!--
 * @Author: jiangjinchi 1468922694@qq.com
 * @Date: 2023-09-07 15:55:13
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2024-07-22 09:15:17
 * @FilePath: \department-of-commerce\PC\src\view\pilotSite.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="importantAcitve-main">
        <div class="mainPage">
            <div class="bac">

            </div>
            <div class="mainPageTime">
                <div >
                    <!-- <img src="@/assets/内容页切图/PC重要活动.png" alt=""> -->
                </div>
            </div>
            <div class="zhan"></div>
            <!-- <div class="mainPageTime">
                <div>
                    <img src="@/assets/内容页切图/PC重要活动.png" alt="">
                </div>
            </div> -->
        </div>

    </div>
</template>

<script>

export default {

    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {

    }
}
</script>


<style scoped lang="scss">
.importantAcitve-main {
    

    // position: relative;
    .mainPage {
        position: relative;
        .bac {
            width: 100vw;
            height: 435px;
            background-image: url(@/assets/内容页切图/顶部图-重要活动.png);
            background-size: contain;
            background-repeat: no-repeat;
        }
        .zhan {
            height: 802px;
        }
        .mainPageTime {
            padding: 0px 310px;
            height: 802px;
            position: absolute;
            top: 200px;
            div {
                background-color: #fff;
                border-radius: 3px;
                padding: 50px;
            }
            img {
                // width: 100%;
                // 1205 x 802
                width: 1205px;
                height: 802px;
                border-radius: 5px;
            }
            
        }

        // img {
        //     width: 100%;
        //     height: 435px;

        // }

        // .mainPageTime {
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     padding: 0px 310px;

        //     div {
        //         background-color: pink !important;
        //         border-radius: 3px;
        //         padding: 30px;

        //         img {
        //             width: 100%;
        //             border-radius: 5px;
        //         }

        //     }

        //     // padding: 20px;
        // }
    }



}
</style>