<template>
    <div class="detailOne">
        <div :class="$route.name == '云展厅' || typeName == '西藏特产美物美食'? 'htm': ''" v-if="['西藏特产美物美食', '非遗文创精粹'].includes(this.typeName)">

            <div class="htm-box-name">
                {{ infoData.productName }}
            </div>
            <div class="htm-box-img" v-if="infoData.picture">
                <el-image  class="imgss" :src="infoData.picture" :preview-src-list="[infoData.picture]"></el-image>
            </div>
            <div class="productIntroduction">
                {{ infoData.productIntroduction }}
            </div>
            <div class="htm-flex">
                <div class="htm-item" v-for="(item,index) in picList" :key="index">
                    <el-image   class="imgss" :src="item" :preview-src-list="picList"></el-image>
                </div>


            </div>
        </div>
        <div  :class="$route.name == '云展厅'? 'htm': ''" v-else>
         
            <div class="htm-box-name">
                {{ typeName == '西藏网红餐厅' ? infoData.restaurantName : infoData.specialName }}
            </div>
            <div class="htm-box-img" v-if="infoData.coverImage">
                <el-image  class="imgss" :src="infoData.coverImage" :preview-src-list="[infoData.coverImage]"></el-image>
            </div>
            <div class="productIntroduction">
                {{ infoData.introductionFeatures }}
            </div>
            <div class="htm-flex">
                <div class="htm-item">
                    <el-image v-if="infoData.productDiagram1"  class="imgss" :src="infoData.productDiagram1" :preview-src-list="[infoData.productDiagram1]"></el-image>
                </div>
                <div class="htm-item">
                    <el-image v-if="infoData.productDiagram2"  class="imgss" :src="infoData.productDiagram2" :preview-src-list="[infoData.productDiagram2]"></el-image>
                </div>
                <div class="htm-item">
                    <el-image v-if="infoData.productDiagram3"  class="imgss" :src="infoData.productDiagram3" :preview-src-list="[infoData.productDiagram3]"></el-image>
                </div>
                <div class="htm-item">
                    <el-image v-if="infoData.productDiagram4"  class="imgss" :src="infoData.productDiagram4" :preview-src-list="[infoData.productDiagram4]"></el-image>
                </div>

            </div>
        </div>

    </div>
</template>

<script>

export default {
    components: {

    },
    props: {
        idDetails: {
            type: [String, Number]
        },
        typeName: {
            type: String
        }
    },
    data() {
        return {
            infoData: {

            },
            picList: []
        }
    },
    mounted() {
        this.getDetails()
    },
    methods: {
        getDetails() {
            this.picList = []
            if (this.typeName == '西藏网红餐厅') {
                this.$baseRequest('/not/restaurant/special/specifics?info=' + this.idDetails, {}, 'get').then(res => {
                    this.infoData = res.data;
                    for (const key in this.infoData) {
                        if(key.includes("productDiagram") && this.infoData[key]) {
                            this.picList.push(this.infoData[key])
                        }
                    }
                 
                })
            } else if (['西藏特产美物美食', '非遗文创精粹'].includes(this.typeName)) {
                this.$baseRequest('/not/goods/boutique/details?info=' + this.idDetails, {}, 'get').then(res => {
                    this.infoData = res.data;
                    for (const key in this.infoData) {
                        if(key.includes("companyDiagram") && this.infoData[key]) {
                            this.picList.push(this.infoData[key])
                        }
                    }
                  
                })
            }
            else {
                this.$baseRequest('/scenic/spot/special/specifics?info=' + this.idDetails, {}, 'get').then(res => {
                    this.infoData = res.data;
                    for (const key in this.infoData) {
                        if(key.includes("productDiagram") && this.infoData[key]) {
                            this.picList.push(this.infoData[key])
                        }
                    }
                })
            }

        }
    }
}
</script>


<style scoped lang="scss">
.detailOne {
    width: 65%;
     margin: 0px auto;
}
.htm-flex {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.htm-item {
    width: 200px;
    height: 200px;

    .imgss {
        width: 100%;
        height: 100%;

        border-radius: 5px;
    }
}
.productIntroduction {
    font-size: 17px;
}
.htm {
    padding: 60px 0px;
}

.htm-box-img {
    margin-bottom: 30px;
    .imgss {
        width: 100%;
        height: auto;
    }
}

.htm-box-name {
    font-size: 30px;
    color: #000;
    margin-bottom: 30px
}
</style>