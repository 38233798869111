<template>
	<div :class="isDetails ? 'body1' : 'body2'">
		<div class="FixedContentStyle">
			<FixedContent :titleJumpList="titleJumpList" @jumpUrl="jumpUrl" @back="back"></FixedContent>
		</div>
		<div class="content" v-if="!isDetails">
			<div class="img">
				<img :src="ebay[ebayIndex].img" @click="goTo('detailpageTwo', ebay[ebayIndex])" />
			</div>
			<div class="list">
				<div class="item" v-for="(item, index) in ebay" :key="index" @click="onclick(index)"
					:class="ebayIndex == index ? 'item-click' : ''">
					<div class="item-name">
						{{ item.name }}
					</div>
					<img src="../../assets/开放平台/跨境电商选中.png" v-show="ebayIndex == index" />
				</div>
			</div>
		</div>
		<el-row v-else>
			<div class="details">
				<div class="introduct-left ">
					<div class="title">
						{{ details.journalismTitle }}
					</div>
					<div class="date">
						{{ details.journalismDate }} <span> {{ details.source }}</span>
					</div>
					<div class="ql-container ql-snow" style="border: none;">
						<div class="ql-editor" @click="showImage($event)" v-html="details.journalismContent"></div>
					</div>
				</div>
			</div>
		</el-row>
		<elImageViewer v-if="dialogVisible" :on-close="closeImage" :url-list="[url]" />
	</div>
</template>

<script>
export default {
	data() {
		return {
			url: '',
			dialogVisible: false,
			titleJumpList: [{
				url: "openLiaoning",
				name: "开放平台"

			}, {
				url: "ebay",
				name: "跨境电商"

			}],
			ebay: [{
				img: require("@/assets/开放平台/跨境电商-沈阳.png"),
				name: "中国(沈阳)跨境电子商务综合试验区"
			}, {
				img: require("@/assets/开放平台/跨境电商-大连.png"),
				name: "中国(大连)跨境电子商务综合试验区"
			}, {
				img: require("@/assets/开放平台/跨境电商-鞍山.png"),
				name: "中国(鞍山)跨境电子商务综合试验区"
			}, {
				img: require("@/assets/开放平台/跨境电商-抚顺.png"),
				name: "中国(抚顺)跨境电子商务综合试验区"
			}, {
				img: require("@/assets/开放平台/跨境电商-营口.png"),
				name: "中国(营口)跨境电子商务综合试验区"
			}, {
				img: require("@/assets/开放平台/跨境电商-盘锦.png"),
				name: "中国(盘锦)跨境电子商务综合试验区"
			}],
			isDetails: false,
			details: {},
			ebayIndex: 0
		}
	},
	mounted() {
		this.getEbay();
	},
	watch: {
		isDetails(val) {

			if (val) {
				this.titleJumpList = [
					{
						url: "openLiaoning",
						name: "开放平台"

					}, {
						url: "",
						name: "跨境电商"

					}, {
						url: "",
						name: "详情"

					}
				]


			} else {
				this.titleJumpList = [
					{
						url: "openLiaoning",
						name: "开放平台"

					}, {
						url: "",
						name: "跨境电商"

					}
				]

			}
		}
	},
	methods: {
		showImage(e) {
			if (e.target.tagName == 'IMG') {

				this.url = e.target.src
				this.dialogVisible = true
			}
		},
		closeImage() {
			this.dialogVisible = false
			this.url = '';
		},
		back() {
			this.isDetails = false;
		},
		//跳转 
		goTo(url, item) {
		
			let params = {
				name: this.query,
				id: item.id,
				titleJumpList: JSON.stringify(this.titleJumpList)
			}
			this.$emit('jumpUrl', url, params)
		},
		jumpUrl(e) {
			this.$emit('jumpUrl', e)
		},
		getEbay() {
			this.$baseRequest("/maintain/journalism/findList", {
				journalismCategory: "开放辽宁",
				journalismSecondaryClassification: "跨境电商"
			}, "post").then(res => {
				this.ebay = [];
				res.data.forEach(item => {
					this.ebay.push({
						id: item.id,
						img: item.journalismPhoto,
						name: item.journalismTitle
					})
				})
			})
		},
		//详情 
		getDetails(id) {
			this.$baseRequest("/maintain/journalism/details?info=" + id, {}, "get").then(res => {
				this.details = res.data;
				if (this.details.journalismContent) {
					this.details.journalismContent = this.details.journalismContent.replace(/<iframe/g, "<video controls style='width:100%;height:auto;'")
				}
				this.isDetails = true;
			})
		},
		onclick(index) {
			this.ebayIndex = index;
		},

	}

}
</script>

<style lang="scss" scoped>
.body1 {
	padding: 20px 280px;


	.content {
		display: flex;
	}

	.img>img {
		width: 1017px;
		height: 546px;
		cursor: pointer;
	}

	.list {
		.item {
			width: 261px;
			height: 89px;
			display: flex;
			background-color: #fff;
			cursor: pointer;

			&-name {
				width: calc(100% - 7px);
				text-align: center;
				font-size: 17px;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 0px 32px;
				font-family: "思源黑体CN-Bold";
			}

			>img {
				width: 7px;
				height: 100%;
			}
		}

		.item-click {
			background-color: #f6f9ff;
		}
	}

	.details {

		padding: 20px;
		background-color: #fff;
		border-radius: 5px;

		.introduct-left {
			background: #fff;
			padding-bottom: 60px;

			.title {
				font-size: 28px;
				text-align: left;
			}

			.date {
				font-size: 18px;
				color: #878787;
				margin-top: 10px;
				margin-bottom: 10px;
			}

			.content {
				font-size: 16px;
			}
		}
	}
}

.body2 {
	padding: 20px 280px;


	.content {
		display: flex;
	}

	.img>img {
		width: 1017px;
		height: 546px;
		cursor: pointer;
	}

	.list {
		.item {
			width: 261px;
			height: 89px;
			display: flex;
			background-color: #fff;
			cursor: pointer;

			&-name {
				width: calc(100% - 7px);
				text-align: center;
				font-size: 17px;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 0px 32px;
				font-family: "思源黑体CN-Bold";
			}

			>img {
				width: 7px;
				height: 100%;
			}
		}

		.item-click {
			background-color: #f6f9ff;
		}
	}

	.details {

		padding: 20px;
		background-color: #fff;
		border-radius: 5px;

		.introduct-left {
			background: #fff;
			padding-bottom: 60px;

			.title {
				font-size: 28px;
				text-align: left;
			}

			.date {
				font-size: 18px;
				color: #878787;
				margin-top: 10px;
				margin-bottom: 10px;
			}

			.content {
				font-size: 16px;
			}
		}
	}
}

.FixedContentStyle {

	margin-bottom: 20px;
}
</style>