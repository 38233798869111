<template>
	<div class="body">
		<div class="FixedContentStyle">
			<FixedContent v-if="showDetail" :titleJumpList="titleJumpList" @back="back"></FixedContent>
		</div>
		<div class="head" v-if="!showDetail">
			<div class="input">
				<input v-model="params.conent" placeholder="请输入搜索内容" @input="inputConent" />
			</div>
			<img @click="sumbitInfo('供应')" style="cursor: pointer;" class="imgs" src="../../assets/供采对接/发布供应信息.png" />
			<img @click="sumbitInfo('采购')" style="cursor: pointer;" class="imgs" src="../../assets/供采对接/发布采购信息.png" />
		</div>
		<div class="chooseList" v-if="!showDetail">
			<div @click="clickChoose(item)"  :class="chooseIndex == item ? 'yesChoose' : 'noChoose'" v-for="(item, index) in chooseList" :key="index">
				{{ item }}</div>


		</div>
		<div class="infiss">
			<div class="infissLeft" v-if="!showDetail">
				<div class="overflow">
					<div class="infissLeftItem" v-for="(item, index) in demandInfo" :key="index"
						@click="clickDetail(index)">
						<div class="itemTitlw">
							<div>
								<img class="itemTitlwOne" :src="require(`../../assets/供采对接/${item.type}商头像.png`)"
									alt="">
								<span>{{ item.company }}</span>
								<img class="itemTitlwTwo" :src="require(`../../assets/供采对接/${item.type}商.png`)" alt="">
							</div>
						</div>
						<div class="conts conts-conceal">
							{{ item.content }}
						</div>
						<div class="tupItem">
							<div class="tupItemImg" v-for="(img, imgIndex) in item.img" :key="imgIndex">
								<img class="myImg" :src="img" alt="">
							</div>
						</div>
					</div>
				</div>
				<el-pagination class="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page.sync="pageNum" :page-sizes="[3, 6, 10, 20, 30, 40, 50, 100]" :page-size="pageSize"
					layout="sizes, prev, pager, next" :total="total">
				</el-pagination>
			</div>
			<div class="infissLeft" v-else>
				<div class="infissLeftItem">
					<div class="itemTitlw" style="display: flex;justify-content: space-between;">
						<div>
							<img class="itemTitlwOne"
								:src="require(`../../assets/供采对接/${demandInfo[detailIndex].type}商头像.png`)" alt="">
							<span>{{ demandInfo[detailIndex].company }}</span>
							<img class="itemTitlwTwo"
								:src="require(`../../assets/供采对接/${demandInfo[detailIndex].type}商.png`)" alt="">
						</div>
						<div>
							<img @click="openDialog" class="itemTitlwThrees" src="../../assets/其他/供采对接/联系企业.png" alt="">

						</div>
					</div>
					<div class="conts">
						{{ demandInfo[detailIndex].content }}
					</div>
					<div class="tupItem">
						<div class="tupItemImg" v-for="(img, imgIndex) in demandInfo[detailIndex].img" :key="imgIndex">
							<!-- <img :src="img" alt=""> -->
							<el-image class="myImg" :src="img" :preview-src-list="[img]">
							</el-image>
						</div>
					</div>
				</div>
			</div>
			<div class="infissRight">
				<div class="remen">公告中心</div>
				<div v-for="(item, index) in notice" class="infissRightitem" :key="index">
					<div>{{ item }}</div>
				</div>
			</div>
		</div>
		<el-dialog title="联系企业" :visible.sync="centerDialogVisible" width="30%" :close-on-click-modal="false"
			@close="close">
			<div class="dialog">
				<div class="dialog-input">
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm"
						:label-position="'top'">
						<el-form-item prop="enterpriseName" label="企业">
							<el-input v-model="ruleForm.enterpriseName" placeholder="请输入您的企业"></el-input>
						</el-form-item>
						<el-form-item prop="name" label="姓名">
							<el-input v-model="ruleForm.name" placeholder="请输入您的姓名"></el-input>
						</el-form-item>
						<el-form-item prop="mobileNumber" label="电话号码">
							<el-input v-model="ruleForm.mobileNumber" placeholder="请输入您的电话号码"></el-input>
						</el-form-item>
						<el-form-item label="留言">
							<el-input v-model="ruleForm.leavingMessage" type="textarea" placeholder="请输入您的留言" :rows="5"
								resize="none"></el-input>
						</el-form-item>
					</el-form>
				</div>
				<div class="dialog-btn"><img class="sumbitBox1" @click="close" src="../../assets/其他/供采对接/弹窗按钮-取消.png" />
					<img class="sumbitBox1" @click="clickSumbit" src="../../assets/其他/供采对接/弹窗按钮-提交.png" />
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
export const isPhone = (rule, value, callback) => {
	let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
	if (value != "") {
		if (reg.test(value)) {
			callback();
		} else {
			callback('请输入正确联系方式')
		}
	} else {
		callback();
	}
}
export default {
	data() {
		return {
			chooseList: ['看全部', '只看供应信息', '只看采购信息'],
			chooseIndex: '只看采购信息',
			titleJumpList: [
				{
					url: "",
					name: "供采对接"

				}, {
					url: "",
					name: "详情"

				}
			],
			params: {
				conent: "",
			},
			showDetail: false,
			centerDialogVisible: false,
			ruleForm: {
				onlineId: "",
				name: "",
				mobileNumber: "",
				leavingMessage: "",
			},
			rules: {
				enterpriseName: [{
					required: true,
					message: '请输入您的企业',
					trigger: 'blur'
				},],
				name: [{
					required: true,
					message: '请输入您的姓名',
					trigger: 'blur'
				},],
				mobileNumber: [{
					required: true,
					message: '请输入您的电话号码',
					trigger: 'blur'
				}, {
					validator: isPhone,
					trigger: 'blur'
				}]
			},
			demandInfo: [], //需求信息
			total: 0,
			pageNum: 1,
			pageSize: 6,
			detailIndex: 0, //详情选择下标
			notice: [

			], //公告
			timer: null,
		}
	},
	mounted() {
		this.getNotice();
		this.getDemandInfo();
	},
	methods: {
		clickChoose(e) {
			this.chooseIndex = e;
			this.getDemandInfo();
		},
		sumbitInfo(e) {
			if (!localStorage.getItem('loginInfo')) {
				this.$router.replace({ path: '/loginPage' })
			} else {

				this.goTo('supplyPurchaseDetail', e)
			}
		},
		back(e) {
			this.showDetail = false;
		},
		getDemandInfo(flag) {
			if (flag) this.pageNum = 1
			this.$baseRequest("/not/online/negotiation/findList", {
				title: this.params.conent,
				beginNum: this.pageNum,
				endNum: this.pageSize,
				province: this.chooseIndex == '看全部'? '': this.chooseIndex == '只看供应信息'? '供应': '采购'
			}, "post").then(res => {
				var data = res.data;
				this.total = Number(data.total);
				this.demandInfo = [];
				data.records.forEach(item => {
					this.demandInfo.push({
						id: item.id,
						company: item.company,
						type: item.province,
						content: item.content,
						img: item.photos
					});
				})
				this.$el.querySelector('.infissLeft>.overflow').scrollTop = 0;
			})
		},
		handleCurrentChange(val) {
			this.pageNum = val;
			this.getDemandInfo();
		},
		handleSizeChange(val) {
			this.pageSize = val;
			this.getDemandInfo(true);
		},
		inputConent() {
			if (this.timer) {
				clearTimeout(this.timer)
			}
			this.timer = setTimeout(() => {
				this.getDemandInfo(true);
			}, 1000)
		},
		getNotice() {
			this.$baseRequest("/not/online/notice/findList", {}, "get").then(res => {
				this.notice = [];
				res.data.forEach(item => {
					this.notice.push(item.notice);
				})
			})
		},
		//跳转 
		goTo(url, query) {
			this.$emit('jumpUrl', url, query);

		},
		clickDetail(index) {
			this.showDetail = true;
			this.detailIndex = index;
		},
		clickSumbit() {
			this.$refs['ruleForm'].validate((valid) => {
				if (valid) {
					this.ruleForm.onlineId = this.demandInfo[this.detailIndex].id;
					this.$baseRequest('/not/online/comments/add', this.ruleForm, 'POST').then(res => {
						this.$message.success("提交成功！");
						this.centerDialogVisible = false;
						this.close();
					})
				}
			});

		},
		close() {
			this.ruleForm = {
				onlineId: "",
				name: "",
				mobileNumber: "",
				leavingMessage: "",
			}
			this.$refs['ruleForm'].clearValidate();
			this.centerDialogVisible = false;
		},
		openDialog() {
			this.centerDialogVisible = true;

		}
	}

}
</script>
<style>
.el-dialog {
	border-radius: 10px;
}

.el-dialog__headerbtn .el-dialog__close {
	color: #fff;
}

.el-dialog__header {
	background-image: url(../../assets/供采对接/弹窗背景.png);
	background-repeat: no-repeat;
	background-size: cover;
	text-align: center;




}

.el-dialog__title {
	color: #fff !important;
}
</style>
<style lang="scss" scoped>
.FixedContentStyle {
	margin-bottom: 20px;
}

.body {
	padding: 20px 280px;
	// background-color: #F6F7F9;

	.head {
		width: 100%;
		height: 128px;
		background-image: url("../../assets/供采对接/搜索bg.png");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		border-radius: 10px;
		display: flex;
		justify-content: center;
		align-items: center;

		.input {
			width: 43%;
			height: 54px;
			margin-right: 13px;

			input {
				padding-left: 10px;
				width: 100%;
				height: 100%;
				background-color: rgba(37, 25, 11, 0.5);
				border-radius: 10px;
				border: 2px solid #fff;
				color: #fff;
				font-size: 20px;
			}
		}

		img {
			margin-left: 20px;
			width: 130px;
			height: auto;
		}
	}
}

.infiss {
	display: flex;
	margin-top: 20px;
	justify-content: space-between;

	.infissLeft {
		width: 70vw;
		margin-right: 20px;

		>div.overflow {
			max-height: 800px;
			overflow-y: auto;
		}
	}

	.infissRight {
		width: 400px;
		height: 540px;
		padding: 20px 30px;
		border-radius: 10px;
		background-image: url("../../assets/供采对接/供采对接-滚屏bg.png");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		color: #fff;

		.remen {
			width: 100%;
			text-align: center;
		}

		.infissRightitem {
			margin-bottom: 10px;
			padding: 0px;
			font-size: 15px;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.dott {
			width: 8px;
			height: 8px;
			background-color: #EA0E1E;
			margin-right: 10px;
			border-radius: 50%;
			position: absolute;
			top: 10%;

		}

		.infissRightitemfff {
			font-size: 18px;
			padding-left: 20px;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
			text-overflow: ellipsis;
		}

	}
}


.remen {
	font-size: 22px;
	letter-spacing: 2px;
	margin-bottom: 20px;
	font-weight: 600;
}

.infissLeftItem {
	background-color: #fff;
	margin-bottom: 15px;

	padding: 30px;
	border-radius: 5px;
	cursor: pointer;
}

.itemTitlw {
	font-size: 20px;
	letter-spacing: 1px;
	color: #000;


	.itemTitlwOne {
		width: 50px;
		height: auto;
		margin-right: 20px;
		vertical-align: middle;
	}

	.itemTitlwTwo {
		width: 55px;
		height: auto;
		margin-left: 10px;
		vertical-align: text-bottom;
	}
}

.conts {
	margin-top: 25px;
	font-size: 18px;
	letter-spacing: 1px;

	&-conceal {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.tupItem {

	margin-top: 20px;
	display: flex;
	flex-wrap: wrap;
	gap: 10px;

	.tupItemImg {
		.myImg {
			width: 175px;
			height: 175px;
			border-radius: 5px;
		}
	}
}

.itemTitlwThrees {
	width: 120px;
}

.dialog {
	// text-align: center;
	padding: 0 45px 30px 45px;

	.dialog-title {
		color: #000;
		font-size: 22px;
		font-weight: bold;
	}

	.dialog-input {
		margin-top: 40px;
	}

	.dialog-btn {
		margin-top: 30px;
		text-align: right;

		img {
			margin-left: 20px;
		}
	}

	.sumbitBox {
		width: 220px;
		height: 44px;
		cursor: pointer;
	}
}

.dialogBox {
	.title {
		font-size: 24px;
		font-weight: 500;
		text-align: center;
		color: #000;
	}

	.inputbox {

		width: 80%;

		margin: 0 auto;
		margin-top: 40px;
	}

	.input {

		border: none;
		outline: none;
		width: 100%;
		padding: 16px 10px;
		background-color: #F5F6F8;
		border-radius: 6px;
		box-shadow: 12.5px 12.5px 10px rgba(0, 0, 0, 0.015), 100px 100px 80px rgba(0, 0, 0, 0.03);
		margin-bottom: 20px;
	}


}

.onlineDetail-wrap {
	background-color: #FAFAFA;
	padding: 60px 310px;
	padding-top: 0px;

	.htmlCon {
		padding: 30px;
		background-color: #fff;
		margin-top: 30px;

	}

	.basicInfo {
		padding: 30px;
		background-color: #fff;
		display: flex;

		.basicInfo-left {
			width: calc(100% - 106px);

			.product-center-title {
				margin-top: 10px;
				font-size: 26px;
				color: #000;
				margin-bottom: 20px;
				font-weight: 500;
				letter-spacing: 1px;

				img {
					height: 28px;
					vertical-align: bottom;
				}
			}

			.company {
				margin-bottom: 10px;
				font-size: 18px;
				color: #6C6C6C;
			}
		}

		.basicInfo-right {
			img {
				width: 106px;
				height: 34px;
				margin-top: 67px;
				cursor: pointer;
			}
		}
	}
}

.sumbitBox1 {
	width: 110px;
	height: auto;
	cursor: pointer;
}

.chooseList {
	display: flex;
	gap: 10px;
	margin-top: 20px;
}

.yesChoose {
	padding: 6px 20px;
	background-color: #FFDBDF;
	border: 1px solid #F2ACB2;
	border-radius: 5px;
	color: #EA1423;
	cursor: pointer;
	font-size: 17px;
}

.noChoose {
	padding: 6px 20px;
	border: 1px solid #F3F3F3;
	background-color: #fff;
	border-radius: 5px;
	color: #333333;
	cursor: pointer;
	font-size: 17px;
}
</style>