/*
 * @Author: jiangjinchi 1468922694@qq.com
 * @Date: 2023-09-01 15:38:46
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2024-09-14 15:42:59
 * @FilePath: \商务厅PC\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import Vue from 'vue'
import Router from 'vue-router'
import mainIndex from '../view/mainIndex.vue'

import newsIndex from '../view/newsIndex.vue'
import open from '../view/open.vue'
import cooperation from '../view/hezuo.vue'
import consumption from '../view/consumption.vue'
import keyDevelopment from '../view/keyDevelopment.vue'

import importantAcitve from '../view/importantAcitve.vue'

import CadresAppearance from '../view/CadresAppearance.vue'
import loginPage from '../view/login/index.vue'
import sign from '../view/login/sign.vue'
import CulturalExchange from '../view/CulturalExchange.vue'
import approach from '../view/approach.vue'
import gallery from '../view/gallery.vue'
import exhibitionGuide from '../view/exhibitionGuide.vue'
import TourIntroduction from '../view/TourIntroduction.vue'
import WonderfulMomentPage from '../view/WonderfulMomentPage.vue'
import strategy from '../view/strategy.vue'

import cluster from '../view/cluster.vue'
import waitingPage from '../view/waitingPage.vue'
import CloudExhibition from '../view/CloudExhibition.vue'
import TimeMemory from '../view/TimeMemory.vue'
import nodifyPage from '../view/nodifyPage.vue'

import liaoQiaExpress from '../view/liaoQiaExpress/index.vue'
import liaoningSituation from '../view/liaoningSituation/index.vue'
import investLiaoning from '../view/investLiaoning/index.vue'
import emphasisDevelopmentZone from '../view/openLiaoning/emphasisDevelopmentZone.vue'
import pilotFreeTradeZone from '../view/openLiaoning/pilotFreeTradeZone.vue'
import ebay from '../view/openLiaoning/ebay.vue'
import previousAchievements from '../view/previousAchievements/index.vue'
import supplyPurchase from '../view/supplyPurchase/index.vue'
import consumptionLiaoning from '../view/consumptionLiaoning/index.vue'
import ImportantActivity from '../view/ImportantActivity/index.vue'
import NavigationPage from '../view/NavigationPage/index.vue'
import HighlightMoment from '../view/HighlightMoment/index.vue'
import detailpageOne from '../view/detailPage/detailpageOne.vue'
import AttractInvestment from '../view/AttractInvestment/index.vue'


import detailpageTwo from '../view/detailPage/detailpageTwo.vue'
import ProjectLibrary from '../view/ProjectLibrary/index.vue'
import KeyPoint from '../view/KeyPoint/index.vue'
import previousAchievementsDET from '../view/previousAchievements/detail.vue'
import liveVideo from '../view/WonderfulCom/liveVideo.vue'
import SubjectActivity from '../view/SubjectActivity/index.vue'
import ExhibitionService from '../view/ExhibitionService/index.vue'


import policy from '../view/policy/index.vue'
Vue.use(Router)
const VueRouterPush = Router.prototype.push
Router.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

//replace
const VueRouterReplace = Router.prototype.replace
Router.prototype.replace = function replace(to) {
  return VueRouterReplace.call(this, to).catch(err => err)
}

export default new Router({
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'mainIndex',
      component: mainIndex
    },
    {
      path: '/ExhibitionService',
      name: '展会服务',
      component: ExhibitionService
    },
    {
      path: '/liveVideo',
      name: '视频回顾',
      component: liveVideo
    },
    {
      path: '/SubjectActivity',
      name: '主体活动',
      component: SubjectActivity
    },
    {
      path: '/previousAchievementsDET',
      name: '详情',
      component: previousAchievementsDET
    },
    {
      path: '/KeyPoint',
      name: '详情',
      component: KeyPoint
    },
    {
      path: '/ProjectLibrary',
      name: '招商项目库',
      component: ProjectLibrary
    },
    {
      path: '/policy',
      name: '招商引资政策',
      component: policy
    },
    {
      path: '/AttractInvestment',
      name: '招商要闻',
      component: AttractInvestment
    },

    {
      path: '/detailpageOne',
      name: '详情',
      component: detailpageOne
    },
    {
      path: '/detailpageTwo',
      name: '详情',
      component: detailpageTwo
    },
    {
      path: '/HighlightMoment',
      name: '高光时刻',
      component: HighlightMoment
    },
    {
      path: '/ImportantActivity',
      name: '重要活动',
      component: ImportantActivity
    }, {
      path: '/NavigationPage',
      name: '辽洽导航',
      component: NavigationPage
    },
    {
      path: '/liaoQiaExpress',
      name: '辽洽快讯',
      component: liaoQiaExpress
    },
    {
      path: '/liaoningSituation',
      name: '辽宁省情',
      component: liaoningSituation
    },
    {
      path: '/investLiaoning',
      name: '投资辽宁',
      component: investLiaoning
    },
    {
      path: '/openLiaoning',
      name: '重点开发区',
      component: emphasisDevelopmentZone
    },
    {
      path: '/pilotFreeTradeZone',
      name: '自贸试验区',
      component: pilotFreeTradeZone
    },
    {
      path: '/ebay',
      name: '跨境电商',
      component: ebay
    },
    {
      path: '/previousAchievements',
      name: '历届成就',
      component: previousAchievements
    },
    {
      path: '/supplyPurchase',
      name: '供采对接',
      component: supplyPurchase
    },
    {
      path: '/consumptionLiaoning',
      name: '消费辽宁',
      component: consumptionLiaoning
    },
	
	
	
    {
      path: '/nodifyPage',
      name: '通知公告',
      component: nodifyPage
    },
    {
      path: '/WonderfulMomentPage',
      name: '精彩瞬间',
      component: WonderfulMomentPage
    },
    {
      path: '/TimeMemory',
      name: '时代记忆',
      component: TimeMemory
    },
    {
      path: '/strategy',
      name: '那曲消费',
      component: strategy
    },
    {
      path: '/newsIndex',
      name: '新闻资讯',
      component: newsIndex
    },
    {
      path: '/open',
      name: '项目援藏',
      component: open
    },
    {
      path: '/cooperation',
      name: '投资合作',
      component: cooperation
    },
    {
      path: '/consumption',
      name: '产品展览',
      component: consumption
    },
    {
      path: '/keyDevelopment',
      name: '援藏风采',
      component: keyDevelopment
    },
    {
      path: '/CloudExhibition',
      name: '云展厅',
      component: CloudExhibition
    },
    {
      path: '/cluster',
      name: '“组团式”援藏',
      component: cluster
    },
    {
      path: '/waitingPage',
      name: '藏区综援',
      component: waitingPage
    },
   
   
    {
      path: '/importantAcitve',
      name: 'importantAcitve',
      component: importantAcitve
    },
  
    {
      path: '/TourIntroduction',
      name: '那曲文旅',
      component: TourIntroduction
    },
    {
      path: '/CadresAppearance',
      name: '干部风采',
      component: CadresAppearance
    },
    {
      path: '/loginPage',
      name: 'loginPage',
      component: loginPage
    },
    {
      path: '/sign',
      name: 'sign',
      component: sign
    },
    {
      path: '/CulturalExchange',
      name: '文化旅游',
      component: CulturalExchange
    },
    {
      path: '/approach',
      name: '走进那曲',
      component: approach
    },
    {
      path: '/gallery',
      name: '工作综述',
      component: gallery
    },
    {
      path: '/exhibitionGuide',
      name: 'exhibitionGuide',
      component: exhibitionGuide
    },

  ]
});
