<!--
 * @Author: jiangjinchi 1468922694@qq.com
 * @Date: 2024-09-12 17:11:48
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2024-09-17 18:42:00
 * @FilePath: \pc\src\view\ImportantActivity\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="body">
         <div class="FixedContentStyle">
            <FixedContent :titleJumpList="titleJumpList" @jumpUrl="jumpUrl"></FixedContent>
         </div>
        <img src="../../assets/重要活动/重要活动-占位图.png" alt="">
    </div>
</template>

<script>
export default {
    data() {
        return {
            titleJumpList: [
                {
                    url: "ExhibitionService",
                    name: "展会服务"

                }, {
                    url: "ImportantActivity",
                    name: "重点活动"

                },
            ]

        }
    },
    mounted() { },
    methods: {
        jumpUrl(e) {
            this.$emit('jumpUrl', e)
        },
    }

}
</script>

<style lang="scss" scoped>
.body {
    padding: 20px 280px;
    // background-color: #fff;

    img {
        width: 100%;
        height: auto;
    }
}
.FixedContentStyle {
    margin-left: 20px;
    margin: 20px;
}
</style>
