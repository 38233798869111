<!--
 * @Author: jiangjinchi 1468922694@qq.com
 * @Date: 2023-09-07 19:10:06
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2024-07-23 09:52:25
 * @FilePath: \department-of-commerce\PC\src\view\components\openDetails.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="openDetails-wrap">
        <div class="content">
            <el-row class="rich-text htmlContent">

                <div v-if="!needShowDetail">
                    <div v-if="typeName != '西藏网红餐厅'">
                        <div v-if="infoData.banner">
                            <el-image  class="banner-img" :src="infoData.banner" :preview-src-list="[infoData.banner]"></el-image>
                          
                        </div>
                        <div class="title">
                            <div class="title-name">{{ infoData.coverName }}</div>
                            <div class="adress-sign">
                                <img src="../../assets/首页切图/旅游景点-标签.png" alt="">
                                <div class="ranking">
                                    旅游推荐NO.{{ infoData.recommendedRanking }}
                                </div>
                            </div>
                        </div>
                        <div class="open-time">
                            开放时间:{{ infoData.openingHours }}
                        </div>
                        <div class="map-box">
                            <div>{{ infoData.location }}</div>
                            <div style="color: #F86D16;"> <img src="../../assets/首页切图/地图.png" alt="">地图</div>

                        </div>
                        <div>
                            <div class="small-tit"><img src="../../assets/首页切图/标题点缀-左.png" alt="">景点介绍</div>

                            <div class="introBox">
                                {{ infoData.introductionScenicSpots }}
                            </div>
                        </div>
                        <div>
                            <div class="small-tit"><img src="../../assets/首页切图/标题点缀-左.png" alt="">特色介绍</div>
                            <div class="store">
                                <div class="storeItem" v-for="(i, ind) in infoData.specialList" :key="ind + 'n'"
                                    @click="clickStore(i.id)">
                                    <div class="stoPics">
                                        <el-image  lazy class="wall-detail" :src="i.coverImage"></el-image>
                                    </div>
                                    <div class="content">
                                        <div class="contentName">
                                            {{ i.specialName }}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div style="display: flex;">
                            <div class="imgs2" v-if="infoData.picture">
                                <el-image class="imgsss" :src="infoData.picture" :preview-src-list="[infoData.picture]"></el-image>
                 
                            </div>
                            <div style="width: 100%;">
                                <div class="title">
                                    <div class="title-name">{{ infoData.restaurantName }}</div>
                                    <div class="adress-sign">
                                        <img src="../../assets/首页切图/旅游景点-标签.png" alt="">
                                        <div class="ranking">
                                            旅游推荐NO.{{ infoData.recommendedRanking }}
                                        </div>
                                    </div>
                                </div>

                                <div class="open-time">
                                    营业时间:{{ infoData.businessHours }}
                                </div>
                                <div class="map-box">
                                    <div>{{ infoData.location }}</div>
                                    <div style="color: #F86D16;"> <img src="../../assets/首页切图/地图.png" alt="">地图</div>

                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="small-tit"><img src="../../assets/首页切图/标题点缀-左.png" alt="">餐厅简介</div>

                             <div class="introBox">
                                {{ infoData.briefIntroduction }}
                             </div>

                        </div>
                        <div>
                            <div class="small-tit"><img src="../../assets/首页切图/标题点缀-左.png" alt="">推荐菜品</div>
                            <div class="store">
                                <div class="storeItem" v-for="(item, index) in infoData.restaurantSpecials"
                                    :key="index + 'n'" @click="clickStore(item.id)">
                                    <div class="stoPics">
                                        <el-image  lazy class="wall-detail" :src="item.coverImage">
                                            <div slot="placeholder" class="image-slot">
                                                <img src="@/assets/首页切图/缺省.png" alt="">
                                            </div>
                                        </el-image>
                                    </div>
                                    <div class="content">
                                        <div class="contentName">
                                            {{ item.restaurantName }}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="chatRef">
                        <chat :id="id" :chatType="typeName == '西藏网红餐厅' ? 'restaurant' : 'scenicSpot'"
                            :fatherScrollHeigh="fatherScrollHeigh"></chat>
                    </div>
                </div>

                <detailStyleOne v-else :idDetails="idDetails" :typeName="typeName"></detailStyleOne>
            </el-row>
        </div>
        <elImageViewer v-if="dialogVisible" :on-close="closeImage" :url-list="[url]" />
    </div>
</template>

<script>

import detailStyleOne from '../../components/detailStyleOne.vue'
export default {


    props: {
        id: {},
        typeName: {},
        only: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            infoData: {

            },
            url: '',
            dialogVisible: false,
            playerOptionsList: [],
            needShowDetail: false,
            idDetails: '',
            fatherScrollHeigh: ''
        }
    },
    components: {
        detailStyleOne
    },
    mounted() {
        this.details();
    },
    methods: {
        showImage(e) {
            if (e.target.tagName == 'IMG') {

                this.url = e.target.src
                this.dialogVisible = true
            }
        },
        closeImage() {
            this.dialogVisible = false
            this.url = '';
        },
        clickStore(e) {
            this.$emit('needShowDetail', '详情')
            this.idDetails = e;
            this.$nextTick(() => {
                document.documentElement.scrollTop = 200;
            });
            this.needShowDetail = true;
        },
        details() {
            if (this.typeName == '西藏网红餐厅') {
                this.$baseRequest('/not/restaurant/details?info=' + this.id, {}, 'get').then(res => {
                    this.infoData = res.data;
                    if (this.infoData.briefIntroduction) {
                        this.infoData.briefIntroduction = this.infoData.briefIntroduction.replace(/<iframe/g, "<video controls style='width:100%;height:auto;'")
                    }

                })
            } else {
                this.$baseRequest('/scenic/spot/details?info=' + this.id, {}, 'get').then(res => {
                    this.infoData = res.data;
                    if (this.infoData.introductionScenicSpots) {
                        this.infoData.introductionScenicSpots = this.infoData.introductionScenicSpots.replace(/<iframe/g, "<video controls style='width:100%;height:auto;'")
                    }

                })
            };
            this.$nextTick(res => {
                this.fatherScrollHeigh = document.getElementById('chatRef').offsetTop;
            })

        },
    }
}
</script>

<style src="@/style/element-ui-class.css"></style>

<style scoped lang="scss">
.imgs2 {
    .imgsss {
        width: 120px;
        height: 120px;
        border-radius: 5px;
        margin-right: 10px;
    }
}

.small-tit {
    font-size: 19px;
    color: #000;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 10px;

    img {
        width: 25px;
        height: auto;
        vertical-align: bottom;
        margin-right: 5px
    }
}

.title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .title-name {
        font-size: 30px;
        letter-spacing: 1px;
        color: #000;
    }

}

.map-box {
    background-color: #F3F8FF;
    padding: 5px 15px;
    margin-bottom: 10px;
    display: flex;
    font-size: 17px;
    justify-content: space-between;

    img {
        width: 15px;
        height: auto;
        vertical-align: middle;
        margin-right: 6px;
    }
}

.open-time {
    margin: 10px 0px;
    font-size: 17px;
}

.adress-sign {
    position: relative;
    margin-top: 4px;
    margin-left: 20px;

    img {
        width: 120px;
        height: auto;
    }

    .ranking {
        position: absolute;
        left: 4px;
        top: 1px;
        font-size: 16px;
        color: #8F694D;
    }
}

.store {
    margin-top: 40px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;

    .storeItem {
        width: 30%;
        background-color: #fff;
        border-radius: 10px;

        cursor: pointer;

        .stoPics {
            width: 100%;
            height: 280px;
            overflow: hidden;
            margin-bottom: 0px;
            border-radius: 10px 10px 0px 0px;

            .wall-detail {
                width: 100%;
                height: 280px;
            }

            .image-slot {
                img {
                    width: 100%;
                    height: 280px;
                }

            }
        }

        img {
            display: block;
            width: 100%;
        }

        .content {

            padding: 20px 20px;
            border-radius: 0px 0px 10px 10px;
            
        }

        .contentName {
            font-size: 22px;

            color: #000;
            margin-bottom: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            img {
                width: 80px;
            }
        }

        .adress {
            display: flex;
            align-items: center;
            color: #6C6C6C;

            font-size: 17px;

            .adress-sign {
                position: relative;

                img {
                    width: 120px;
                    height: auto;
                }

                .ranking {
                    position: absolute;
                    left: 4px;
                    top: 1px;
                    font-size: 16px;
                    color: #8F694D;
                }
            }

            .address-name {
                width: calc(100% - 120px);
                margin-left: 10px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}

.store :last-child {
    margin-right: 0px;
}

.openDetails-wrap {
    

    .content {
        padding: 0px 100px;
        // padding: 60px 310px;

        .breadcrumb {
			height: 15px;
			display: flex;
			align-items: center;
			line-height: 15px;
			color: #8389a6;
			margin-bottom: 20px;
			font-size: 19px;

			div {
				display: flex;
				align-items: center;

				img {
					width: 18px;
					height: auto;
					margin-right: 10px;
				}
			}

			i {
				margin: 0 5px;
			}
		}


        .rich-text {
            margin-top: 20px;

            padding: 50px 80px;
        }
    }
}

.carousel {
    width: 100%;
    height: 457px;
}

.videoBox {
    margin-bottom: 30px;
}

.cityBox {
    font-size: 28px;
    margin-bottom: 30px;
    color: #000;

}
.banner-img {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
}
.introBox {
    font-size: 19px;
}
</style>