<!--
 * @Author: jiangjinchi 1468922694@qq.com
 * @Date: 2024-09-13 09:05:28
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2024-09-18 16:17:19
 * @FilePath: \pc\src\view\detailPage\detailpageOne.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="body">
        <div class="bodyTitle">
            <div>
                <img @click="goTo('ImportantActivity')" src="../../assets/展会服务/重要活动.png" alt="">
            </div>
            <div>
                <img @click="goTo('exhibitionInfo')" src="../../assets/展会服务/展会概况.png" alt="">
            </div>
            <div>
                <img @click="clickShowDialog" src="../../assets/展会服务/证件查询.png" alt="">
            </div>
            <div>
                <img @click="goTo('exhibitionGuide')" src="../../assets/展会服务/酒店推荐.png" alt="">
            </div>
        </div>
        <div class="title">

            <img src="../../assets/辽宁省情/标题装饰.png" />
            <span>展商服务</span>

        </div>
        <div class="flexitem">
            <div class="flexitemleft">
                <div class="list" :class="cityFage == item.name ? 'clickyes' : 'noclick'"
                    v-for="(item, index) in cityList" :key="item.name" @click="cityClick(item.name)">
                    <div class="titlep">{{ item.name }}</div>
                    <div class="img">
                        <img src="@/assets/内容页切图/城市导航-箭头-白.png" v-if="cityFage == item.name" />
                        <img src="@/assets/内容页切图/城市导航-箭头-灰.png" v-else />
                    </div>
                </div>
            </div>
            <div class="flexitemright">

                <div>
                    <el-image class="imgStyle" :src="cityList.find(i => i.name == cityFage).url"
                        :preview-src-list="[cityList.find(i => i.name == cityFage).url]"></el-image>

                </div>
            </div>

        </div>

    </div>
</template>

<script>
export default {
    props: ["query"],
    data() {
        return {
            cityUrl: '',
            // cityList: ['展会日程', '报道及撤展需求', '证件管理', '布展撤展路线', '特装申报', '租赁服务'],
            cityFage: "展会日程",
            cityList: [{
                name: '展会日程',
                url: require("@/assets/展会服务/展会服务-展会基本信息.png"),
            }, {
                name: '报道及撤展需求',
                url: require("@/assets/展会服务/展会服务-布、撤展须知.png"),
            }, {
                name: '证件管理',
                url: require("@/assets/展会服务/展会服务-证件使用介绍.png"),
            }, {
                name: '布展撤展路线',
                url: require("@/assets/展会服务/展会服务-布展撤展路线.png"),
            }, {
                name: '特装申报',
                url: require("@/assets/展会服务/展会服务-特装申报.png"),
            }, {
                name: '租赁服务',
                url: require("@/assets/展会服务/展会服务-租赁服务.png"),
            },],

        }
    },
    mounted() {
        // this.query = this.query == '展会概况' ? '展会日程' : this.query
        this.cityFage = this.query || '展会日程';
      
    },
    methods: {
        clickShowDialog() {

            this.$emit('showDialogNeed')

        },
        cityClick(e) {
            this.cityFage = e;

        },
        //跳转 
        goTo(url) {
            this.$emit('jumpUrl', url)
        },
    }

}
</script>
<style>
.el-input-group__append {
    color: #3F75F4;
    cursor: pointer;
}
</style>
<style lang="scss" scoped>
.body {
    padding: 60px 280px;



}

.bodyTitle {
    display: flex;
    justify-content: space-between;

    div {
        width: 23%;

        img {
            width: 100%;
            cursor: pointer;
        }
    }
}

.title {
    margin-top: 20px;
    font-family: 'SourceHanSansSC-Regular';
    font-size: 26px;
    color: #000;
    margin-bottom: 20px;

    img {
        width: 20px;
        height: auto;
    }

    span {
        margin: 0px 10px;
    }
}

.flexitem {
    display: flex;
}

.flexitemleft {
    width: 25vw;
    padding: 20px;
    background-color: #fff;
}

.flexitemright {
    padding: 20px;
    width: 75vw;

    margin-left: 40px;
    background-color: #fff;
}

.list {
    height: 60px;
    border-radius: 8px;
    padding: 0 25px;
    display: flex;
    cursor: pointer;

    .titlep {

        font-size: 19px;
        line-height: 60px;
        display: inline-block;
    }

    .img {
        float: right;
        margin: auto 0px auto auto;

        img {
            width: 10px;
        }
    }
}

.clickyes {
    background-color: #EA0E1E;
    color: #fff;
}

.noclick {
    color: #333333;
}

.imgStyle {
    width: 100%;
}
</style>