<template>
    <div class="TimeMemory">
        <el-row>
            <img class="banner-unify" src="../assets/内容页切图/顶部图-时代记忆.png" alt="">
        </el-row>

        <div class="wrap-Row" style="position: relative;" id="nav">
            <!-- <div class="line-big">

            </div> -->
            <div>
                <div class="row row1">
                    <div class="item" v-for="(item, index) in List.list1" :key="index" @click="clickItem(item)">
                        <div class="item-dot"></div>
                        <div>
                            <div class="time">
                                {{ item.pictureDate }}
                            </div>
                            <div class="name">
                                {{ item.pictureTitle }}
                            </div>
                            <div>
                                <img :src="item.picture_photo" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="line"></div>
                <div class="row row2">
                    <div class="item" v-for="(item, index) in List.list2" :key="index" @click="clickItem(item)">
                        <div class="item-dot2"></div>
                        <div>
                            <div class="time">
                                {{ item.pictureDate }}
                            </div>
                            <div class="name">
                                {{ item.pictureTitle }}
                            </div>
                            <div>
                                <img :src="item.picture_photo" alt="">
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <el-dialog v-if="dialogVisible" title="详情" :visible.sync="dialogVisible" width="80%" class="dialogClass">
            <div>
                <div class="time">
                    {{ itemList.pictureDate }}
                </div>
                <div class="name">
                    {{ itemList.pictureTitle }}
                </div>
                <div class="div-flex">
                    <div v-for="(i, ind) in itemList.list" class="div-item">
                        <!-- <img class="div-img" :src="i.picturePhoto" alt=""> -->
                        <el-image lazy class="div-img" :src="i.picturePhoto"
                            :preview-src-list="itemList.list.map(b => { return b.picturePhoto })">
                            <div slot="placeholder" class="image-slot">
                                <img src="@/assets/首页切图/缺省.png" alt="">
                            </div>
                        </el-image>
                    </div>
                </div>
            </div>
            <!-- <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span> -->
        </el-dialog>
    </div>
</template>

<script>


export default {

    data() {
        return {
            dialogVisible: false,
            List: {},
            itemList: ''
        }
    },
    mounted() {
        this.getList()
        this.scrollInit();
    },
    methods: {
        clickItem(item) {
            this.dialogVisible = true;
            this.itemList = item;
          
        },
        getList() {
            this.$baseRequestGet('/maintain/picture/findGroupingMap?info=' + '时代记忆', {}, 'get').then(res => {
                this.List = res.data;
               
            })
        },
        scrollInit() {
            // 获取要绑定事件的元素
            const nav = document.getElementById("nav")
            var flag; // 鼠标按下
            var downX; // 鼠标点击的x下标
            var scrollLeft; // 当前元素滚动条的偏移量
            nav.addEventListener("mousedown", function (event) {
                flag = true;
                downX = event.clientX; // 获取到点击的x下标
                scrollLeft = this.scrollLeft; // 获取当前元素滚动条的偏移量
            });
            nav.addEventListener("mousemove", function (event) {
                if (flag) { // 判断是否是鼠标按下滚动元素区域
                    var moveX = event.clientX; // 获取移动的x轴
                    var scrollX = moveX - downX; // 当前移动的x轴下标减去刚点击下去的x轴下标得到鼠标滑动距离
                    this.scrollLeft = scrollLeft - scrollX // 鼠标按下的滚动条偏移量减去当前鼠标的滑动距离

                }
            });
            // 鼠标抬起停止拖动
            nav.addEventListener("mouseup", function () {
                flag = false;
            });
            // 鼠标离开元素停止拖动
            nav.addEventListener("mouseleave", function (event) {
                flag = false;
            });

        },
        stopRoute() {

        }
    }
}
</script>
<style scoped lang="scss">
.TimeMemory ::-webkit-scrollbar {
    display: inline;
}


.row {
    display: flex;
    justify-content: flex-start;

}

.row1 {
    margin-left: 40px;
}

.row2 {
    margin-left: 300px;
}

.line {
    width: 100%;
    height: 3px;
    background-color: #DFE0E5;
    margin-top: 1px;
}

.wrap-Row {
    margin-top: 40px;
    width: 80%;
    display: flex;
    text-align: center;
    overflow-x: scroll;
    font-size: 20px;
    .line-big {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
        left: 135px;
        top: 275px;
        background-color: #F7701A;
    }

    .item {
        width: 300px;
        flex-shrink: 0;
        border-left: 4px solid #F7701A;
        padding: 0px 0px 40px 20px;
        position: relative;
        cursor: pointer;

        .item-dot {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            position: absolute;
            bottom: -8px;
            left: -6px;

            background-color: #F7701A;
        }

        .item-dot2 {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            position: absolute;
            top: -8px;
            left: -6px;

            background-color: #F7701A;
        }
    }

    .hingelis {
        content: '';
        height: 280px;
        position: absolute;
        right: 144px;

        border-right: 4px solid #DFE0E5;
        border-radius: 0 50px 50px 0;
    }

    .hingelis-left {
        content: '';
        height: 280px;
        position: absolute;
        left: 144px;

        border-left: 4px solid #DFE0E5;
        border-radius: 50px 0 0 50px;
    }

    .item:last-child {}

    img {
        width: 200px;
        height: 150px;
        border-radius: 5px;
        margin-bottom: 20px;
    }

    .time {
        color: #F7701A;
        font-size: 20px;

    }

    .name {
        margin: 10px 0px;
    }

    padding: 30px 150px;
}

.dialogClass {
    .time {
        color: #F7701A;
        font-size: 20px;

    }

    .name {
        margin: 10px 0px;
        font-size: 18px;
        position: relative;
        padding-left: 20px;
    }

    .name::before {
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        top: 5px;
        left: 0px;
        background-color: #F7701A;
    }

    .div-item {
        width: 200px;

        .div-img {
            width: 200px;
            height: 150px;
        }
    }
    .image-slot {
        width: 200px;
        height: 150px;
    }
    .div-flex {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
    }
}
</style>