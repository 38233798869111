<!--
 * @Author: jiangjinchi 1468922694@qq.com
 * @Date: 2023-09-07 15:55:13
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2024-09-17 14:25:23
 * @FilePath: \department-of-commerce\PC\src\view\pilotSite.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="importantAcitve-main">
        <div class="FixedContentStyle">
                <FixedContent :titleJumpList="titleJumpList" @jumpUrl="goTo"></FixedContent>
            </div>
        <div class="wie">

            <div class="broadcast" :infinite-scroll-immediate="false" :infinite-scroll-disabled="loading"
                v-infinite-scroll="loadMore" infinite-scroll-distance="1">
                <div class="pic-wall" v-for="(item, index) in videoFirst">
                    <div class="wall-detail">
                        <videoPlayer class="video-player vjs-custom-skin videoSize" ref="videoPlayer"
                            :playsinline="true" :options="playerOptionsList[index]">
                        </videoPlayer>
                    </div>
                    <div class="wall-name">
                        {{ item.spare2 }}
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import { videoPlayer } from 'vue-video-player'
export default {
    components: {
        videoPlayer,

    },
    data() {
        return {
            titleJumpList: [
                {
                    url: "HighlightMoment",
                    name: "高光时刻"

                }, {
                    url: "",
                    name: "高光时刻"

                }
            ],
            loading: false,
            count: 20,
            playerOptionsList: [

            ],
            videoFirst: [],
            beginNum: 1,
            endNum: 10,
            isNoPage: false,
            type: '',
            temNum: 10

        }
    },
    mounted() {
        this.endNum = this.temNum;
        this.getVideoFirst();
        if (this.$route.query.type) {
            this.type = this.$route.query.type;
        }
    },
    methods: {
        goTo(url, query) {
            this.$emit('jumpUrl', url, query);
        },
        loadMore() {
            this.loading = true;
            if (this.isNoPage) return;
            this.beginNum = this.beginNum + 1
          
            this.getVideoFirst().then(res => {
                this.loading = false;
            })

        },
        // 资讯视频
        getVideoFirst() {
            return new Promise(resolve => {
                this.$baseRequest('/maintain/video/pageList', { beginNum: this.beginNum, endNum: 10 }, 'post').then(res => {
                    if (res.data.length == 0 || res.data.records.length < 10) {
                        this.isNoPage = true;
                    }
                    this.videoFirst = [...this.videoFirst, ...res.data.records];
                    this.videoFirst.forEach((item, index) => {
                        if (!this.playerOptionsList.find(i => i.id == item.id)) {
                            this.playerOptionsList.push(
                                {
                                    id: item.id,
                                    playbackRates: [], // 可选的播放速度
                                    autoplay: false, // 如果为true,浏览器准备好时开始回放。
                                    muted: false, // 默认情况下将会消除任何音频。
                                    loop: false, // 是否视频一结束就重新开始。
                                    preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                                    language: 'zh-CN',
                                    aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                                    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                                    sources: [{
                                        type: "video/mp4", // 类型
                                        src: item.videoPhoto
                                    }],
                                    // poster: require('@/assets/首页切图/10投资合作-大连-宽.png'), // 封面地址
                                    notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                                    controlBar: {
                                        timeDivider: false, // 当前时间和持续时间的分隔符
                                        durationDisplay: false, // 显示持续时间
                                        remainingTimeDisplay: false, // 是否显示剩余时间功能
                                        fullscreenToggle: true // 是否显示全屏按钮
                                    }
                                }
                            )
                        }



                    })

                    resolve()
                })
            })

        },
    }
}
</script>

<style src="../../style/viedo.css"></style>
<style scoped lang="scss">
.broadcast {
    margin-top: 20px;
    background-color: #fff;
    border-radius: 3px;
    padding: 50px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    height: 750px;
    overflow: auto;

}

.pic-wall {
    width: 370px;
    margin-right: 20px;
    margin-bottom: 20px;

    .wall-detail {
        width: 100%;
        // height: 175px;

    }

    .wall-name {
        width: calc(100% - 40px);
        text-align: center;
        padding: 15px 20px;
        font-size: 16px;
        color: #000;
        border-radius: 0px 0px 5px 5px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }



}

.importantAcitve-main {

    padding: 20px 280px;
    

    .mainPage {

        .bac {

            z-index: 99;

            img {
                width: 100%;
                height: auto;
            }
        }



        .mainPageTime {
            // padding: 20px 280px;

            .broadcast {
                width: calc(100vw - 750px);
                background-color: #fff;
                border-radius: 3px;
                padding: 50px;
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                height: 750px;
                overflow: auto;
                // background-color: pink;
            }
        }
    }



}

.wie {
 
}
</style>