<template>
	<div class="body">
		<el-row class="title">
			<img src="../../assets/辽宁省情/标题装饰.png" />
			<span>辽宁省概况</span>
		</el-row>
		<el-row>
			<el-col :span="12">
				<div class="situation-province">
					<videoPlayer class="video-player vjs-custom-skin videoSize" ref="videoPlayer" :playsinline="true"
						:options="videoPlayerOptions">
					</videoPlayer>
					<div class="situation-province-content" v-html="liaoningOverview.content1">
						<!-- <div class="situation-province-content" v-html="liaoningOverview.content"
							@click="goTo('detailpageOne', liaoningOverview)"> -->
					</div>
					<div class="situation-province-content" v-html="liaoningOverview.content2">
					</div>
				</div>
			</el-col>
			<el-col :span="12">
				<div class="situation-city">
					<el-row>
						<el-col
							:span="index == marketInformation.length - 1 && marketInformation.length % 2 != 0 ? 24 : 12"
							v-for="(item, index) in marketInformation" :key="index" class="item">
							<div @click="goTo('detailpageOne', item)">
								<div>
									<img :src="item.img" />
									<div class="text">
										{{ item.title }}
									</div>
								</div>
								<img src="../../assets/导航箭头-灰.png" />
							</div>
						</el-col>
					</el-row>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import {
	videoPlayer
} from 'vue-video-player'
export default {
	components: {
		videoPlayer,
	},
	data() {
		return {
			params: {
				cityName: "",
			},
			liaoningOverview: {
				video: "https://2024lqh.oss-cn-beijing.aliyuncs.com/LQH-gys/欢迎您到辽宁来_20240916_17060160_20240916_17093419.mp4",
				img: require("@/assets/测试图片/3.png"),
				title: "辽宁省概况",
				content1: "辽宁位于我国东北地区南部,土地面积14.8万平方公里,海岸线2920公里,与日本、韩国、朝鲜、俄罗斯、蒙古国相邻或相近,是中国东北地区唯-的沿海省份和出海大通道,是“一带一路”建设向北开放的重要窗口,是中国对接东北亚、沟通欧亚大陆桥的重要陆海门户和前沿地带,在环渤海经济圈中具有重要地位。辽宁产业基础扎实,在国民经济行业的41个工业大类中拥有40个,数控机床、工业机器人,石油化工,钢铁等在国家产业在局中占有重要位置。辽宁科教资源丰富,拥有科研机构1652家、高等院校114所、国家重点实验室14家,辽宁以启动辽宁材料实验室建设为牵引,后步推进辽河实验室、黄海实验室、滨海实验室建设,更好发挥创新对高质量发展的支撑引领作用。辽宁省共转14个地级市,省会是沈阳市,沈阳、大连为副省级城市。近年来,辽宁以优化营商环境为基础全面深化改革,2022年上半年全省市场主体总量达到458,4万户,同比增长9.1%,社会各界对于辽宁振兴发展的预期更好、意愿更强、信心更足。",
				content2: "辽宁位于我国东北地区南部， 南邻黄海、渤海， 大陆海岸线全长2292 .4 公里。辽宁地处东北亚中心地带，与朝鲜、韩国、日本、俄罗斯、蒙古相邻，与京津冀、环渤海经济圈地缘相近，是“一带一路”建设向北开放的重要窗口，是东北地区唯一的沿海省份和出海大通道，是我国对接东北亚、沟通欧亚大陆桥的重要陆海门户和前沿地带，在环渤海经济圈中具有重要地位。"
			}, //辽宁省概况
			marketInformation: [
				// 	{
				// 	img: require("@/assets/测试图片/4.png"),
				// 	title: "沈阳市",
				// 	content: "沈阳是国家历史文化名城，清朝发祥地，素有“一朝发祥地，两代帝王都”之称。明天启五年(1625年),阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴"
				// }, {
				// 	img: require("@/assets/测试图片/4.png"),
				// 	title: "沈阳市",
				// 	content: "沈阳是国家历史文化名城，清朝发祥地，素有“一朝发祥地，两代帝王都”之称。明天启五年(1625年),阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴"
				// }, {
				// 	img: require("@/assets/测试图片/4.png"),
				// 	title: "沈阳市",
				// 	content: "沈阳是国家历史文化名城，清朝发祥地，素有“一朝发祥地，两代帝王都”之称。明天启五年(1625年),阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴"
				// }, {
				// 	img: require("@/assets/测试图片/4.png"),
				// 	title: "沈阳市",
				// 	content: "沈阳是国家历史文化名城，清朝发祥地，素有“一朝发祥地，两代帝王都”之称。明天启五年(1625年),阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴"
				// }, {
				// 	img: require("@/assets/测试图片/4.png"),
				// 	title: "沈阳市",
				// 	content: "沈阳是国家历史文化名城，清朝发祥地，素有“一朝发祥地，两代帝王都”之称。明天启五年(1625年),阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴"
				// }, {
				// 	img: require("@/assets/测试图片/4.png"),
				// 	title: "沈阳市",
				// 	content: "沈阳是国家历史文化名城，清朝发祥地，素有“一朝发祥地，两代帝王都”之称。明天启五年(1625年),阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴"
				// }, 
			], //市情介绍
			timer: null, //定时器
			videoPlayerOptions: {
				id: 1,
				playbackRates: [], // 可选的播放速度
				autoplay: false, // 如果为true,浏览器准备好时开始回放。
				muted: false, // 默认情况下将会消除任何音频。
				loop: false, // 是否视频一结束就重新开始。
				preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
				language: 'zh-CN',
				aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
				fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
				sources: [{
					type: "", // 类型
					src: ""
				}],
				// poster: require('@/assets/首页切图/10投资合作-大连-宽.png'), // 封面地址
				notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
				controlBar: {
					timeDivider: false, // 当前时间和持续时间的分隔符
					durationDisplay: false, // 显示持续时间
					remainingTimeDisplay: false, // 是否显示剩余时间功能
					fullscreenToggle: true // 是否显示全屏按钮
				}
			}
		}
	},
	mounted() {
		this.getMarketInformation();
	},
	methods: {
		//获取市情
		getMarketInformation() {
			this.$baseRequest("/maintain/journalism/findList", {
				journalismCategory: "辽宁省情",
				journalismSecondaryClassification: "市情概况",
				journalismTitle: this.params.cityName
			}, "post").then(res => {
				this.marketInformation = [];
				res.data.forEach(item => {
					this.marketInformation.push({
						id: item.id,
						img: item.journalismPhoto,
						title: item.journalismTitle,
						content: item.spare1
					})
				})
			})
		},
		//城市查询
		cityNameInput(val) {
			if (this.timer) {
				clearTimeout(this.timer)
			}
			this.timer = setTimeout(() => {
				this.getMarketInformation();
			}, 1000)
		},
		//跳转 
		goTo(url, item) {
			console.log(item);
			this.$emit('jumpUrl', url, {
				id: item.id
			})
		},
	},
	watch: {
		"liaoningOverview.video": {
			handler: function (newVal, oldVal) {
				console.log(newVal);
				this.videoPlayerOptions.sources[0].type = "video/" + newVal.split(".").pop();
				this.videoPlayerOptions.sources[0].src = newVal;
			},
			immediate: true
		}
	}
}
</script>

<style lang="scss" scoped>
.body {
	padding: 30px 280px;
	// background-image: url("../../assets/辽宁省情/辽宁省情-bg.png");
	// background-size: cover;
	// background-repeat: no-repeat;
	// background-position: center;

	.title {
		margin-bottom: 13px;

		img {
			width: 23px;
			height: 25px;
		}

		>span {
			margin-left: 7px;
			font-size: 33px;
			font-family: 'DingTalk_JinBuTi_Regular';
		}
	}

	.situation {
		&-province {
			width: 90%;

			.situation-province-content {
				text-indent: 35px;
				background-color: #fbf8ef;
				margin-top: 10px;
				padding: 15px 30px;
				font-size: 16px;
				font-family: "SourceHanSansSC-Regular";
				border-radius: 10px;
				line-height: 25px;
			}
		}

		&-city {
			.item {
				margin-bottom: 20px;
				padding-right: 20px;

				>div {
					cursor: pointer;
					background-color: #fff;
					height: 88px;
					border-radius: 10px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 0px 29px;

					>div {
						display: flex;
						align-items: center;
						font-size: 20px;
						font-family: "思源黑体CN-Bold";

						>img {
							width: 60px;
							height: 60px;
							border-radius: calc(60px / 2);
							margin-right: 16px;
						}
					}


				}
			}
		}
	}
}
</style>