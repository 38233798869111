<template>
	<div class="body">
		<el-row :gutter="25">
			<el-col :span="8" v-for="(item, index) in attractInvestment" :key="index">
				<img @click="goTo(item.url,item.name)" class="tupyangshi" :src="item.img" style="cursor: pointer;" />
			</el-col>
		</el-row>
		<el-row class="title margin">
			<img src="../../assets/辽宁省情/标题装饰.png" />
			<span>招商要闻</span>
			<div class="view-more" @click="goTo('AttractInvestment', '招商要闻')">
				查看更多
				<img src="../../assets/投资辽宁/查看更多箭头.png" />
			</div>
		</el-row>
		<el-row class="content">
			<el-row>
				<el-col :span="12" class="content-img">
					<img :src="attractImportantNews[imgIndex].img" />
					<div class="content-img-title">
						{{ attractImportantNews[imgIndex].title }}
					</div>
				</el-col>
				<el-col :span="12">
					<div class="text" v-for="(item, index) in attractImportantNews" :key="index" @click="cutImg(index)">
						<div class="text-title">{{ item.title }}</div>
						<div class="text-date">{{ item.date }}</div>
					</div>
				</el-col>
			</el-row>
		</el-row>
	</div>
</template>

<script>
export default {
	data() {
		return {
			params: {
				cityName: "",
			},
			attractInvestment: [{
				img: require("@/assets/投资辽宁/投资辽宁-招商项目库.png"),
				url: "ProjectLibrary",
				name: ""
			}, {
				img: require("@/assets/投资辽宁/投资辽宁-招商项目成果.png"),
				url: "AttractInvestment",
				name: "招商项目成果"
			}, {
				img: require("@/assets/投资辽宁/投资辽宁-招商引资政策.png"),
				url: "policy",
				name: ""
			}], //招商
			attractImportantNews: [{
				img: require("@/assets/测试图片/5.png"),
				title: "沈阳临空经济区“区办园企”产业链招商模式成功实施1111111111111111111111111111111111",
				date: "2023-09-25"
			}, {
				img: require("@/assets/测试图片/5.png"),
				title: "沈阳临空经济区“区办园企”产业链招商模式成功实施2",
				date: "2023-09-25"
			}, {
				img: require("@/assets/测试图片/5.png"),
				title: "沈阳临空经济区“区办园企”产业链招商模式成功实施3",
				date: "2023-09-25"
			}, {
				img: require("@/assets/测试图片/5.png"),
				title: "沈阳临空经济区“区办园企”产业链招商模式成功实施4",
				date: "2023-09-25"
			}, {
				img: require("@/assets/测试图片/5.png"),
				title: "沈阳临空经济区“区办园企”产业链招商模式成功实施5",
				date: "2023-09-25"
			},], //招商要闻
			imgIndex: 0, //图片选择下标
		}
	},
	mounted() { },
	methods: {
		cutImg(index) {
			this.imgIndex = index;
		},
		goTo(url, name) {
			this.$emit('jumpUrl',url,name);
			// this.$emit('jumpQuery',name);

		},
	}

}
</script>

<style lang="scss" scoped>
.body {
	padding: 20px 280px;

	.title {
		height: 40px;

		img {
			width: 25px;
			height: 25px;
		}

		>span {
			line-height: 40px;
			margin-left: 9px;
			font-size: 29px;
			font-family: 'DingTalk_JinBuTi_Regular';
		}

		.view-more {
			float: right;
			line-height: 40px;
			color: #a7abc0;
			font-size: 16px;
			cursor: pointer;

			img {

				width: 13px;
				height: auto;
			}
		}
	}

	.title.margin {
		margin: 40px 0px 30px 0px;
	}

	>.content {
		margin-top: 10px;

		.content-img {
			padding-right: 15px;

			img {
				width: 100%;
				height: 374px;
			}

			&-title {
				background-color: rgba(9, 6, 5, 0.5);
				position: absolute;
				bottom: 5px;
				height: 50px;
				line-height: 50px;
				text-align: center;
				font-size: 16px;
				font-family: 'SourceHanSansSC-Regular';
				color: #fff;
				width: calc(50% - 16px);
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 1;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.text {
			background-color: #fff;
			padding: 11px 10px;
			border-bottom: 1px solid #ececec;
			margin-left: 15px;
			cursor: pointer;

			&-title {
				font-size: 22px;
				font-family: "SourceHanSansSC-Regular";
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 1;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&-date {
				margin-top: 10px;
				font-size: 14px;
				color: #a3a3a3;
			}
		}
	}
}

.tupyangshi {
	width: 100%;
}
</style>