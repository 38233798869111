<!--
 * @Author: jiangjinchi 1468922694@qq.com
 * @Date: 2024-09-13 09:05:28
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2024-09-18 14:23:33
 * @FilePath: \pc\src\view\detailPage\detailpageOne.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="body">
        <FixedContent :titleJumpList="titleJumpList" @jumpUrl="jumpUrl"></FixedContent>
        <div class="content">
            <div class="introduct-left ">
                <div class="title">
                    {{ objInfo.journalismTitle }}
                </div>
                <div class="date">
                    {{ objInfo.journalismDate }} <span> {{ objInfo.source }}</span>
                </div>

                <div class="ql-container ql-snow" style="border: none;">
                    <div class="ql-editor" @click="showImage($event)" v-html="objInfo.journalismContent"></div>
                </div>
            </div>
        </div>
        <elImageViewer v-if="dialogVisible" :on-close="closeImage" :url-list="[url]" />
    </div>
</template>

<script>
export default {
    props: ['query'],
    data() {
        return {
            url: '',
            dialogVisible: false,
            titleJumpList: [],
            objInfo: ""
        }
    },
    mounted() {

        let params = JSON.parse(this.query.titleJumpList)
        params.push({
            url: 'detailpageTwo',
            name: '详情'
        })

        this.titleJumpList = params;
        this.getDetail()
    },
    methods: { //跳转 
        showImage(e) {
            if (e.target.tagName == 'IMG') {

                this.url = e.target.src
                this.dialogVisible = true
            }
        },
        closeImage() {
            this.dialogVisible = false
            this.url = '';
        },
        jumpUrl(e) {
            this.$emit('jumpUrl', e, this.query.name)
        },
        getDetail() {
            this.$baseRequestGet('/maintain/journalism/details?info=' + this.query.id, {}, 'get').then(res => {
                this.objInfo = res.data;
                if (this.objInfo.journalismContent) {
                    this.objInfo.journalismContent = this.objInfo.journalismContent.replace(/<iframe/g, "<video controls style='width:100%;height:auto;'")
                }
            })
        },

    }

}
</script>

<style lang="scss" scoped>
.body {
    padding: 20px 280px;
    

    .content {
        margin-top: 30px;
        padding: 50px 130px;
        background-color: #fff;
        border-radius: 5px;
    }

    .title {
        text-align: center;
        font-family: 'SourceHanSansSC-Regular';
        font-size: 26px;
        color: #000;
        margin-bottom: 20px;

        img {
            width: 20px;
            height: auto;
        }

        span {
            margin: 0px 10px;
        }
    }
}

.tupian {
    width: 100%;

    img {
        width: 100%;
        height: auto;
    }
}

.conts {
    margin-top: 30px;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 140%;

}

.introduct-left {

    background: #fff;
    // padding: 20px;
    padding-bottom: 60px;

    .title {
        font-size: 28px;
        text-align: left;
    }

    .date {
        font-size: 18px;
        color: #878787;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .content {
        font-size: 16px;
    }



}
</style>