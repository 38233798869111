<template>
    <div class="open-wrap">
        <div class="content">
            <FixedContent :FixedContentList="FixedContentList" @ControlsBack="ControlsBack"></FixedContent>
            <div v-if="!showMoreListDetail && !showDetail">
                <el-row :gutter="20">
                    <div class="indexBox" v-if="['精选旅游路线', '路线规划'].includes(typeName)">
                        <div v-for="(item, index) in list" :key="index" class="itemIndex"
                         >
                            <img :src="item.lineImage"  alt="">
                            <div  class="itemIndexImg">
                                <img  @click="clickStore(item.id, '路线规划')" src="../../assets/首页切图/旅游介绍-去看看.png" alt="">

                            </div>
                            <div class="introduction">
                                <div class="introduction-bigname">
                                    {{item.lineName}}
                                </div>
                                <div class="introduction-smallname">
                                    {{item.lineDays}}天·{{item.numberAttractions}}个观光景点
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="store" v-if="['旅游介绍', '热门景点推荐'].includes(typeName)">
                        <div class="storeItem" v-for="(item, index) in list" :key="index + 'n'" @click="clickXiZ(item.id)">
                            <div class="stoPics">
                                <el-image lazy class="wall-detail" :src="item.coverImage">
                                    <div slot="placeholder" class="image-slot">
                                        <img src="@/assets/首页切图/缺省.png" alt="">
                                    </div>
                                </el-image>
                            </div>
                            <div class="content">
                                <div class="contentName">
                                    {{item.coverName}}
                                </div>
                                <div class="adress">
                                    <div class="adress-sign">
                                        <img src="../../assets/首页切图/旅游景点-标签.png" alt="">
                                        <div class="ranking">
                                            旅游推荐NO.{{item.recommendedRanking}}
                                        </div>
                                    </div>
                                    <div class="address-name">
                                        {{item.introductionScenicSpots}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="store" v-if="['西藏网红餐厅'].includes(typeName)">
                        <div class="storeItem" v-for="(item, index) in list" :key="index + 'n'" @click="clickXiZ(item.id)">
                            <div class="stoPics">
                                <el-image lazy class="wall-detail" :src="item.picture">
                                    <div slot="placeholder" class="image-slot">
                                        <img src="@/assets/首页切图/缺省.png" alt="">
                                    </div>
                                </el-image>
                            </div>
                            <div class="content">
                                <div class="contentName">
                                   {{ item.restaurantName }}
                                </div>
                                <div class="adress">
                                    <div class="address-name" style="margin-left: 0px;">
                                        {{ item.briefIntroduction }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-row>
            </div>
            <moreListDetail ref="moreListDetailRef" v-if="showMoreListDetail" :typeName="typeName"
                @needShowDetail="needShowDetail" @modifyName="modifyName" :id="id"></moreListDetail>
            <detail v-if="showDetail" ref="detailRef" @needShowDetail="needShowDetail" :id="id" :typeName="typeName"
                @goBack="goBack"></detail>
        </div>

    </div>
</template>

<script>
import moreListDetail from './moreListDetail'
import detail from './detail'

export default {
    components: {
        moreListDetail, detail,
    },
    props: {
        typeName: {}
    },
    data() {
        return {
            FixedContentList: [],
            showDetail: false,
            showMoreListDetail: false,
            cityName: "",
            cityList: [],
            cityId: "",
            list: [],
            id: ''
        }
    },
    mounted() {
        this.findList();
        this.FixedContentList.push(this.typeName)
    },
    methods: {
        goBackDetail(e) {

        },
        findList() {
            if (['精选旅游路线','路线规划'].includes(this.typeName)) {
                // 路线
                this.$baseRequest('/scenic/line/findList', {}, 'post').then(res => {
                    this.list = res.data;
                });
            }else if(this.typeName == '西藏网红餐厅') {
                this.$baseRequest('/not/restaurant/findList', {}, 'post').then(res => {
                    this.list = res.data;
                });
            } else {
                // 景点
                this.$baseRequest('/scenic/spot/findList', {}, 'post').then(res => {
                    this.list = res.data;
                });

            }

        },
        goTo(e) {
            this.$emit("goBack");
        },
        goBack(e) {

            if (e == 'one') {
                this.showMoreListDetail = false;
                this.showDetail = false;

            } else {
                this.$emit("goBack");
            }

        },
        modifyName(e) {
            this.$set(this, "FixedContentList", [this.typeName, '景点详情']);
        },
        needShowDetail(e) {
            this.$set(this, "FixedContentList", [this.typeName, '景点详情', '详情']);
        },
        ControlsBack(e) {
            switch (e) {
                case -1:
                    this.$emit("goBack");
                    break;
                case 0:
                    this.$set(this, "FixedContentList", [this.typeName]);
                    this.showMoreListDetail = false;
                    this.showDetail = false;
                    break;
                case 1:
                    this.$set(this, "FixedContentList", [this.typeName, '景点详情']);
                    if (this.showMoreListDetail) {
                        this.$refs.moreListDetailRef.detailShow = false;
                    } else {
                        this.$refs.detailRef.needShowDetail = false;
                    }
                    break;
                default:
                    break;
            }

        },
        clickStore(id) {
            this.$set(this, "FixedContentList", [this.typeName, '详情']);
            this.showMoreListDetail = true;
            this.id = id;
            this.$nextTick(() => {
                document.documentElement.scrollTop = 200;
            })
        },
        clickXiZ(e) {
            this.id = e;
            this.$set(this, "FixedContentList", [this.typeName, '详情']);
            this.showDetail = true;
        }
    }
}
</script>


<style scoped lang="scss">
.indexBox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 40px;


}

.itemIndex {
    width: 650px;
    position: relative;
    margin-bottom: 25px;

    img {
        width: 100%;
        border-radius: 10px;
        height: 250px;
    }

    .itemIndexImg {
        position: absolute;
        bottom: 90px;
        right: 20px;

        img {
            width: 150px;
            height: auto;
            cursor: pointer;
        }
    }

    .introduction {
        position: absolute;
        top: 100px;
        left: 40px;
        color: #fff;

        .introduction-bigname {
            font-size: 25px;
            margin-bottom: 12px;
        }

        .introduction-smallname {
            font-size: 17px;
        }
    }
}

.breadcrumb {
			height: 15px;
			display: flex;
			align-items: center;
			line-height: 15px;
			color: #8389a6;
			margin-bottom: 20px;
			font-size: 19px;

			div {
				display: flex;
				align-items: center;

				img {
					width: 18px;
					height: auto;
					margin-right: 10px;
				}
			}

			i {
				margin: 0 5px;
			}
		}


.open-wrap {
    

    .content {
        padding: 60px 310px;
    }
}


.store {
    margin-top: 40px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;

    .storeItem {
        width: 32%;
        background-color: #fff;
        border-radius: 10px;

        cursor: pointer;

        .stoPics {
            width: 100%;
            height: 280px;
            overflow: hidden;
            margin-bottom: 0px;
            border-radius: 10px 10px 0px 0px;

            .wall-detail {
                width: 100%;
                height: 280px;
            }

            .image-slot {
                img {
                    width: 100%;
                    height: 280px;
                }

            }
        }

        img {
            display: block;
            width: 100%;
        }

        .content {
            background-color: #fff;
            padding: 30px 20px;
            border-radius: 0px 0px 10px 10px;
        }

        .contentName {
            font-size: 26px;
            font-weight: 600;
            color: #000;
            margin-bottom: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            img {
                width: 80px;
            }
        }

        .adress {
            display: flex;
            align-items: center;
            color: #6C6C6C;

            font-size: 17px;

            .adress-sign {
                position: relative;

                img {
                    width: 120px;
                    height: auto;
                }

                .ranking {
                    position: absolute;
                    left: 4px;
                    top: 1px;
                    font-size: 16px;
                    color: #8F694D;
                }
            }

            .address-name {
                width: calc(100% - 120px);
                margin-left: 10px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}

.store :last-child {
    margin-right: 0px;
}
</style>