<!--
 * @Author: jiangjinchi 1468922694@qq.com
 * @Date: 2024-09-14 13:03:46
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2024-09-17 15:21:51
 * @FilePath: \ddd\src\view\consumptionLiaoning\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<div class="body">
		<el-row class="title">
			<img src="../../assets/消费辽宁/探索辽宁商品.png" />
		</el-row>
		<div class="conent">
			<div v-for="(item, index) in commodity" :key="index">
				<img :src="item.img" @click="goTo(item.url, item.name)" class="commodity" />
			</div>
		</div>
		<el-row class="title margin">
			<img src="../../assets/消费辽宁/辽宁饮食文化.png" />
		</el-row>
		<div class="conent">
			<div v-for="(item, index) in culture" :key="index">
				<img :src="item.img" @click="goTo(item.url, item.name)" class="culture" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			commodity: [{
				img: require("@/assets/消费辽宁/辽宁品牌商品.png"),
				url: "consumptionDetail",
				name: "品牌商品"
			}, {
				img: require("@/assets/消费辽宁/老字号商品.png"),
				url: "consumptionDetail",
				name: "老字号商品"
			}, {
				img: require("@/assets/消费辽宁/辽宁农产品.png"),
				url: "consumptionDetail",
				name: "农产品"
			}], //商品
			culture: [{
				img: require("@/assets/消费辽宁/品味辽菜.png"),
				url: "AttractInvestment",
				name: "品味辽菜"
			}, {
				img: require("@/assets/消费辽宁/辽宁步行街.png"),
				url: "AttractInvestment",
				name: "辽宁步行街"
			},], //文化
		}
	},
	mounted() { },
	methods: {
		goTo(url, name) {
			this.$emit('jumpUrl', url, name)
		}
	}

}
</script>

<style lang="scss" scoped>
.body {
	padding: 55px 280px;
	

	.title {
		img {
			width: 514px;
			height: 42px;
		}
	}

	.conent {
		display: flex;
		justify-content: space-between;
		margin-top: 22px;

		.commodity {
			cursor: pointer;
			width: 440px;
			height: 300px;
		}

		.culture {
			cursor: pointer;
			width: 670px;
			height: 230px;
		}
	}

	.title.margin {
		margin-top: 45px;

		img {
			width: 472px;
			height: 44px;
		}
	}
}
</style>