<template>
	<div class="newsIndex-wrap">
		<FixedContent :titleJumpList="titleJumpList" @jumpUrl="jumpUrl"></FixedContent>
		<div class="newsIndex-introduct">
			<div class="introduct-left ">
				<div class="title">
					{{ details.journalismTitle }}
				</div>
				<div class="date">
					{{ details.journalismDate }} <span> {{ details.source }}</span>
				</div>

				<div class="ql-container ql-snow" style="border: none;">
					<div class="ql-editor" @click="showImage($event)" v-html="details.journalismContent"></div>
				</div>
			</div>
			<div class="introduct-right">
				<div class="introduct-title">{{ query.type }}</div>
				<div class="list-div">
					<div class="list" v-for="(item, index) in list" :key="index" @click="getDetails(item.id)">

						<div class="list-left-div">
							<div></div>
						</div>
						<div class="list-right">
							<div class="list-content">{{ item.title }}</div>
							<div class="list-date">{{ item.date }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<elImageViewer v-if="dialogVisible" :on-close="closeImage" :url-list="[url]" />
	</div>
</template>

<script>
export default {
	props: ["query"],
	data() {
		return {
			url: '',
			dialogVisible: false,
			details: {

			}, //详情
			list: [], //列表
			titleJumpList: [
				{
					url: "liaoQiaExpress",
					name: "辽洽快讯"

				}, {
					url: "newsIndex",
					name: "新闻详情"

				},
			]
		}
	},
	mounted() { },
	methods: {
		showImage(e) {
			if (e.target.tagName == 'IMG') {

				this.url = e.target.src
				this.dialogVisible = true
			}
		},
		closeImage() {
			this.dialogVisible = false
			this.url = '';
		},
		jumpUrl(e) {
			this.$emit('jumpUrl', e)
		},
		//获取详情
		getDetails(id) {
			this.$baseRequest("/maintain/journalism/details?info=" + id, {}, "get").then(res => {
				this.details = res.data;
				if (this.details.journalismContent) {
					this.details.journalismContent = this.details.journalismContent.replace(/<iframe/g, "<video controls style='width:100%;height:auto;'")
				}
			})
		},
		//获取列表
		getList(type) {
			this.$baseRequest("/maintain/journalism/findList", {
				journalismCategory: this.query.journalismCategory,
				journalismSecondaryClassification: type,
			}, "post").then(res => {
				this.list = [];
				res.data.forEach(item => {
					this.list.push({
						id: item.id,
						title: item.journalismTitle,
						date: item.journalismDate
					})
				})
			})
		}
	},
	watch: {
		"query.id": {
			handler: function (newVal, oldVal) {
				this.getDetails(newVal);
			},
			immediate: true
		},
		"query.type": {
			handler: function (newVal, oldVal) {
				this.getList(newVal);
			},
			immediate: true
		}
	}
}
</script>
<style>
.search .el-input__inner {
	font-size: 18px;
}

.el-button--primary {
	font-size: 17px;
}
</style>
<style scoped lang="scss">
.newsIndex-wrap {
	padding: 20px 280px;



	.newsIndex-introduct {

		display: flex;
		justify-content: center;
		padding: 20px 0;

		.introduct-left {
			width: calc(100% - 350px);
			background: #fff;
			padding: 30px;
			padding-bottom: 60px;

			.title {
				font-size: 28px;
				text-align: left;
			}

			.date {
				font-size: 18px;
				color: #878787;
				margin-top: 10px;
				margin-bottom: 10px;
			}

			.content {
				font-size: 16px;
			}


			.introduct-list {
				display: flex;
				margin-top: 40px;
				padding-bottom: 40px;
				border-bottom: 1px solid #f0f1f7;
				cursor: pointer;


				img {
					width: 280px;
					height: 180px;

				}

				.list-right {
					display: flex;
					flex-direction: column;
					padding-left: 25px;

					.list-title {
						font-size: 23px;
						font-weight: 600;
						line-height: 36px;

					}

					.list-text {
						margin-top: 10px;
						font-size: 15px;
						color: #8b8b8b;
					}

					.list-time {
						margin-top: auto;
						font-size: 16px;
						color: #8b8b8b;
					}
				}
			}
		}

		.introduct-right {
			width: 350px;
			margin-left: 20px;

			.introduct-title {
				font-size: 19px;
				font-family: "思源黑体CN-Bold";
				margin-bottom: 5px;
			}

			.title {
				font-size: 20px;
				font-weight: bold;
			}

			.list-div {
				.list {
					display: flex;
					padding: 15px 17px;
					cursor: pointer;
					background-color: #fff;
					margin-bottom: 10px;

					.list-left-img {
						width: 200px;
						height: 90px;

						img {
							width: 100%;
							height: 100%;
						}
					}

					.list-left-div {
						width: 35px;
						height: 80px;
						display: flex;
						align-items: center;
						justify-content: center;

						>div {
							width: 5px;
							height: 5px;
							background-color: #eb1f2e;
						}
					}

					.list-right {
						margin-top: 5px;
						margin-left: 15px;
						display: flex;
						flex-direction: column;
						width: 100%;
						flex-wrap: wrap;

						.list-content {
							font-size: 16px;
							font-family: "SourceHanSansSC-Regular";
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							overflow: hidden;
							text-overflow: ellipsis;
						}

						.list-date {
							margin-top: 10px;
							font-size: 13px;
							color: #b1b1b1;

						}
					}
				}
			}
		}
	}

	.loadingMorebox {
		height: 750px;
		overflow: auto;
	}
}

.search-box {
	margin: 30px 0px;

	.search {
		width: 90%;
		margin-right: 10px;
	}
}
</style>