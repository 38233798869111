/*
 * @Author: your name
 * @Date: 2022-03-11 18:44:51
 * @LastEditTime: 2024-09-16 16:49:52
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \systemDocuments\src\utils\mount-components.js
 */
import Vue from "vue";
// 挂载全局组件

import chat from '@/components/chat'
import FixedContent from '@/components/FixedContent'
import CityUtils from '@/components/CityUtils'
import elImageViewer from 'element-ui/packages/image/src/image-viewer'
import timeLine from '../components/timeLine.vue'
import mainIndex from '@/view/mainIndex.vue';
import liaoQiaExpress from '@/view/liaoQiaExpress/index.vue';

import liaoningSituation from '@/view/liaoningSituation/index.vue';
import investLiaoning from '@/view/investLiaoning/index.vue';
import openLiaoning from '@/view/openLiaoning/index.vue';
import previousAchievements from '@/view/previousAchievements/index.vue';
import supplyPurchase from '@/view/supplyPurchase/index.vue';
import consumptionLiaoning from '@/view/consumptionLiaoning/index.vue';
import ImportantActivity from '@/view/ImportantActivity/index.vue';
import newsIndex from '@/view/newsIndex.vue';
import detailpageOne from '@/view/detailPage/detailpageOne.vue';
import AttractInvestment from '@/view/AttractInvestment/index.vue';
import ProjectLibrary from '@/view/ProjectLibrary/index.vue';

import detailpageTwo from '@/view/detailPage/detailpageTwo.vue';
import policy from '@/view/policy/index.vue';
import previousAchievementsDET from '../view/previousAchievements/detail.vue'

import SubjectActivity from '../view/SubjectActivity/index.vue'

import liveVideo from '../view/WonderfulCom/liveVideo.vue'
import NavigationPage from '../view/NavigationPage/index.vue'

import HighlightMoment from '../view/HighlightMoment/index.vue'

import ExhibitionService from '../view/ExhibitionService/index.vue'
import exhibitionGuide from '../view/exhibitionGuide.vue'
import detailpageThree from '@/view/detailPage/detailpageThree.vue';
import consumptionDetail from '@/view/consumptionLiaoning/detail.vue';

import supplyPurchaseDetail from '@/view/supplyPurchase/detail.vue';
import photoBroadcast from '@/view/WonderfulCom/photoBroadcast.vue';
import KeyPoint from '@/view/KeyPoint/index.vue';
import emphasisDevelopmentZone from '@/view/openLiaoning/emphasisDevelopmentZone.vue';

import ebay from '@/view/openLiaoning/ebay.vue';
import pilotFreeTradeZone from '@/view/openLiaoning/pilotFreeTradeZone.vue';
import exhibitionInfo from '@/view/ExhibitionService/exhibitionInfo.vue';

const mountComponents = {
    // install 是默认的方法。当外界在 use 这个组件的时候，就会调用本身的 install 方法，同时传一个 Vue 这个类的参数。

    install: function () {
        Vue.component('chat', chat);
        Vue.component('FixedContent', FixedContent);
        Vue.component('CityUtils', CityUtils);
        Vue.component('elImageViewer', elImageViewer);
        Vue.component('timeLine', timeLine);
        // 主页面
        Vue.component('mainIndex', mainIndex);
        // 辽洽快讯
        Vue.component('liaoQiaExpress', liaoQiaExpress);
        // 辽宁省情
        Vue.component('liaoningSituation', liaoningSituation);
        // 投资辽宁
        Vue.component('investLiaoning', investLiaoning);
        // 开放平台
        Vue.component('openLiaoning', openLiaoning);
        // 历届成就
        Vue.component('previousAchievements', previousAchievements);
        // 供采对接
        Vue.component('supplyPurchase', supplyPurchase);
        // 消费辽宁
        Vue.component('consumptionLiaoning', consumptionLiaoning);
        // 重要活动
        Vue.component('ImportantActivity', ImportantActivity);
        // 辽洽快讯详情页
        Vue.component('newsIndex', newsIndex);
        // 辽宁省情详情页
        Vue.component('detailpageOne', detailpageOne);
        // 投资辽宁详情页--查看更多/招商项目成果
        Vue.component('AttractInvestment', AttractInvestment);
        // 投资辽宁详情页--招商项目库
        Vue.component('ProjectLibrary', ProjectLibrary);
        // 投资辽宁详情页--查看更多/招商项目成果-详情
        Vue.component('detailpageTwo', detailpageTwo);
        // 投资辽宁详情页--招商引资政策
        Vue.component('policy', policy);
        // 历届成就-查看更多
        Vue.component('previousAchievementsDET', previousAchievementsDET);
        // 历届成就-主体活动
        Vue.component('SubjectActivity', SubjectActivity);
        // 历届成就-视频回顾
        Vue.component('liveVideo', liveVideo);
        // 辽洽导航-查看更多
        Vue.component('NavigationPage', NavigationPage);
        // 高光时刻-查看更多
        Vue.component('HighlightMoment', HighlightMoment);
        // 展会服务-查看更多
        Vue.component('ExhibitionService', ExhibitionService);
        // 酒店服务
        Vue.component('exhibitionGuide', exhibitionGuide);
        // 酒店服务-详情
        Vue.component('detailpageThree', detailpageThree);
        // 消费辽宁-详情
        Vue.component('consumptionDetail', consumptionDetail);
        // 供采对接-详情
        Vue.component('supplyPurchaseDetail', supplyPurchaseDetail);
        // 精彩瞬间-查看更多
        Vue.component('photoBroadcast', photoBroadcast);
        // 开放平台-详情    
        Vue.component('KeyPoint', KeyPoint);
        // 重点开发区  
        Vue.component('emphasisDevelopmentZone', emphasisDevelopmentZone);
        // 自贸试验区  
        Vue.component('pilotFreeTradeZone', pilotFreeTradeZone);
        // 跨境电商  
        Vue.component('ebay', ebay);
         // 展会服务-展会概况内容
        Vue.component('exhibitionInfo', exhibitionInfo);

    },

}
export default mountComponents
