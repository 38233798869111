<template>
    <div>
        <el-row>
            <el-col :span="24" class="rich-text">

                <div>
                    <div class="btn-chat">
                        <div class="zan" @click="clickLike">
                            <img :style="{ 'cursor': agreeState ? 'not-allowed' : 'pointer' }"
                                :src="require(`@/assets/首页切图/${agreeState ? '已' : '未'}点赞.png`)" alt="">
                            {{ info.likeNum }}
                        </div>
                        <div class="zan"><img src="../assets//首页切图/评论.png" alt="">
                            {{ info.commentNum }}</div>
                    </div>
                    <div class="pinglun">
                        评论 <span> {{ info.commentNum }}</span>
                    </div>
                    <div class="shuruk">
                        <div>
                            <img :src="userInfo.spare1" alt="">
                        </div>
                        <div class="shuruk-kuang">
                            <el-input class="shurn" type="textarea" show-word-limit maxlength="500" :rows="6"
                                placeholder="留下你的想法吧" v-model="textarea">
                            </el-input>
                        </div>
                    </div>
                    <div style="display: flex;justify-content: flex-end;" class="btn-amo">
                        <div data-tooltip="请输入内容" class="button" v-if="!textarea">
                            <div class="button-wrapper">
                                <div class="text">发布评论</div>
                                <span class="icon">
                                    <i style="font-size: 22px;" class="el-icon-s-comment"></i>
                                </span>
                            </div>
                        </div>
                        <div class="button2" v-else>
                            <div class="button-wrapper">
                                <div class="text" @click="clickCommit">发布评论</div>
                            </div>
                        </div>
                    </div>
                    <div class="pinylun-list" v-for="(item, index) in info.commentList" :key="index + 'pin'">
                        <div>
                            <img :src="item.enterpriseHeadPortrait" alt="">
                        </div>
                        <div class="shuruk-kuang">
                            <div class="title">
                                <div class="name">{{ item.enterpriseName }}</div>
                                <div class="time">{{ item.creatTime }}</div>
                            </div>
                            <div class="neirong">
                                {{ item.commentContent }}
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <!-- 跟随 -->
        <div class="follows" v-show="isShow == true">
            <button class="btn" @click="clickTop">
                <img src="../assets/首页切图/回到顶部 (1).png" alt="">
            </button>


        </div>
    </div>
</template>

<script>
export default {
    props: {
        fatherScrollHeigh: {
            type: [String, Number]
        },
        chatType: {
            type: String
        },
        id: {
            type: [String, Number]
        }
    },
    data() {
        return {
            isShow: false,
            textarea: '',
            info: '',
            agreeState: false,
            userInfo: {}
        }
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
        clearTimeout(this.timeout);
    },
    mounted() {
        let params = localStorage.getItem('yuanzang');
        this.userInfo = params ? JSON.parse(params) : '';
        this.getDetail()
        window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
        clickTop() {
            document.documentElement.scrollTop = 100;
        },
        clickCommit() {
            if (!this.userInfo) {
                this.$message.warning('请点击右上角登录后再操作')
                return;
            }
            let params = {
                enterpriseId: this.userInfo.id, //用户id
                currencyId: this.id, //评论对象id
                commentType: this.chatType,
                commentContent: this.textarea

            }
            this.$baseRequest('/not/comment/add', params, 'post').then(res => {
                // 刷新点赞状态
                this.textarea = '';
                this.$message.success('评论成功,审核中');
                this.getDetail()
            })
        },
        clickLike() {
            if (!this.userInfo) {
                this.$message.warning('请点击右上角登录后再操作')
                return;
            }
            if (!this.agreeState) {
                let params = {
                    enterpriseId: this.userInfo.id, //用户id
                    currencyId: this.id, //评论对象id
                    likeType: this.chatType

                }
                this.$baseRequest('/not/comment/like/add', params, 'post').then(res => {
                    // 刷新点赞状态
                    this.getDetail()
                })
            }
        },
        getDetail() {
            // 获取是否点赞
            this.$baseRequestGetParams('/not/comment/like/confirmWhetherLike', { currencyId: this.id, commentType: this.chatType, enterpriseId: this.userInfo.id }, 'get').then(res => {
                this.agreeState = res.data;
            })
            // 获取评论列表 + 点赞 + 评论 数
            this.$baseRequestGetParams('/not/comment/viewComments', { info: this.id, commentType: this.chatType }, 'get').then(res => {
                this.info = res.data;
            })
        },
        handleScroll() {

            let scroll = document.documentElement.scrollTop || document.body.scrollTop
            if (scroll > this.fatherScrollHeigh + 200) {
                this.isShow = true
            } else {
                this.isShow = false
            }
        },
    }
}
</script>

<style scoped lang="scss">
.btn-chat {
    display: flex;
    justify-content: flex-end;

    .zan {
        padding: 8px 12px;
        border: 1px solid #D4D7DF;
        font-size: 17px;
        cursor: pointer;
        margin-left: 20px;
        border-radius: 7px;

        img {
            vertical-align: text-bottom;
            width: 20px !important;
            height: auto;
        }
    }
}

.pinglun {
    font-size: 22px;
    color: #000;
    margin-bottom: 20px;
}

.shuruk {
    display: flex;

    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    .shuruk-kuang {
        width: calc(100% - 50px);
        margin-left: 10px;

        .shurn {
            width: 100%;
            font-size: 17px;
        }
    }
}

.btn-fabu {
    padding: 7px 15px;
    text-align: right;
    background-color: #F86C14;
    border-radius: 8px;
    color: #fff;
    font-size: 18px;
    margin-top: 15px;
    cursor: pointer;
}

.pinylun-list {
    display: flex;
    margin-bottom: 40px;

    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    .shuruk-kuang {
        width: calc(100% - 50px);
        margin-left: 10px;

        .title {
            display: flex;

            .name {
                font-size: 18px;
                margin-right: 10px;
                color: #000;
                letter-spacing: 1px;
            }

            .time {
                font-size: 16px;
                color: #DCDFE6;
            }
        }

        .neirong {
            font-size: 16px;
            margin-top: 10px;
        }
    }
}

.follows {
    width: 74px;
    position: fixed;
    right: 230px;
    top: 30%;

    .btn {
        display: grid;
        place-items: center;
        background: #FAE8DB;
        padding: 0.9em;
        border-radius: 10px;
        box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
            -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
        border: 1px solid rgba(0, 0, 0, 0);
        cursor: pointer;
        transition: transform 0.5s;

        img {
            width: 26px;
            height: auto;
        }
    }

    .btn:hover {
        box-shadow: inset 4px 4px 6px -1px rgba(0, 0, 0, 0.2),
            inset -4px -4px 6px -1px rgba(255, 255, 255, 0.7),
            -0.5px -0.5px 0px rgba(255, 255, 255, 1),
            0.5px 0.5px 0px rgba(0, 0, 0, 0.15),
            0px 12px 10px -10px rgba(0, 0, 0, 0.05);
        border: 1px solid rgba(0, 0, 0, 0.1);
        transform: translateY(0.5em);
    }

    .btn svg {
        transition: transform 0.5s;
    }

    .btn:hover svg {
        transform: scale(0.9);
        fill: #333333;
    }







}

.btn-amo {
    .button2 {
        margin-top: 20px;
        --width: 100px;
        --height: 35px;
        --tooltip-height: 35px;
        --tooltip-width: 100px;
        --gap-between-tooltip-to-button: 18px;
        --button-color: #F86C14;
        --tooltip-color: #fff;
        width: var(--width);
        height: var(--height);
        background: var(--button-color);
        position: relative;
        text-align: center;
        border-radius: 0.45em;
        font-family: "Arial";
        transition: background 0.3s;
        font-size: 1.1rem;
        cursor: pointer;
    }

    .button {
        margin-top: 20px;
        --width: 100px;
        --height: 35px;
        --tooltip-height: 35px;
        --tooltip-width: 100px;
        --gap-between-tooltip-to-button: 18px;
        --button-color: #F86C14;
        --tooltip-color: #fff;
        width: var(--width);
        height: var(--height);
        background: var(--button-color);
        position: relative;
        text-align: center;
        border-radius: 0.45em;
        font-family: "Arial";
        transition: background 0.3s;
        font-size: 1.1rem;
    }

    .button::before {
        position: absolute;
        content: attr(data-tooltip);
        width: var(--tooltip-width);
        height: var(--tooltip-height);
        background-color: #F86C14;
        font-size: 1.1rem;
        color: #fff;
        border-radius: .25em;
        line-height: var(--tooltip-height);
        bottom: calc(var(--height) + var(--gap-between-tooltip-to-button) + 10px);
        left: calc(50% - var(--tooltip-width) / 2);
    }

    .button::after {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-top-color: #F86C14;
        left: calc(50% - 10px);
        bottom: calc(100% + var(--gap-between-tooltip-to-button) - 10px);
    }

    .button::after,
    .button::before {
        opacity: 0;
        visibility: hidden;
        transition: all 0.5s;
    }

    .text {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .button-wrapper,
    .text,
    .icon {
        overflow: hidden;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        color: #fff;
    }

    .text {
        top: 0
    }

    .text,
    .icon {
        transition: top 0.5s;
    }

    .icon {
        color: #fff;
        top: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .icon svg {
        width: 24px;
        height: 24px;
    }

    .button:hover {
        background: #F86C14;
    }

    .button:hover .text {
        top: -100%;
    }

    .button:hover .icon {
        top: 0;
    }

    .button:hover:before,
    .button:hover:after {
        opacity: 1;
        visibility: visible;
    }

    .button:hover:after {
        bottom: calc(var(--height) + var(--gap-between-tooltip-to-button) - 20px);
    }

    .button:hover:before {
        bottom: calc(var(--height) + var(--gap-between-tooltip-to-button));
    }




}
</style>