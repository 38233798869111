<!--
 * @Author: jiangjinchi 1468922694@qq.com
 * @Date: 2024-09-12 17:11:48
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2024-09-18 16:09:06
 * @FilePath: \pc\src\view\ImportantActivity\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="body">
        <div class="FixedContentStyle">
            <FixedContent :titleJumpList="titleJumpList" @jumpUrl="jumpUrl"></FixedContent>
        </div>
        <div class="exhibitionInfoCon">
            <!-- <el-image   class="imgStyle"  src="../../assets/展会服务/展会概况详情.png" ></el-image> -->
            <el-image   class="imgStyle"  :src="srcUrl" :preview-src-list="[srcUrl]"></el-image>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            srcUrl:  require("@/assets/展会服务/展会概况详情.png"),
            titleJumpList: [
                {
                    url: "ExhibitionService",
                    name: "展会服务"

                }, {
                    url: "exhibitionInfo",
                    name: "展会概况"

                },
            ]

        }
    },
    mounted() { },
    methods: {
        jumpUrl(e) {
            this.$emit('jumpUrl', e)
        },
    }

}
</script>

<style lang="scss" scoped>
.body {
    padding: 20px 280px;
    

    img {
        width: 100%;
        height: auto;
    }
}

.exhibitionInfoCon {
    background-color: #fff;
    margin-top: 20px;
    padding: 40px;
    letter-spacing: 1px;
    line-height: 150%;
    font-size: 18px;

    div {
        text-indent: 2em;
        margin-bottom: 10px;
    }
}

.FixedContentStyle {
    margin-left: 20px;

}
.title {
			text-align: center;
			font-family: 'SourceHanSansSC-Regular';
			font-size: 26px;
			color: #000;
			margin-bottom: 30px;

			img {
				width: 20px;
				height: auto;
			}

			span {
				margin: 0px 10px;
			}
		}
</style>
