<template>
	<div class="newsIndexDetails-wrap">
		<div class="content">
			<el-row class="breadcrumb">
				<div @click="goTo"><img src="@/assets/内容页切图/面包屑-首页.png" /> {{ $route.name }} </div><i
					class="el-icon-arrow-right"></i>{{ $route.name == '通知公告'? '公告详情': '新闻详情' }}
			</el-row>
			<el-row>
				<el-col :span="24" class="rich-text">
					<div class="journalismTitle">
						{{ infoData.journalismTitle }}
					</div>
					<div class="journalismDate">
					{{ infoData.source }}	{{ infoData.journalismDate }}
					</div>
					<div class="ql-container ql-snow" style="border: none;">
						<div class="ql-editor" @click="showImage($event)" v-html="infoData.journalismContent"></div>
					</div>
					<div id="chatRef">
						<chat :id="id" :chatType="'article'" :fatherScrollHeigh="fatherScrollHeigh"></chat>
					</div>
				</el-col>
			</el-row>
		</div>
		<elImageViewer v-if="dialogVisible" :on-close="closeImage" :url-list="[url]" />

	</div>
</template>

<script>

export default {
	props: {
		id: {},


	},
	data() {
		return {
			infoData: {
				journalismContent: ''
			},
			url: '',
			dialogVisible: false,
			textarea: '',
			fatherScrollHeigh: ''
		}
	},
	mounted() { },
	methods: {
		showImage(e) {
			if (e.target.tagName == 'IMG') {

				this.url = e.target.src
				this.dialogVisible = true
			}
		},
		closeImage() {
			this.dialogVisible = false
			this.url = '';
		},
		details() {
			this.$baseRequest('/maintain/journalism/details?info=' + this.id, {}, 'get').then(res => {
				this.infoData = res.data;
				if(this.infoData.journalismContent) {
					this.infoData.journalismContent = this.infoData.journalismContent.replace(/<iframe/g, "<video controls style='width:100%;height:auto;'")
				}
				
				this.$nextTick(res => {
					this.fatherScrollHeigh = document.getElementById('chatRef').offsetTop;
				})
			})
		},

		goTo() {
			this.$emit("goBack");
		},
		clickItem(item) {
			this.$baseRequest('/maintain/journalism/details?info=' + item.id, {}, 'get').then(res => {
				this.infoData = res.data
			})
		}
	}
}
</script>

<style src="@/style/element-ui-class.css"></style>
<style scoped lang="scss">
.newsIndexDetails-wrap {
	

	.content {
		padding: 60px 310px;

		.breadcrumb {
			height: 15px;
			display: flex;
			align-items: center;
			line-height: 15px;
			color: #8389a6;
			margin-bottom: 20px;
			font-size: 19px;
			div {
				display: flex;
				align-items: center;
				cursor: pointer;

				img {
					width: 18px;
					height: auto;
					margin-right: 10px;
				}
			}

			i {
				margin: 0 5px;
			}
		}

		.rich-text {
			background-color: #fff;
			padding: 50px 30px;

			.journalismTitle {
				font-size: 28px;
				color: #000;
				margin-bottom: 5px;
			}

			.journalismDate {
				color: #7B7B7B;
				margin-top: 10px;
				margin-bottom: 30px;
				font-size: 17px;
			}
		}

		.introduct-right {
			width: 350px;
			margin-left: 20px;

			.title {
				font-size: 20px;
				font-weight: bold;
			}

			.list-div {
				background-color: #fff;
				margin-top: 10px;

				.list {
					display: flex;
					padding: 15px;
					cursor: pointer;

					.list-left {
						margin-left: 10px;
						font-size: 55px;
						color: #015fe5;
					}

					.list-right {
						margin-left: 15px;
						display: flex;
						flex-direction: column;

						.list-content {
							font-size: 17px;
							font-weight: bold;
						}

						.list-date {
							margin-top: auto;
							font-size: 15px;
							color: #b1b1b1;
						}
					}
				}
			}
		}
	}
}

.btn-chat {
	display: flex;
	justify-content: flex-end;

	.zan {
		padding: 8px 12px;
		border: 1px solid #F9F9F9;
		font-size: 17px;
		cursor: pointer;
		margin-left: 20px;
		border-radius: 7px;

		img {
			vertical-align: middle;
			width: 18px;
		}
	}
}

.pinglun {
	font-size: 22px;
	color: #000;
	margin-bottom: 20px;
}

.shuruk {
	display: flex;

	img {
		width: 40px;
		height: 40px;
		border-radius: 50%;
	}

	.shuruk-kuang {
		width: calc(100% - 50px);
		margin-left: 10px;
	}
}

.btn-fabu {
	padding: 7px 15px;
	text-align: right;
	background-color: #F86C14;
	border-radius: 8px;
	color: #fff;
	font-size: 18px;
	margin-top: 15px;
	cursor: pointer;
}

.pinylun-list {
	display: flex;
	margin-bottom: 40px;

	img {
		width: 40px;
		height: 40px;
		border-radius: 50%;
	}

	.shuruk-kuang {
		width: calc(100% - 50px);
		margin-left: 10px;

		.title {
			display: flex;

			.name {
				font-size: 18px;
				margin-right: 10px;
				color: #000;
				letter-spacing: 1px;
			}

			.time {
				font-size: 16px;
				color: #DCDFE6;
			}
		}

		.neirong {
			font-size: 16px;
			margin-top: 10px;
		}
	}
}
</style>