<template>
	<div class="hezuo-wrap">
		<div class="content">
			<div class="FixedContentStyle">
				<FixedContent v-if="showDetail" @back="back" :titleJumpList="titleJumpList"></FixedContent>
			</div>
			<el-row>
				<el-col :span="24" class="hezuo-right">
					<div>
						<el-row v-if="!showDetail">
							<div class="tupListKun">
								<div class="tupListDia">
									<div v-for="(item, index) in tupList">
										<img style="cursor: pointer;" @click="tupClick(index)"
											:src="require(`../../assets/投资辽宁/${item}${tupListIndex == index ? '-选中' : '-未选中'}.png`)"
											alt="">
									</div>
								</div>
								<el-row class="overflow">
									<el-col :span="12" class="hezuo-right-project" v-for="(item, index) in list"
										:key="index" :class="index % 2 != 0 ? 'hezuo-right-project-left' : 'hezuo-right-project-right'">
										<div @click="clickDetail(item.id)">
											<div class="project-left">
												<img src="../../assets/投资辽宁/项目.png" />
											</div>
											<div class="project-title">
												{{ item.title }}
											</div>
											<div class="project-right">
												<img src="../../assets/内容页切图/列表箭头.png" />
											</div>
										</div>
									</el-col>
								</el-row>
							</div>
						</el-row>
						<el-row v-else>
							<div class="detail-box">
								<div class="first">
									<div>
										<img src="../../assets/投资辽宁/项目.png" />
										<div class="first-name">{{ infoData.title }}</div>
									</div>
									<div class="second-name">计划投资总额 <span>{{ infoData.totalPlannedInvestment }}</span>
									</div>
								</div>
								<div class="second">
									<div v-if="infoData.location">项目位置：{{ infoData.location }}</div>
									<div v-if="infoData.landUseSituation">用地情况：{{ infoData.landUseSituation }}</div>
									<div v-if="infoData.contacts">联系人：{{ infoData.contacts }}</div>
									<div v-if="infoData.telephone">联系电话：{{ infoData.telephone }}</div>
									<div class="single" v-if="infoData.supportingConditions">配套条件：{{
										infoData.supportingConditions }}</div>
								</div>
							</div>
							<div class="htmls-box">
								<el-row class="htmls-box-title">
									<img src="../../assets/辽洽快讯/标题装饰.png" />
									<span>项目简介</span>
								</el-row>
								<div class="htmls-box-content">
									{{ infoData.content }}
									
								</div>
								<!-- <div class="htmls-box-content" @click="showImage($event)" v-html="infoData.content">
								</div> -->
							</div>
						</el-row>
					</div>

				</el-col>
			</el-row>
		</div>
		<elImageViewer v-if="dialogVisible" :on-close="closeImage" :url-list="[url]" />
	</div>
</template>

<script>
	import city from '@/components/CityUtils.vue'
	export default {
		components: {
			city
		},
		data() {
			return {
				titleJumpList: [{
					url: "",
					name: "投资辽宁"

				}, {
					url: "",
					name: "详情"

				}, ],
				tupList: [
					'老字号',
					'新字号',
					'原字号',
				],
				tupListIndex: 0,
				showDetail: false,
				cityFage: "",
				policyDetails: false,
				list: [],
				infoData: {
					title: "",
					totalPlannedInvestment: "",
					location: "",
					landUseSituation: "",
					contacts: "",
					telephone: "",
					supportingConditions: "",
					content: ""
				},
				needCity: '',
				url: '',
				dialogVisible: false
			}
		},
		mounted() {
			this.findList();
		},
		methods: {
			findList() {
				this.$baseRequest('/not/investment/cooperation/findList', {
					province: this.cityFage,
					type: this.tupList[this.tupListIndex]
				}, 'post').then(res => {
					this.list = res.data;
				})
			},
			clickBack() {
				this.showDetail = false;
			},
			showImage(e) {
				if (e.target.tagName == 'IMG') {
					this.url = e.target.src
					this.dialogVisible = true
				}
			},
			closeImage() {
				this.dialogVisible = false
				this.url = '';
			},
			clickDetail(e) {
				this.$baseRequest('/not/investment/cooperation/getNotInvestment?info=' + e, {}, 'get').then(res => {
					this.infoData = res.data;
					this.showDetail = true;

				})
			},
			cityClick(e) {
				this.cityFage = e;
				this.showDetail = false;
				this.findList();
			},
			tupClick(index) {
				this.tupListIndex = index;
				this.findList();
			},
			back() {
				this.showDetail = false
			}
		}
	}
</script>

<style src="../../style/element-ui-class.css"></style>

<style scoped lang="scss">
	.FixedContentStyle {
		margin-left: 20px;
		margin-bottom: 30px;
	}

	.htmls-box {
		padding: 16px 150px;
		background-color: #fff;

		.htmls-box-title {
			display: flex;
			align-items: center;

			img {
				width: 23px;
				height: 23px;
			}

			span {
				font-family: "SourceHanSansSC-Regular";
				margin-left: 5px;
				font-size: 20px;
			}
		}

		.htmls-box-content {
			margin-top: 15px;
			margin-bottom: 45px;
			font-size: 17px;
		}
	}

	.detail-box {
		padding: 70px 150px;
		background-color: #fff;

		.first {
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid #f5f5f5;
			padding-bottom: 10px;

			>div {
				display: flex;
				align-items: center;

				>img {
					width: 52px;
					height: 52px;
				}

				.first-name {
					margin-left: 10px;
					font-size: 28px;
					font-family: "思源黑体CN-Bold";
					letter-spacing: 2px;
				}
			}

			.second-name {
				line-height: 36px;
				font-size: 16px;
				color: #888888;

				span {
					font-size: 28px;
					font-family: "思源黑体CN-Bold";
					color: #ea0e1e;
					margin-left: 5px;
				}
			}

		}

		.second {
			display: flex;
			flex-wrap: wrap;
			gap: 7px;
			font-size: 16px;
			margin-top: 24px;
			font-family: "SourceHanSansSC-Regular";

			div {
				width: calc(50% - 10px);
			}

			.single {
				width: calc(100% - 10px);
				margin-bottom: 10px;
			}
		}
	}

	.dingbu {
		width: 100%;
		height: auto;
	}

	.TourIntroduction-title {
		text-align: center;
		font-size: 36px;
		letter-spacing: 1px;

		img {
			vertical-align: middle;
			width: 43px;
		}
	}

	.hezuo-wrap {
		

		.content {
			padding: 20px 280px;

			.breadcrumb {
				height: 15px;
				display: flex;
				align-items: center;
				line-height: 15px;
				color: #8389a6;
				margin-bottom: 20px;
				font-size: 19px;

				div {
					display: flex;
					align-items: center;

					img {
						width: 18px;
						height: auto;
						margin-right: 10px;
					}
				}

				i {
					margin: 0 5px;
				}
			}

			.hezuo-left {
				background-color: #fff;
				padding: 30px 17px 30px 32px;

			}

			.hezuo-right {
				padding-left: 20px;

				.hezuo-right-vessel {
					margin-top: 30px
				}

				.hezuo-right-placeholder {
					height: 10px;
				}

				.hezuo-right-project {
					color: #000;
					margin-bottom: 20px;

					>div {
						border: 1px #f7f7f7 solid;
						border-radius: 5px;
						height: 92px;
						display: flex;
						align-items: center;
						cursor: pointer;
						padding: 0px 25px;

						.project-title {
							padding-right: 60px;
							font-size: 23px;
							margin-left: 20px;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							overflow: hidden;
							text-overflow: ellipsis;
						}

						.project-right {
							margin-left: auto;
						}
					}
				}

				.hezuo-right-project-left {
					padding-left: 10px;
				}

				.hezuo-right-project-right {
					padding-right: 10px;
				}
			}
		}

		.bigPolic {
			width: 970px;
			height: 100px;
		}
	}

	.conMargin {
		margin-bottom: 20px;
		display: flex;
	}

	.tupListDia {
		padding: 20px 20px 16px 20px;
		display: flex;
		justify-content: space-between;

		img {
			width: 400px;
			height: 108px;
		}
	}

	.tupListKun {
		padding: 20px;
		background-color: #fff;

		.overflow {
			max-height: 800px;
			overflow-y: auto;
			padding: 0px 20px;
		}

	}

	.detailBtn {
		font-size: 19px;
		color: #7E84A3;
		cursor: pointer;
	}

	.FixedContent-img2 {
		width: 14px;
		height: auto;
		vertical-align: middle;
		margin-right: 5px;
	}
</style>