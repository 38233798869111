<template>
	<div class="consumptionDetails-wrap">
		<div class="consumptionDetails-head">
			<div class="head-title">
				<div class="title1"> {{ infoData.productName }}</div>
				<div class="title2">{{ infoData.productDescription }}</div>
			</div>

			<div class="company">
				<div class="company-img">
					<img :src="infoData.picture" alt="">
				</div>
				<div class="company-content">
					<div class="first">
						{{ infoData.companyName }}
					</div>
					<div class="second">
						{{ infoData.companyTitle }}
					</div>
				</div>
				<div class="company-btn">
					<el-button class="btn-1" @click="dialogVisible = true" type="primary" style="">商务洽谈</el-button>
				</div>
			</div>
			<div class="company-info">
				{{ infoData.companyContent }}
			</div>
			<div class="phone">
				<div>
					<div class="phone-left">
						<img src="../../assets/首页切图/联系人.png" alt="">
						联系人：{{ infoData.contacts }}
					</div>
					<div class="phone-left">
						<img src="../../assets/首页切图/联系电话.png" alt="">
						联系电话：{{ infoData.contactNumber }}
					</div>
				</div>
				<div class="phone-qrd">
					<img :src="infoData.qrCode" alt="">
				</div>
			</div>
			<div id="chatRef">
				<chat :id="id" :chatType="'product'" :fatherScrollHeigh="fatherScrollHeigh"></chat>
			</div>
		</div>

		<el-dialog :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false" @close="close">
			<div class="dialog">
				<div class="dialog-title">填写约见信息</div>
				<div class="dialog-input">
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
						<el-form-item prop="messageName">
							<el-input v-model="ruleForm.messageName" placeholder="请输入您的姓名"></el-input>
						</el-form-item>
						<el-form-item prop="messageNumber">
							<el-input v-model="ruleForm.messageNumber" placeholder="请输入您的电话号码"></el-input>
						</el-form-item>
						<el-form-item prop="messageAddress">
							<el-input v-model="ruleForm.messageAddress" placeholder="请输入您的地址"></el-input>
						</el-form-item>
						<el-form-item prop="messageDemand">
							<el-input v-model="ruleForm.messageDemand" type="textarea" placeholder="请输入您的需求" :rows="5"
								resize="none"></el-input>
						</el-form-item>
					</el-form>
				</div>
				<div class="dialog-btn">
					<el-button @click="save" class="sumbitBox"
						style="background-color: #F86C14;border: 1px solid #F86C14;font-size: 16px;"
						type="primary">提交</el-button>

				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>

export const isPhone = (rule, value, callback) => {
	let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
	if (value != "") {
		if (reg.test(value)) {
			callback();
		} else {
			callback('请输入正确联系方式')
		}
	} else {
		callback();
	}
}
export default {
	props: ["id"],

	data() {
		return {
			fatherScrollHeigh: 0,
			infoData: {},
			dialogVisible: false,
			ruleForm: {
				boutiqueId: "",
				messageName: "",
				messageNumber: "",
				messageAddress: "",
				messageDemand: ""
			},
			rules: {
				messageName: [{
					required: true,
					message: '请输入您的姓名',
					trigger: 'blur'
				},],
				messageAddress: [{
					required: true,
					message: '请输入您的地址',
					trigger: 'blur'
				},],
				messageDemand: [{
					required: true,
					message: '请输入您的需求',
					trigger: 'blur'
				},],
				messageNumber: [{
					required: true,
					message: '请输入您的电话号码',
					trigger: 'blur'
				}, {
					validator: isPhone,
					trigger: 'blur'
				}]
			}
		}
	},
	mounted() {

		this.$baseRequest('/not/goods/boutique/specifics?info=' + this.id, {}, 'get').then(res => {
			this.infoData = res.data
		});
		this.$nextTick(res => {
			this.fatherScrollHeigh = document.getElementById('chatRef').offsetTop;
		})
	},
	methods: {
		save() {
			this.$refs['ruleForm'].validate((valid) => {
				if (valid) {
					this.ruleForm.boutiqueId = this.infoData.id;
					this.$baseRequest('/not/goods/boutique/message/add', this.ruleForm, 'POST').then(res => {
						this.$message.success("提交成功！");
						this.dialogVisible = false;
						this.close();
					})
				}
			});
		},
		close() {
			this.ruleForm = {
				boutiqueId: "",
				messageName: "",
				messageNumber: "",
				messageAddress: "",
				messageDemand: ""
			};
			this.$refs['ruleForm'].clearValidate();
		}
	}
}
</script>

<style src="@/style/element-ui-class.css"></style>

<style>
.consumptionDetails-wrap .el-input__inner {
	background-color: #f5f6f8 !important;
	height: 55px;
	line-height: 55px;
	border: none;
}

.consumptionDetails-wrap .el-textarea__inner {
	background-color: #f5f6f8 !important;
	border: none;
}
</style>

<style scoped lang="scss">
.phone {
	margin-top: 30px;
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;

	.phone-qrd {
		img {
			width: 100px;
			height: auto;
		}
	}

	.phone-left {
		img {
			width: 25px;
			height: auto;
			margin-right: 10px;
			vertical-align: middle;

		}

		font-size: 15px;
		margin-bottom: 20px;
	}
}

.company-info {
	font-size: 18px;
	color: #666666;
}

.company {
	background-color: #FFEEE3;
	padding: 15px;
	margin: 30px 0px;
	border-radius: 5px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.company-img {
		margin-right: 10px;

		img {
			width: 100px;
			border-radius: 5px;
		}
	}

	.company-content {
		width: calc(100% - 240px);
		text-align: left;

		.first {
			font-size: 22px;
			color: #000;
			margin-bottom: 10px;
		}

		.second {
			font-size: 17px;
			color: #666666;
			word-break: break-all;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;

		}
	}
}

.consumptionDetails-wrap {
	padding: 50px 30px;
	background-color: #fff;

	.consumptionDetails-head {

		padding-bottom: 20px;


		.head-title {
			.title1 {
				font-size: 27px;
				font-weight: 400;
			}

			.title2 {
				margin-top: 10px;
				font-size: 18px;
				color: #666666;
			}
		}

		.head-btn {
			display: flex;
			align-items: center;
			justify-content: right;

			img {

				width: 107px;
				height: 35px;
				vertical-align: bottom;
				cursor: pointer;
				// object-fit: contain;
			}
		}
	}

	.rich-text {
		margin-top: 20px;
		font-size: 15px;

		.richPic {
			text-align: center;
		}

		.wall-detail {
			width: 50%;

			margin-bottom: 20px;
		}
	}

	.dialog {
		text-align: center;
		padding: 0 45px 30px 45px;

		.dialog-title {
			color: #000;
			font-size: 22px;
			font-weight: bold;
		}

		.dialog-input {
			margin-top: 40px;
		}

		.dialog-btn {
			margin-top: 30px;
		}

		.sumbitBox {
			width: 220px;
			height: 44px;
			// cursor: pointer;
		}
	}
}

.btn-1 {
	background-color: #F86C14;
	border: 1px solid #F86C14;
	font-size: 13px;
}
</style>