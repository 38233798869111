<!--
 * @Author: jiangjinchi 1468922694@qq.com
 * @Date: 2023-09-01 10:55:04
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2024-09-16 16:02:02
 * @FilePath: \sizedemo\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="send-page">
        <div class="FixedContentStyle">
            <FixedContent :titleJumpList="titleJumpList" @jumpUrl="goTo"></FixedContent>
        </div>
        <div class="send-box">
            <div class="title">
                发布信息
                <img @click="sendMessage" style="cursor: pointer;" src="../../assets/其他/供采对接/组 386.png" alt="">
            </div>
            <div>

                <el-form ref="form" :model="form" label-width="80px">

                    <el-form-item label="标题">
                        <el-input v-model="form.title"></el-input>

                    </el-form-item>
                    <el-form-item label="内容">
                        <el-input v-model="form.content" maxlength="20" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item label="图片">
                        <el-upload class="upload-demo" :action="acitonUrl + '/file/pictureUpload'"
                            :on-remove="handleRemove" :on-success="onSuccess" multiple :file-list="fileList"
                            accept=".jpg,.jpeg,.png,.gif,.bmp,..JPG,.JPEG,.PBG,.GIF,.BMP">
                            <el-button size="small" type="primary">点击上传</el-button>

                        </el-upload>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['query'],
    data() {
        return {
            acitonUrl: window.PLATFROM_CONFIG.url,
            fileList: [],
            titleJumpList: [
                {
                    url: "supplyPurchase",
                    name: "供货对接"

                }, {
                    url: "",
                    name: "发布信息"

                }
            ],

            content: '',
            checked: '',
            form: {
                province: '',
                company: '',
                title: '',

                content: '',
                enterpriseId: '',
                photos: []
            },
            myPic: []
        }
    },
    mounted() {

    },

    methods: {
        handleRemove(file, fileList) {
        
            this.myPic = fileList.map(item => {
                return {
                    fileUrl: item.response.data.fileUrl
                }
            })
        
        },
        onSuccess(file, fileList) {
          
            this.myPic.push(file.data)
        },

        goTo(url, query) {
            this.$emit('jumpUrl', url, query);
        },
        sendMessage() {

            let arr = JSON.parse(localStorage.getItem('loginInfo'))

            this.form.province = this.query;
            this.form.company = arr.company;
            this.form.enterpriseId = arr.id;
            if (!this.form.title) {
                this.$message.warning('请输入标题')
                return;
            }
            if (!this.form.content) {
                this.$message.warning('请输入内容')
                return;
            }
            this.form.photos = [];
            for (let index = 0; index < this.myPic.length; index++) {
                this.form.photos.push(this.myPic[index].fileUrl)
            }
            this.$baseRequest('/not/online/negotiation/add', this.form, 'post').then(res => {
                this.$message.success('发布成功')
                setTimeout(() => {
                    this.$emit('jumpUrl', 'supplyPurchase');
                }, 1500);
            });


        }
    }

}
</script>

<style scoped lang="scss">
.FixedContentStyle {
    margin-bottom: 20px;
}

.send-page {
    padding: 60px 280px;
    

    .send-box {
        background-color: #fff;
        padding: 50px 70px;

        .title {
            font-size: 22px;
            color: #000;
            margin-bottom: 40px;

            img {

                width: 94px;
                height: 38px;
                float: right;
            }
        }
    }

}
</style>