import { render, staticRenderFns } from "./liveVideo.vue?vue&type=template&id=b26888f4&scoped=true&"
import script from "./liveVideo.vue?vue&type=script&lang=js&"
export * from "./liveVideo.vue?vue&type=script&lang=js&"
import style0 from "../../style/viedo.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./liveVideo.vue?vue&type=style&index=1&id=b26888f4&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b26888f4",
  null
  
)

export default component.exports