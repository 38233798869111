<template>
	<div :class="isDetails ? 'body1' : 'body2'">
		<div class="FixedContentStyle">
			<FixedContent :titleJumpList="titleJumpList" @jumpUrl="jumpUrl" @back="back"></FixedContent>
		</div>
		<el-row class="row" v-if="!isDetails">
			<el-col :span="8" v-for="(item, index) in pilotFreeTradeZone" :key="index" class="item">
				<img :src="item.img" @click="goTo('detailpageTwo', item)" />
			</el-col>
		</el-row>
		<el-row v-else>
			<div class="details">
				<div class="introduct-left ">
					<div class="title">
						{{ details.journalismTitle }}
					</div>
					<div class="date">
						{{ details.journalismDate }} <span> {{ details.source }}</span>
					</div>

					<div class="ql-container ql-snow" style="border: none;">
						<div class="ql-editor" @click="showImage($event)" v-html="details.journalismContent"></div>
					</div>
				</div>
			</div>
		</el-row>
		<elImageViewer v-if="dialogVisible" :on-close="closeImage" :url-list="[url]" />
	</div>
</template>

<script>
export default {
	data() {
		return {
			url: '',
			dialogVisible: false,
			pilotFreeTradeZone: [],
			titleJumpList: [{
				url: "openLiaoning",
				name: "开放平台"

			}, {
				url: "pilotFreeTradeZone",
				name: "自贸试验区"
			}],
			isDetails: false,
			details: {},
			imgUrl: require('../../assets/历届成就/历届成就bg.png'),
		}
	},
	mounted() {
		this.getPilotFreeTradeZone();
	},
	watch: {
		isDetails(val) {

			if (val) {
				this.titleJumpList = [
					{
						url: "openLiaoning",
						name: "开放平台"

					}, {
						url: "pilotFreeTradeZone",
						name: "自贸试验区"

					}, {
						url: "",
						name: "详情"

					}
				]
				this.imgUrl = ''

			} else {
				this.titleJumpList = [
					{
						url: "openLiaoning",
						name: "开放平台"

					}, {
						url: "pilotFreeTradeZone",
						name: "自贸试验区"

					}
				]
				this.imgUrl = require('../../assets/历届成就/历届成就bg.png')
			}
		}
	},
	methods: {
		showImage(e) {
			if (e.target.tagName == 'IMG') {

				this.url = e.target.src
				this.dialogVisible = true
			}
		},
		closeImage() {
			this.dialogVisible = false
			this.url = '';
		},
		back() {
			console.log('1111111111')
			this.isDetails = false;
		},
		//跳转 
		goTo(url, item) {

			let params = {
				name: this.query,
				id: item.id,
				titleJumpList: JSON.stringify(this.titleJumpList)
			}
			this.$emit('jumpUrl', url, params)
		},
		jumpUrl(e) {
			this.$emit('jumpUrl', e)
		},
		getPilotFreeTradeZone() {
			this.$baseRequest("/maintain/journalism/findList", {
				journalismCategory: "开放辽宁",
				journalismSecondaryClassification: "自贸区"
			}, "post").then(res => {
				this.pilotFreeTradeZone = [];
				res.data.forEach(item => {
					this.pilotFreeTradeZone.push({
						id: item.id,
						img: item.journalismPhoto
					})
				});
			})
		},
		//详情 
		getDetails(id) {
			this.$baseRequest("/maintain/journalism/details?info=" + id, {}, "get").then(res => {
				this.details = res.data;
				if (this.details.journalismContent) {
					this.details.journalismContent = this.details.journalismContent.replace(/<iframe/g, "<video controls style='width:100%;height:auto;'")
				}
				this.isDetails = true;
			})
		},
	}

}
</script>

<style lang="scss" scoped>
.body1 {
	padding: 20px 280px;

	.row {
		.item {
			text-align: center;

			>img {
				width: 421px;
				height: 480px;
			}
		}
	}

	.details {
		// margin-top: 30px;
		padding: 20px;
		background-color: #fff;
		// box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

		border-radius: 5px;

		.introduct-left {
			background: #fff;
			padding-bottom: 60px;

			.title {
				font-size: 28px;
				text-align: left;
			}

			.date {
				font-size: 18px;
				color: #878787;
				margin-top: 10px;
				margin-bottom: 10px;
			}

			.content {
				font-size: 16px;
			}
		}
	}
}

.body2 {
	padding: 20px 280px;

	.row {
		.item {
			text-align: center;

			>img {
				width: 421px;
				height: 480px;
			}
		}
	}

	.details {
		// margin-top: 30px;
		padding: 20px;
		background-color: #fff;
		border-radius: 5px;

		.introduct-left {
			background: #fff;
			padding-bottom: 60px;

			.title {
				font-size: 28px;
				text-align: left;
			}

			.date {
				font-size: 18px;
				color: #878787;
				margin-top: 10px;
				margin-bottom: 10px;
			}

			.content {
				font-size: 16px;
			}
		}
	}
}

.FixedContentStyle {

	margin-bottom: 20px;
}
</style>