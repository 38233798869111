<template>
	<div class="body">
		<div class="FixedContentStyle">
			<FixedContent :titleJumpList="titleJumpList" @jumpUrl="jumpUrl" @back="back"></FixedContent>
		</div>
		<el-row id="detailBACK">

			<el-col :span="24" class="content" v-if="!showPage">
				<el-row>
					<el-input placeholder="请输入城市名称" v-model="params.cityName" class="content-input"
						@input="cityNameInput">
						<i slot="suffix" class="el-input__icon el-icon-search"></i>
					</el-input>
				</el-row>
				<el-row class="overflow">
					<el-col :span="12" v-for="(item, index) in developmentZone" :key="index" class="content-item">
						<div @click="clickDetail(item)">
							<div>
								<div class="content-item-name">
									{{ item.name }}
								</div>
								<div class="content-item-content">
									{{ item.content }}
								</div>
							</div>
							<img :src="item.img" class="content-item-img" />
						</div>
					</el-col>
				</el-row>
			</el-col>
			<el-col :span="24" class="content" v-if="showPage">
				<KeyPoint @jumpUrl="jumpUrl" :id="id" @changeName="changeName" ref="KeyPointCon"></KeyPoint>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import KeyPoint from '../KeyPoint/index.vue'
export default {
	props: ['query'],
	components: {
		KeyPoint
	},
	data() {
		return {
			titleJumpList: [
				{
					url: "openLiaoning",
					name: "开放平台"

				}, {
					url: "emphasisDevelopmentZone",
					name: "重点开发区"

				}
			],
			showPage: false,
			params: {
				cityId: "",
				cityName: ""
			},

			developmentZone: [], //开发区
			cityClickIndex: 0,
			id: "",
			timer: null
		}
	},
	mounted() {
		// if (localStorage.getItem('jilu')) {
		// 	this.showPage = true;
		// 	this.$refs.KeyPointCon.showPart = [false, false, false, false, false, true];
			
		// }
		this.getDevelopmentZone();
	},
	watch: {
		showPage(val) {

			if (val) {
				this.titleJumpList = [
					{
						url: "openLiaoning",
						name: "开放平台"

					}, {
						url: "",
						name: "重点开发区"

					}, {
						url: "emphasisDevelopmentZone",
						name: "详情"

					}
				]
			} else {
				this.titleJumpList = [
					{
						url: "openLiaoning",
						name: "开放平台"

					}, {
						url: "emphasisDevelopmentZone",
						name: "重点开发区"

					}
				]
			}
		}
	},
	methods: {
		changeName(val) {
		
			this.titleJumpList = [
				{
					url: "openLiaoning",
					name: "开放平台"

				}, {
					url: "",
					name: "重点开发区"

				}, {
					url: "",
					name: "详情"

				}, {
					url: "",
					name: val

				}
			];
			this.$scrollTo('#detailBACK', 500, { offset: 0 })
		},
		getDevelopmentZone() {
			this.$baseRequest("/not/development/zones/findList", {
				title: this.params.cityName
			}, "post").then(res => {
				this.developmentZone = [];
				res.data.forEach(item => {
					this.developmentZone.push({
						id: item.id,
						name: item.title,
						content: item.content,
						img: item.cover,
					})
				})
			})
		},
		//城市查询
		cityNameInput(val) {
			if (this.timer) {
				clearTimeout(this.timer)
			}
			this.timer = setTimeout(() => {
				this.getDevelopmentZone();
			}, 1000)
		},
		cityClick(index) {
			this.cityClickIndex = index;
		},
		//跳转 
		goTo(url, query) {
			this.$emit('jumpUrl', url, query);
		},
		back(e) {
	
			if (e == '详情') {

				this.$refs.KeyPointCon.showPart = [true, false, false, false, false, false];
				this.titleJumpList = [
				{
					url: "openLiaoning",
					name: "开放平台"

				}, {
					url: "",
					name: "重点开发区"

				}, {
					url: "",
					name: "详情"

				}
			];
			} else if (e == '重点项目') {

				this.$refs.KeyPointCon.showPart = [false, false, false, false, true, false];
				this.$refs.KeyPointCon.showDetail = false;
			} else if (e == '重要新闻') {

				this.$refs.KeyPointCon.showPart = [false, false, false, false, false, true];
				this.$refs.KeyPointCon.ShowDetailCon = false;

			} else {
				this.showPage = false;
			};


		},

		jumpUrl(e, query) {

			this.goTo(e, query)
		},
		clickDetail(item) {
			this.showPage = true;
			this.id = item.id;
		}
	}

}
</script>

<style lang="scss" scoped>
.body {
	padding: 20px 280px;
	// background-color: #f6f7f9;

	.tabs {
		background-color: #fff;
		padding: 31px 20px;

		&-item {
			height: 60px;
			border-radius: 10px;
			line-height: 60px;
			padding: 0 27px;
			font-size: 18px;
			position: relative;
			cursor: pointer;

			img {
				position: absolute;
				right: calc(27px + 9px);
				top: calc(50% - 15px / 2);
			}

		}

		&-item-click {
			color: #fff;
			background-color: #ea0e1e;
		}
	}

	.content {
		padding-left: 39px;

		.overflow {
			max-height: 800px;
			overflow-y: auto;
		}

		&-input {
			width: 390px;

			.el-input__icon {
				font-size: 20px;
			}
		}

		&-item {
			margin-top: 10px;
			margin-bottom: 10px;
			padding-right: 10px;

			>div {
				background-color: #fff;
				border-radius: 10px;
				display: flex;
				padding: 18px 21px;
				cursor: pointer;

				>div {
					width: calc(100% - 90px);
					flex-direction: column;
					padding: 10px 20px 0px 0px;

					.content-item-name {
						font-size: 22px;
						font-family: '思源黑体CN-Bold';
					}

					.content-item-content {
						color: #6c6c6c;
						margin-top: 4px;
						font-size: 13px;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}

				.content-item-img {
					width: 90px;
					height: 90px;
					border-radius: 10px;
				}
			}
		}
	}
}

.FixedContentStyle {
	margin-left: 40px;
	margin-bottom: 20px;
}
</style>