<template>
	<div class="hezuo-wrap">

		<div class="content">
		
			<el-row>
				<el-col :span="5" class="hezuo-left">
					<city ref="city" :cityList="cityList" @cityClick="cityClick"></city>
				</el-col>
				<el-col :span="19" class="hezuo-right">
					<div>
						<el-row >
							<div class="tupListKun">
								 <div>
                                    1
                                 </div>
							</div>
						</el-row>
					
					</div>

				</el-col>
			</el-row>


		</div>
		<elImageViewer v-if="dialogVisible" :on-close="closeImage" :url-list="[url]" />

	</div>
</template>

<script>

import city from '@/components/CityUtils.vue'
export default {
	components: {

		city
	},
	data() {
		return {
			tupList: [
				'老字号',
				'新字号',
				'原字号',

			],
			tupListIndex: 0,
			titleJumpList: [{ path: '/investLiaoning', name: '投资辽宁' }, { path: '/policy', name: '招商引资政策' }],
			showDetail: false,
			cityFage: "那曲市",
			indexFlag: 0,
			policyDetails: false,
			list: [],
			infoData: {},
			needCity: '',
			url: '',
			dialogVisible: false,
			cityList: ['那曲市', '嘉黎县', '比如县', '班戈县', '尼玛县']
		}
	},
	mounted() {
		this.findList();


	},
	methods: {
		clickBack() {
			this.showDetail = false;
		},
		showImage(e) {
			if (e.target.tagName == 'IMG') {

				this.url = e.target.src
				this.dialogVisible = true
			}
		},
		closeImage() {
			this.dialogVisible = false
			this.url = '';
		},
		clickDetail(e) {
			this.showDetail = true;
			this.$baseRequest('/not/investment/cooperation/details?info=' + e, {}, 'get').then(res => {
				this.infoData = res.data
				if (this.infoData.content) {
					this.infoData.content = this.infoData.content.replace(/<iframe/g, "<video controls style='width:100%;height:auto;'")
				}

			})
		},
		findList() {
			this.$baseRequest('/not/investment/cooperation/findList', { province: this.cityFage }, 'post').then(res => {
				this.list = res.data;
			})
		},
		cityClick(e) {
			this.cityFage = e;
			this.indexFlag = 0;
			this.showDetail = false;
			this.findList();

		},
		goTo(e, info) {
			this.showDetail = false;
			document.documentElement.scrollTop = 200;

		}
	}
}
</script>

<style src="../../style/element-ui-class.css"></style>

<style scoped lang="scss">
.htmls-box {
	padding: 30px 40px;
	margin-top: 30px;
	background-color: #fff;

	.intro {
		border-left: 4px solid #2378f1;
		font-size: 22px;
		margin-bottom: 20px;
		padding-left: 5px;
	}
}

.detail-box {
	padding: 30px 40px;
	background-color: #fff;

	.first {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #F8F8F8;
		padding-bottom: 10px;

		.first-name {
			font-size: 30px;
			color: #000;
			border-bottom: 4px solid #2378F1;
			margin-bottom: -10px;
			padding-bottom: 10px;
			text-align: left;
			letter-spacing: 1px;

		}

		.second-name {

			text-align: right;
			font-size: 18px;
			color: #6B6B6B;

			span {
				font-size: 32px;
				color: #FF700E;
				margin-left: 5px;
			}
		}

	}

	.second {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		font-size: 18px;
		margin: 20px 0px;

		div {
			width: calc(50% - 10px);
		}

		.single {
			margin-bottom: 10px;
		}
	}
}

.dingbu {
	width: 100%;
	height: auto;
}

.TourIntroduction-title {
	text-align: center;
	font-size: 36px;
	letter-spacing: 1px;

	img {
		vertical-align: middle;
		width: 43px;
	}
}

.hezuo-wrap {
	

	.content {
		padding: 60px 310px;

		.breadcrumb {
			height: 15px;
			display: flex;
			align-items: center;
			line-height: 15px;
			color: #8389a6;
			margin-bottom: 20px;
			font-size: 19px;

			div {
				display: flex;
				align-items: center;

				img {
					width: 18px;
					height: auto;
					margin-right: 10px;
				}
			}

			i {
				margin: 0 5px;
			}
		}

		.hezuo-left {
			background-color: #fff;
			padding: 20px;

		}

		.hezuo-right {
			// padding-left: 35px;
			margin-left: 35px;
			width: 970px;
			// background-color: pink;


			.hezuo-right-vessel {
				margin-top: 30px
			}

			.hezuo-right-placeholder {
				height: 10px;
			}

			.hezuo-right-project {
				background-color: #F0F4FF;
				height: 90px;
				display: flex;
				align-items: center;
				padding: 0 30px;
				margin-top: 20px;
				margin-bottom: 20px;
				color: #000;
				cursor: pointer;

				.project-title {
					font-size: 23px;
					margin-left: 20px;
				}

				.project-right {
					margin-left: auto;
				}
			}
		}
	}

	.bigPolic {

		width: 970px;
		height: 100px;
	}
}

.conMargin {
	margin-bottom: 20px;
	display: flex;
}

.tupListDia {
	display: flex;
	justify-content: space-between;
	
	div {
		width: 32%;
	}
	img {
		width: 100%;
		height: auto;
	}
}
.tupListKun {
	padding: 20px;
	background-color: #fff;
}
.detailBtn {
	font-size: 19px;
    color: #7E84A3;
	cursor: pointer;
}

.FixedContent-img2 {
        width: 14px;
        height: auto;
        vertical-align: middle;
        margin-right: 5px;
    }
</style>