<template>
    <div class="hezuo-wrap">
        <el-row >
            <img class="banner-unify" src="../assets/内容页切图/顶部图-精彩瞬间.png" alt="">
        </el-row>
        <div class="chooseItem">
            <div class="chooseItem-item" v-for="(item, index) in chooseList" :key="index"
                @click="chooseListIndex = index">
                {{ item }}
                <div class="line" v-if="chooseListIndex == index"></div>
            </div>
        </div>
        <div class="wrap-Row">
         <photoBroadcast v-if="chooseListIndex == 0"></photoBroadcast>
         <liveVideo v-else></liveVideo>
          
        </div>

    </div>
</template>

<script>
import photoBroadcast from '../view/WonderfulCom/photoBroadcast.vue'
import liveVideo from '../view/WonderfulCom/liveVideo.vue'
export default {
    components: { photoBroadcast,liveVideo },
    data() {
        return {
            
            chooseList: ['照片直播', '视频直播'],
            chooseListIndex: 0,
        }
    },
    mounted() {
       
    },
    methods: {
      
    }
}
</script>

<style src="../style/element-ui-class.css"></style>

<style scoped lang="scss">
.chooseItem {
    height: 60px;
    padding: 0px 310px;
    background-color: #fff;
    border-bottom: 1px solid #EBEBEB;
    display: flex;
    align-items: center;

    .chooseItem-item {
        width: 200px;
        font-size: 20px;
        font-weight: 600;
        position: relative;
        cursor: pointer;

        .line {
            position: absolute;
            bottom: -17px;
            left: -2px;
            width: 90px;
            border-radius: 2px;
            height: 4px;
            background-color: #F86C14;
        }

    }

}

.hezuo-wrap {
    

    .wrap-Row {
        padding: 30px 310px;
    }

    .content {


        .hezuo-left {
            background-color: #fff;
            padding: 20px;

            .hezuo-left-list {
                height: 60px;
                border-radius: 8px;
                padding: 0 25px;
                display: flex;
                cursor: pointer;

                .hezuo-left-title {
                    line-height: 60px;
                    font-size: 18px;
                    display: inline-block;
                }

                .hezuo-left-img {
                    float: right;
                    margin: auto 0px auto auto;

                    img {
                        width: 10px;
                    }
                }
            }

            .click {
                color: #fff;
                background-image: url(../assets/首页切图/展会简介背景.png);
                background-repeat: no-repeat;
                background-size: cover;
            }
        }

        .hezuo-right {
            padding-left: 35px;

            .hezuo-right-vessel {
                margin-top: 30px
            }

            .hezuo-right-placeholder {
                height: 10px;
            }

            .hezuo-right-project {
                background-color: #fff;
                height: 90px;
                display: flex;
                align-items: center;
                padding: 0 30px;
                margin-top: 20px;

                .project-title {
                    font-size: 23px;
                    margin-left: 20px;
                }

                .project-right {
                    margin-left: auto;
                }
            }
        }
    }
}

.flexBox {
    display: flex;
    background-color: #fff;
    padding: 20px;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 20px;

    // justify-content: space-between;
    .left {
        width: 80px;
        height: 80px;

        .left-img {
            width: 80px;
            height: 80px;
        }

        .image-slot {
            img {
                width: 80px;
                height: 80px;
            }

        }

        // img {
        //     width: 80px;
        //     height: 80px;
        // }
    }

    .center {
        width: calc(100% - 187px);
        height: 80px;
        padding: 0px 20px;

        .content1 {
            color: #6B6B6B;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .dotContenttime {
            font-size: 15px;
            color: #A6ABBC;
        }

        .title {
            font-size: 23px;
            margin-bottom: 5px;
        }
    }

    .right {
        width: 107px;
        height: 35px;

        img {
            width: 107px;
            height: 35px;
            cursor: pointer;
        }
    }
}

.navBox {
    // padding: 0px 310px;
    margin-bottom: 20px;
    font-size: 14px;

    div {
        display: inline-block;
        cursor: pointer;
    }

    span {
        margin-right: 10px;
        color: #7E84A3;
    }

    .img1 {
        width: 15px;
        height: 15px;
        margin-right: 10px;
    }

    .img2 {
        width: 9px;
        height: 9px;
        margin-right: 10px;
        vertical-align: middle;
    }

}

.newsBox {
    display: flex;
    padding: 60px 310px;

    .newsBox1 {
        width: calc(100% - 360px);
        background-color: #fff;
        padding: 20px;
    }

    .newsBox2 {
        width: 330px;
        margin-left: 30px;

        .text2 {
            font-size: 22px;
            color: #000;
            margin-bottom: 10px;

        }

        .newsBox3 {
            background-color: #fff;
            padding: 20px;

            .list {
                width: 100%;
                display: flex;
                align-items: center;
                margin-bottom: 30px;

                .dot {
                    width: 10px;
                    height: 10px;
                    margin-right: 20px;
                    background-color: #015FE5;
                }

                .dotContent {

                    width: calc(100% - 30px);
                }

                .dotContentinfo {
                    font-size: 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }


        }
    }
}
.introduct-list {
	display: flex;
	margin-top: 40px;
	padding-bottom: 40px;
	border-bottom: 1px solid #f0f1f7;
	cursor: pointer;

	// align-items: center;
	img {
		width: 280px;
		height: 180px;

	}

	.list-right {
		display: flex;
		flex-direction: column;
		padding-left: 25px;

		.list-title {
			font-size: 23px;
			font-weight: 600;
			line-height: 36px;

		}

		.list-text {
			margin-top: 10px;
			font-size: 15px;
			color: #8b8b8b;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		.list-time {
			margin-top: 10px;
			font-size: 16px;
			color: #8b8b8b;
		}
	}
}
.btn-fabu {
	padding: 7px 15px;
	text-align: left;
	background-color: #F86C14;
	border-radius: 4px;
	color: #fff;
	font-size: 18px;
	margin-top: 5px;
	cursor: pointer;
}
</style>