<!--
 * @Author: jiangjinchi 1468922694@qq.com
 * @Date: 2023-09-07 19:10:06
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2024-07-23 10:04:35
 * @FilePath: \department-of-commerce\PC\src\view\components\openDetails.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="openDetails-wrap">

        <div class="content">
            <el-row>
                <div class="htmlContent-names">
                    {{ infoData.hotelName }}
                </div>
                <div class="flexbox-title">
                    <div style="color: #FF7313;">
                        {{ infoData.position }}
                    </div>
                    <div>
                        {{ infoData.contactNumber }}
                    </div>
                </div>
                <div v-if="infoData.picture">
                    <el-image  class="imgss" :src="infoData.picture" :preview-src-list="[infoData.picture]"></el-image>
                </div>
                <div class="htmlboxMargin">
                    {{ infoData.spare1 }}
                </div>
            </el-row>
        </div>

    </div>
</template>

<script>

export default {
    props: {
        id: {
            type: [String, Number]
        }
    },
    data() {
        return {
            infoData: {
                spare1: ''
            },
            playerOptionsList: [],
            needShowDetail: false
        }
    },

    mounted() {
        this.getDetail()
    },
    methods: {
        getDetail() {
            this.$baseRequest('/not/hotel/details?info=' + this.id, {}, 'get').then(res => {
                this.infoData = res.data

            })
        }
    }
}
</script>

<style src="@/style/element-ui-class.css"></style>

<style scoped lang="scss">
.openDetails-wrap {
    

    .content {
        padding: 70px 200px;
        .imgss {
            width: 100%;
            height: auto;
        }
        .htmlboxMargin {
            margin-top: 30px;
            font-size: 17px;
        }

        .htmlContent-names {
            font-size: 30px;
            color: #000;
            margin-bottom: 10px;
        }

        .flexbox-title {
            display: flex;
            justify-content: space-between;
            font-size: 17px;
            margin-bottom: 20px;
        }

    }
}
</style>