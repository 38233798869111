<template>
	<div class="consumption-wrap">
		<el-row>
			<img class="banner-unify" src="@/assets/内容页切图/顶部图-产品展览.png" alt="">
		</el-row>
		<div class="content">

			<div class="content-chooses">
				<FixedContent v-if="detailsFlag" @ControlsBack="ControlsBack" :FixedContentList="['详情']"></FixedContent>
			</div>
			<div style="display: flex;">
				<div class="consumption-left">
					<city v-if="cityList.length" :cityList="cityList" :cityFage="cityFage" ref="city"
						@cityClick="cityClick"></city>
				</div>

				<div class="consumption-right">

					<div v-if="!detailsFlag">
						<div class="search-box">
							<el-input class="search" clearable v-model="searchContent" placeholder="请输入搜索条件"></el-input>
							<el-button @click="clickBtns" class="btn" type="primary">搜索</el-button>
						</div>
						<div class="right-item">
							<div v-for="(item, index) in list" :key="item.id">
								<div class="consumption-product" @click="goTo(item.id)">
									<el-image class="consumption-product-img" :src="item.picture">
										<div slot="placeholder" class="image-slot">
											<img src="@/assets/首页切图/缺省.png" alt="">
										</div>
										<div slot="error" class="image-slot">
											<img src="@/assets/首页切图/缺省.png" alt="">
										</div>
									</el-image>
									<div class="product-boxs">
										<div class="consumption-product-title"> {{ item.productName }}</div>
										<div class="consumption-product-content"> {{ item.productIntroduction }}</div>
									</div>
								</div>

							</div>
						</div>

						<div class="el-pageing" v-if="list.length">
							<el-pagination @current-change="handleCurrentChange" background layout="prev, pager, next"
								:total="total">
							</el-pagination>
						</div>
					</div>

					<consumptionDetails ref="consumptionDetails" v-else :id="id"></consumptionDetails>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import consumptionDetails from './components/consumptionDetails.vue'
import city from '@/components/CityUtils.vue'
export default {
	components: {
		city,
		consumptionDetails
	},
	data() {
		return {
			cityFage: "",
			detailsFlag: false,
			list: [],
			cityList: [],
			infoData: "",
			id: '',
			beginNum: 1,
			total: 0,
			searchContent: ''
		}
	},
	mounted() {
		this.getLeftList();

	},
	beforeDestroy() {
		this.$store.commit('SET_DETAILSTORE', '')
	},
	methods: {
		clickBtns() {
			this.beginNum = 1;
			this.findList();
		},
		// 获取左侧选择列表
		getLeftList() {
			this.$baseRequestGet('/sys/dictionaries/list?info=' + '产品分类', {}, 'get').then(res => {
				this.cityList = res.data.map(item => {
					return item.dictionariesName
				});
				if (this.$store.getters.detailStore) {
					this.cityFage = this.$store.getters.detailStore.province;
					this.goTo(this.$store.getters.detailStore.id)
				} else {
					this.cityFage = this.cityList.length ? this.cityList[0] : '';
				};
				this.findList();

			})
		},
		ControlsBack() {
			this.detailsFlag = false;
		},
		handleCurrentChange(val) {
			this.beginNum = val;
			this.findList()
		},
		findList() {
			if (!this.cityFage) return;
			this.$baseRequest('/not/goods/boutique/pageLists', {
				province: this.cityFage,
				productType: '',
				endNum: 6,
				beginNum: this.beginNum,
				productName: this.searchContent
			}, 'POST').then(res => {
				this.list = res.data.records;
				this.total = Number(res.data.total)
			})
		},
		cityClick(e) {
			this.searchContent = '';
			this.beginNum = 1;
			this.cityFage = e;
			this.detailsFlag = false;
			this.findList();
		},
		goTo(e) {
			this.id = e;
			this.detailsFlag = !this.detailsFlag;
			this.$nextTick(() => {
				document.documentElement.scrollTop = 200;
			})
		}
	}
}
</script>

<style src="../style/element-ui-class.css"></style>
<style>
.search .el-input__inner {
	font-size: 18px;
}

.el-button--primary {
	font-size: 17px;
}
</style>
<style scoped lang="scss">
.dingbu {
	width: 100%;
	height: auto;
}

.consumption-wrap {
	

	.content {
		padding: 60px 310px;

		.breadcrumb {
			height: 15px;
			display: flex;
			align-items: center;
			line-height: 15px;
			color: #8389a6;
			margin-bottom: 20px;
			font-size: 19px;

			div {
				display: flex;
				align-items: center;

				img {
					width: 18px;
					height: auto;
					margin-right: 10px;
				}
			}

			i {
				margin: 0 5px;
			}
		}

		.consumption-left {
			background-color: #fff;
			padding: 20px;
			width: 240px;
			height: 100%;
		}

		.consumption-right {
			padding-left: 35px;
			width: calc(100% - 275px);

			.right-item {
				display: flex;
				justify-content: flex-start;
				flex-wrap: wrap;
				gap: 20px;
			}

			.consumption-product {
				width: 315px;
				cursor: pointer;
				border-radius: 5px;


				.consumption-product-img {
					width: 100%;
					height: 280px;
					border-radius: 5px 5px 0 0;
					vertical-align: top;
				}

				.image-slot {
					img {
						width: 100%;
						height: 280px;
						border-radius: 5px 5px 0 0;

					}

				}

				.product-boxs {
					padding: 20px;
					background-color: #fff;
					box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
					border-radius: 0px 0px 5px 5px;
				}

				.consumption-product-title {

					// margin-top: 10px;
					font-size: 20px;
					font-weight: bold;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;

				}

				.consumption-product-content {
					margin-top: 5px;
					height: 40px;
					font-size: 16px;
					color: #b4b4b4;
					display: -webkit-box;
					overflow: hidden;
					-webkit-box-orient: vertical;
					line-clamp: 2;
					-webkit-line-clamp: 2;
				}
			}
		}
	}
}

.content-chooses {
	margin-bottom: 20px;
}

.el-pageing {
	text-align: center;
	margin-top: 20px;
}

.search-box {
	margin-bottom: 30px;

	.search {
		width: 90%;
		margin-right: 10px;
	}
}
</style>