<!--
 * @Author: jiangjinchi 1468922694@qq.com
 * @Date: 2024-09-15 08:50:09
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2024-09-17 16:06:09
 * @FilePath: \PC最新版本\src\view\KeyPoint\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<div class="detailBACK">

		<div v-if="showPart[0]">
			<div class="title">
				<img src="../../assets/标题装饰.png" alt="">
				<span>{{ details.title }}</span>
				<img src="../../assets/标题装饰.png" alt="">
			</div>
			<div class="conts" style="">
				{{ details.content }}
			</div>
			<div v-if="playerOptionsList.sources[0].src">
				<videoPlayer class="video-player vjs-custom-skin videoSize" ref="videoPlayer" :playsinline="true"
					:options="playerOptionsList">
				</videoPlayer>
			</div>
			<div class="imgList">
				<img v-if="details.planningMap" @click="clickDetail([false, true, false, false, false, false], '开发区规划')"
					src="../../assets/开放平台/开发区规划.png" alt="">
				<img v-if="details.whetherEnvironment" @click="getEnvironmentImgs" src="../../assets/开放平台/营商环境.png"
					alt="">
				<img v-if="details.policyMap" @click="clickDetail([false, false, false, true, false, false], '招商政策')"
					src="../../assets/开放平台/招商政策.png" alt="">
				<img v-if="details.whetherProject" @click="getMajorProject" src="../../assets/开放平台/重点项目.png" alt="">
				<img v-if="details.whetherJournalism" @click="getAttractImportantNews" src="../../assets/开放平台/重要新闻.png"
					alt="">
			</div>
		</div>
		<div v-if="showPart[1]">
			<div class="title">
				<img src="../../assets/标题装饰.png" alt=""> <span>开发区规划</span> <img src="../../assets/标题装饰.png" alt="">
			</div>
			<div class="placeholderMap">
				<img :src="details.planningMap" />
			</div>
		</div>
		<div v-if="showPart[2]">
			<div class="title">
				<img src="../../assets/标题装饰.png" alt=""> <span>营商环境</span> <img src="../../assets/标题装饰.png" alt="">
			</div>
			<div>
				<div class="placeholderMap">
					<img :src="item" v-for="(item, index) in details.environmentImgs" :key="index" />
				</div>
			</div>
		</div>
		<div v-if="showPart[3]">
			
			<div class="title">
				<img src="../../assets/标题装饰.png" alt=""> <span>招商政策</span> <img src="../../assets/标题装饰.png" alt="">
			</div>
			<div>
				<div class="placeholderMap">
					<img :src="details.policyMap" />
				</div>
			</div>
		</div>
		<div v-if="showPart[4]">
			<div v-if="!showDetail">
				<div class="title">
					<img src="../../assets/标题装饰.png" alt=""> <span>重点项目</span> <img src="../../assets/标题装饰.png" alt="">
				</div>
				<div class="content2">
					<div @click="getProjectDetails(item.id)" style="cursor: pointer;"
						v-for="(item, index) in majorProject" :key="index" class="att">
						<div>
							<img :src="item.img" alt="">
						</div>
						<div>
							<div class="titless">{{ item.title }}</div>
							<div class="contss">{{ item.content }}</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else>
				<div class="title">
					<img src="../../assets/标题装饰.png" alt="">
					<span>{{ projectDetails.corporateName }}</span>
					<img src="../../assets/标题装饰.png" alt="">
				</div>
				<div class="storeCon">
					{{ projectDetails.content }}
				</div>
				<div class="storeImg">
					<img :src="projectDetails.cover" alt="">
				</div>
				<div class="title" style="text-align: left;font-size: 16px;">
					<img src="../../assets/标题装饰.png" alt="">
					<span>联系方式</span>
				</div>
				<div style="display: flex;border-top: 1px solid #F4F4F4;padding-top: 20px;">
					<div class="storeInfo">
						<div>
							项目方招商
						</div>
						<div>
							联系人：{{ projectDetails.contactsOne }}
						</div>
						<div>
							联系电话：{{ projectDetails.contactTelephoneOne }}
						</div>
					</div>
					<div class="storeInfo">
						<div>
							开发区招商
						</div>
						<div>
							联系人：{{ projectDetails.contactsTwo }}
						</div>
						<div>
							联系电话：{{ projectDetails.contactTelephoneTwo }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="showPart[5]">
			<div v-if="!ShowDetailCon">
				<div class="title">
					<img src="../../assets/标题装饰.png" alt=""> <span>重要新闻</span> <img src="../../assets/标题装饰.png" alt="">
				</div>
				<div class="content3">
					<div @click="clickShowDetail(item)" style="cursor: pointer;"
						v-for="(item, index) in attractImportantNews" :key="index" class="att">
						<div>
							<img :src="item.img" alt="">
						</div>
						<div>
							<div class="titless">{{ item.title }}</div>
							<div class="contss">{{ item.content }}</div>
							<div class="contss">{{ item.date }}</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else>
				<div class="content">
					<div class="introduct-left ">
						<div class="title">
							{{ objInfo.journalismTitle }}
						</div>
						<div class="date">
							{{ objInfo.journalismDate }}
						</div>
						<div class="conts">
							<div class="content" v-html="objInfo.journalismContent">
							</div>
						</div>
					</div>
				</div>
			</div>


		</div>

	</div>
</template>

<script>

import {
	videoPlayer
} from 'vue-video-player'
export default {
	components: {
		videoPlayer,
	},
	props: ["id"],
	data() {
		return {
			objInfo: {},
			ShowDetailCon: false,

			nameValue: '',
			titleJumpList: [
				{
					url: "openLiaoning",
					name: "开放平台"

				}, {
					url: "emphasisDevelopmentZone",
					name: "重点开发区"

				}, {
					url: "emphasisDevelopmentZone",
					name: "重要新闻"

				}],
			details: {

			},
			showDetailNew: false,
			showDetail: false,
			majorProject: [],
			attractImportantNews: [], //招商要闻
			showPart: [true, false, false, false, false, false],
			playerOptionsList: {
				id: 1,
				playbackRates: [], // 可选的播放速度
				autoplay: false, // 如果为true,浏览器准备好时开始回放。
				muted: false, // 默认情况下将会消除任何音频。
				loop: false, // 是否视频一结束就重新开始。
				preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
				language: 'zh-CN',
				aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
				fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
				sources: [{
					type: "video/mp4", // 类型
					src: ""
				}],
				// poster: require('@/assets/首页切图/10投资合作-大连-宽.png'), // 封面地址
				notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
				controlBar: {
					timeDivider: false, // 当前时间和持续时间的分隔符
					durationDisplay: false, // 显示持续时间
					remainingTimeDisplay: false, // 是否显示剩余时间功能
					fullscreenToggle: true // 是否显示全屏按钮
				}
			},
			projectDetails: {},
		}
	},
	mounted() { },
	methods: {
		clickShowDetail(e) {
			this.ShowDetailCon = true;
			this.$baseRequestGet('/maintain/journalism/details?info=' + e.id, {}, 'get').then(res => {
				this.objInfo = res.data;
			})
		},
		clickDetail(e, name) {
			this.showPart = e;
			
		
			this.nameValue = name;
		

		},
		goTo(url, item) {

			let params = {
				name: '重点开发区',
				id: item.id,
				titleJumpList: JSON.stringify(this.titleJumpList)
			}

			this.$emit('jumpUrl', url, params)
		},
		getDetails(id) {
			this.$baseRequest("/not/development/zones/details?info=" + id, {}, "get").then(res => {
				this.details = res.data;
			})
		},
		getEnvironmentImgs() {
			this.nameValue = '营商环境';
			this.showPart = [false, false, true, false, false, false];
			this.$baseRequest("/not/development/photo/findList?info=" + this.id, {}, "get").then(res => {
				this.details.environmentImgs = [];
				res.data.forEach(item => {
					this.details.environmentImgs.push(item.zonesPhoto);
				})
			})
		},
		getMajorProject() {
			this.nameValue = '重点项目';
			this.showPart = [false, false, false, false, true, false]
			this.$baseRequest("/not/development/project/findList", {
				"zonesId": this.id
			}, "post").then(res => {
				this.majorProject = [];
				res.data.forEach(item => {
					this.majorProject.push({
						id: item.id,
						img: item.cover,
						title: item.corporateName,
						content: item.content,
					});
				})
			})
		},
		getProjectDetails(id) {
			this.$baseRequest("/not/development/project/details?info=" + id, {}, "get").then(res => {
				this.showDetail = !this.showDetail;
				this.projectDetails = res.data;
			})
		},
		getAttractImportantNews() {
			this.nameValue = '重要新闻';
			this.showPart = [false, false, false, false, false, true]
			this.$baseRequest("/not/development/journalism/findList", {
				"journalismCategoryId": this.id
			}, "post").then(res => {
				this.attractImportantNews = [];
				res.data.forEach(item => {
					this.attractImportantNews.push({
						id: item.id,
						img: item.journalismPhoto,
						title: item.journalismTitle,
						content: item.spare1,
						date: item.journalismDate,
					});
				})
			})
		},


	},
	watch: {
		"id": {
			handler: function (newVal, oldVal) {
				this.getDetails(newVal);
			},
			immediate: true
		},
		"details.video": {
			handler: function (newVal, oldVal) {
			
				if (newVal) {
					this.playerOptionsList.sources[0].type = "video/" + newVal.split(".").pop();
					this.playerOptionsList.sources[0].src = newVal;
				} else {
					this.playerOptionsList.sources[0].src = ''
				}


			},
			immediate: true
		},
		nameValue(val) {
			this.$emit('changeName', val);


		}
	}
}
</script>

<style src="../../style/element-ui-class.css"></style>

<style scoped lang="scss">
.detailBACK {
	padding: 40px;
	background-color: #fff;

	.title {
		text-align: center;
		font-family: 'SourceHanSansSC-Regular';
		font-size: 26px;
		color: #000;
		margin-bottom: 30px;

		img {
			width: 20px;
			height: auto;
		}

		span {
			margin: 0px 10px;
		}
	}

	.placeholderMap {
		width: 100%;
		max-height: 1000px;
		overflow-y: auto;

		img {
			width: 100%;
		}
	}
}

.conts {
	letter-spacing: 1px;
	line-height: 150%;
	font-size: 18px;
	margin-bottom: 20px;
	text-indent: 2em;
	
}

.imgList {
	margin-top: 20px;
	display: flex;
	justify-content: flex-start;
	gap: 10px;

	img {
		width: 19%;
		cursor: pointer;

	}
}

.content2 {
	max-height: 800px;
	overflow-y: auto;

	.titless {
		font-size: 24px;
		font-weight: 600;
		letter-spacing: 1px;
		margin-bottom: 20px;
	}

	.att {
		display: flex;
		margin-bottom: 30px;
		background-color: #F0F4FF;
		border-radius: 5px;
		padding: 20px;

		img {
			width: 120px;
			height: 120px;
			margin-right: 20px;
			border-radius: 5px;
		}
	}

	.contss {
		font-size: 16px;
		margin-top: 20px;
		color: #707071;
	}

	.body {
		padding: 20px 280px;
		

		.content {
			margin-top: 30px;
			padding: 20px;
			background-color: #fff;
			border-radius: 5px;
		}

		.title {
			margin-top: 20px;
			font-family: 'SourceHanSansSC-Regular';
			font-size: 26px;
			color: #000;
			margin-bottom: 20px;

			img {
				width: 20px;
				height: auto;
			}

			span {
				margin: 0px 10px;
			}
		}
	}

	.tupian {
		width: 100%;

		img {
			width: 100%;
			height: auto;
		}
	}

	.conts {
		margin-top: 30px;
		font-size: 18px;
		letter-spacing: 1px;
		line-height: 140%;

	}

	.title {
		height: 40px;

		img {
			width: 25px;
			height: 25px;
		}

		>span {
			line-height: 40px;
			margin-left: 9px;
			font-size: 29px;
			font-family: 'DingTalk_JinBuTi_Regular';
		}

		.view-more {
			float: right;
			line-height: 40px;
			color: #a7abc0;
			font-size: 16px;
			cursor: pointer;

			img {

				width: 13px;
				height: auto;
			}
		}
	}
}

.storeCon {
	letter-spacing: 1px;
	line-height: 150%;
	font-size: 16px;
	margin-bottom: 20px;
}

.storeInfo {
	width: 50%;

	div {
		font-size: 16px;
		color: #000;
		margin-bottom: 20px;
	}
}

.storeImg {
	width: 100%;
	margin-bottom: 20px;

	img {
		width: 100%;
		height: auto;
		border-radius: 5px;
	}
}

.content3 {
	.titless {
		font-size: 24px;
		font-weight: 600;
		letter-spacing: 1px;
		margin-bottom: 20px;
	}

	.att {
		display: flex;
		margin-bottom: 30px;
		border-bottom: 1px solid #F8F9FB;
		padding-bottom: 20px;

		img {
			width: 280px;
			height: 180px;
			margin-right: 20px;
			border-radius: 5px;
		}
	}

	.body {
		padding: 20px 280px;
		

		.content {
			margin-top: 30px;
			padding: 20px;
			background-color: #fff;
			border-radius: 5px;
		}

		.title {
			margin-top: 20px;
			font-family: 'SourceHanSansSC-Regular';
			font-size: 26px;
			color: #000;
			margin-bottom: 20px;

			img {
				width: 20px;
				height: auto;
			}

			span {
				margin: 0px 10px;
			}
		}
	}

	.tupian {
		width: 100%;

		img {
			width: 100%;
			height: auto;
		}
	}

	.conts {
		margin-top: 30px;
		font-size: 18px;
		letter-spacing: 1px;
		line-height: 140%;

	}

	.title {
		height: 40px;

		img {
			width: 25px;
			height: 25px;
		}

		>span {
			line-height: 40px;
			margin-left: 9px;
			font-size: 29px;
			font-family: 'DingTalk_JinBuTi_Regular';
		}

		.view-more {
			float: right;
			line-height: 40px;
			color: #a7abc0;
			font-size: 16px;
			cursor: pointer;

			img {

				width: 13px;
				height: auto;
			}
		}
	}
}

.introduct-left {

	background: #fff;
	// padding: 20px;
	padding-bottom: 60px;

	.title {
		font-size: 28px;
		text-align: left;
	}

	.date {
		font-size: 18px;
		color: #878787;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.content {
		font-size: 16px;
	}



}
</style>