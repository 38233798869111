<template>
    <div class="newsIndex-wrap">
        <div>
            <img class="banner-unify" src="@/assets/内容页切图/顶部图-通知公告.png" alt="">
        </div>
        <div class="newsIndex-introduct" v-if="!detailsFlag">
            <div class="introduct-left ">

                <div class="loadingMorebox" :infinite-scroll-immediate="false" :infinite-scroll-disabled="loading"
                    v-infinite-scroll="loadMore" infinite-scroll-distance="1">
                    <div class="introduct-list" v-for="(item, index) in list1" :key="index + '1'"
                        @click="goTo(item.id)">
                        <div>
                            <img v-if="item.journalismPhoto" :src="item.journalismPhoto" alt="">
                            <img v-else src="../assets/首页切图/占位.png" alt="">
                        </div>
                        <div class="list-right">
                            <div class="list-title">{{ item.journalismTitle }}</div>
                            <div class="list-text">{{ item.spare1 }}</div>
                            <div class="list-time">{{ item.journalismDate }}</div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <newsIndexDetails ref="newsIndexDetails" v-else @goBack="goBack" :id="id" :list2="list2"></newsIndexDetails>
    </div>
</template>

<script>
import newsIndexDetails from './components/newsIndexDetails.vue'
export default {
    components: {
        newsIndexDetails
    },
    data() {
        return {
            bannerListSmall: [],
            list1: [],
            list2: [],
            detailsFlag: false,
            id: '',
            loading: false,
            count: 20,
            beginNum: 1,
            isNoPage: false,
            type: ''
        }
    },
    mounted() {
        this.getList();
        if (this.$route.query.id) {
            this.goTo(this.$route.query.id);
        };
    },
    methods: {
        loadMore() {
            this.loading = true;
            if (this.isNoPage) return;
            this.beginNum = this.beginNum + 1;

            this.getList().then(res => {
                this.loading = false;
            })

        },
        getList() {
            return new Promise(resolve => {
                this.$baseRequest('/maintain/journalism/pageList', {
                    journalismCategory: "通知公告",
                    beginNum: this.beginNum,
                    endNum: 8
                }, 'post').then(res => {
                    if (res.data.records.length == 0 || res.data.records.length < 7) {
                        this.isNoPage = true;
                    }
                    // this.list1 = res.data;
                    this.list1 = [...this.list1, ...res.data.records];
                    resolve();
                })
            })
        },

        goTo(e) {
            this.id = e;
            this.detailsFlag = !this.detailsFlag;
            this.$nextTick(() => {
                document.documentElement.scrollTop = 200;
                this.$refs["newsIndexDetails"].details();
            })
        },
        goBack() {
            this.detailsFlag = !this.detailsFlag;
        }
    }
}
</script>
<style src="../style/elementSingle.css"></style>
<style src="../style/viedo.css"></style>

<style scoped lang="scss">
.newsIndex-wrap {
    background: #F7F7F7;

    .newsIndex-banner {
        width: 100%;
        height: 238px;
    }

    .newsIndex-introduct {
        // border: 1px solid red;
        display: flex;
        justify-content: center;
        // padding: 60px 0;

        .introduct-left {

            background: #fff;
            padding: 60px 310px;
            padding-bottom: 60px;

            .carousel-wrap {
                position: relative;

                .carousel {
                    width: 100%;
                    height: 500px;
                }

                .ExhibitionLeftHeightTitle {

                    width: 100%;

                    overflow: hidden;

                    div {
                        width: 80%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                    }

                    position: absolute;
                    color: #fff;
                    left: 0px;
                    bottom: 0px;
                    padding: 20px;
                    font-size: 18px;
                    background-color: rgba(0, 0, 0, 0.4);

                }
            }

            .introduct-list {
                display: flex;
                margin-top: 40px;
                padding-bottom: 40px;
                border-bottom: 1px solid #f0f1f7;
                cursor: pointer;

                // align-items: center;
                img {
                    width: 280px;
                    height: 180px;

                }

                .list-right {
                    display: flex;
                    flex-direction: column;
                    padding-left: 25px;

                    .list-title {
                        font-size: 23px;
                        font-weight: 600;
                        line-height: 36px;

                    }

                    .list-text {
                        margin-top: 10px;
                        font-size: 15px;
                        color: #8b8b8b;
                    }

                    .list-time {
                        margin-top: auto;
                        font-size: 16px;
                        color: #8b8b8b;
                    }
                }
            }
        }

        .introduct-right {
            width: 350px;
            margin-left: 20px;

            .title {
                font-size: 20px;
                font-weight: bold;
            }

            .list-div {
                .list {
                    display: flex;
                    padding: 15px;
                    cursor: pointer;
                    background-color: #fff;
                    margin-bottom: 10px;
                    ;

                    .list-left {
                        width: 200px;
                        height: 90px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .list-right {
                        margin-left: 15px;
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        flex-wrap: wrap;

                        .list-content {
                            font-size: 17px;
                            font-weight: bold;
                        }

                        .list-date {
                            margin-top: auto;
                            font-size: 15px;
                            color: #b1b1b1;

                        }
                    }
                }
            }
        }
    }

    .loadingMorebox {
        height: 750px;
        overflow: auto;
    }
}
</style>